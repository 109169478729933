import './Navbar.scss';

import { Link } from 'react-router-dom';
import React from 'react';
import { motion } from 'framer-motion';

interface NavLink {
  label: string;
  href: string;
}

interface NavbarProps {
  links: NavLink[];
  bookADemoPage?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ links, bookADemoPage = false }) => {
  return (
    <nav className="navbar" style={{ zIndex: 99 }}>
      <motion.div
        className="logo"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Link to="/">
          <img
            src="/maxhome.svg"
            alt="MaxHome Logo"
            style={{
              maxWidth: '8rem',
            }}
          />
        </Link>
      </motion.div>

      {/* <motion.div
        className="nav-links"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        {links.map((link, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link to={link.href}>{link.label}</Link>
          </motion.div>
        ))}
      </motion.div> */}

      <motion.div
        className="nav-buttons"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {!bookADemoPage ? (
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link to="/agent/login" className="login-btn">
              Log In
            </Link>
          </motion.div>
        ) : (
          <div>
            <Link to="/agent/login" className="login-btn">
              Log In
            </Link>
          </div>
        )}

        {!bookADemoPage && (
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link to="/get-in-touch" className="demo-btn">
              Book a Demo
            </Link>
          </motion.div>
        )}
      </motion.div>
    </nav>
  );
};

export default Navbar;
