import api from 'app/utils/api';

interface CheckBuilderOptions {
  page?: number;
  limit?: number;
  createdAtGte?: string;
  createdAtLte?: string;
  status?: string;
  checkType?: string;
  transactionOfficeID?: string;
  receivingOfficeID?: string;
  search?: string;
  readyToDeposit?: boolean | null;
  readyToDepositMarkedAtGte?: string;
  readyToDepositMarkedAtLte?: string;
}

export class CheckBuilder {
  private page?: number;
  private limit?: number;
  private createdAtGte?: string;
  private createdAtLte?: string;
  private status?: string;
  private checkType?: string;
  private transactionOfficeID?: string;
  private receivingOfficeID?: string;
  private search?: string;
  private readyToDeposit?: boolean | null;
  private readyToDepositMarkedAtGte?: string;
  private readyToDepositMarkedAtLte?: string;
  public hasMore: boolean;

  private constructor(options: CheckBuilderOptions) {
    this.page = options.page;
    this.limit = options.limit;
    this.createdAtGte = options.createdAtGte;
    this.createdAtLte = options.createdAtLte;
    this.status = options.status;
    this.checkType = options.checkType;
    this.transactionOfficeID = options.transactionOfficeID;
    this.receivingOfficeID = options.receivingOfficeID;
    this.search = options.search;
    this.readyToDeposit = options.readyToDeposit;
    this.readyToDepositMarkedAtGte = options.readyToDepositMarkedAtGte;
    this.readyToDepositMarkedAtLte = options.readyToDepositMarkedAtLte;
    this.hasMore = true;
  }

  static builder = class {
    private page?: number;
    private limit?: number;
    private createdAtGte?: string;
    private createdAtLte?: string;
    private status?: string;
    private checkType?: string;
    private transactionOfficeID?: string;
    private receivingOfficeID?: string;
    private search?: string;
    private readyToDeposit?: boolean | null;
    private readyToDepositMarkedAtGte?: string;
    private readyToDepositMarkedAtLte?: string;

    setPage(page: number): this {
      this.page = page;
      return this;
    }

    setLimit(limit: number): this {
      this.limit = limit;
      return this;
    }

    setCreatedAtGte(date: string): this {
      this.createdAtGte = date;
      return this;
    }

    setCreatedAtLte(date: string): this {
      this.createdAtLte = date;
      return this;
    }

    setStatus(status: string): this {
      this.status = status;
      return this;
    }

    setCheckType(type: string): this {
      this.checkType = type;
      return this;
    }

    setTransactionOfficeID(id: string): this {
      this.transactionOfficeID = id;
      return this;
    }

    setReceivingOfficeID(id: string): this {
      this.receivingOfficeID = id;
      return this;
    }

    setSearch(search: string): this {
      this.search = search;
      return this;
    }

    setReadyToDeposit(value: boolean | null): this {
      this.readyToDeposit = value;
      return this;
    }

    setReadyToDepositMarkedAtGte(date: string): this {
      this.readyToDepositMarkedAtGte = date;
      return this;
    }

    setReadyToDepositMarkedAtLte(date: string): this {
      this.readyToDepositMarkedAtLte = date;
      return this;
    }

    build(): CheckBuilder {
      return new CheckBuilder({
        page: this.page,
        limit: this.limit,
        createdAtGte: this.createdAtGte,
        createdAtLte: this.createdAtLte,
        status: this.status,
        checkType: this.checkType,
        transactionOfficeID: this.transactionOfficeID,
        receivingOfficeID: this.receivingOfficeID,
        search: this.search,
        readyToDeposit: this.readyToDeposit,
        readyToDepositMarkedAtGte: this.readyToDepositMarkedAtGte,
        readyToDepositMarkedAtLte: this.readyToDepositMarkedAtLte,
      });
    }
  };

  async fetchNext(): Promise<any> {
    try {
      let url = `/transaction/checks?sort=-createdAt`;
      if (this.page !== undefined) url += `&page=${this.page}`;
      if (this.limit !== undefined) url += `&limit=${this.limit}`;
      if (this.createdAtGte) url += `&createdAt[gte]=${this.createdAtGte}`;
      if (this.createdAtLte) url += `&createdAt[lte]=${this.createdAtLte}`;
      if (this.status) url += `&processingStatus.status=${this.status}`;
      if (this.checkType) url += `&type=${this.checkType}`;
      if (this.transactionOfficeID)
        url += `&transactionOffice=${this.transactionOfficeID}`;
      if (this.receivingOfficeID)
        url += `&receivingOffice=${this.receivingOfficeID}`;
      if (this.search) url += `&search=${this.search}`;
      if (this.readyToDeposit !== null)
        url += `&readyToDeposit=${this.readyToDeposit}`;
      if (this.readyToDepositMarkedAtGte)
        url += `&readyToDepositMarkedAt[gte]=${this.readyToDepositMarkedAtGte}`;
      if (this.readyToDepositMarkedAtLte)
        url += `&readyToDepositMarkedAt[lte]=${this.readyToDepositMarkedAtLte}`;

      const response: any = await api.get(url);
      if (this.page !== undefined && this.limit !== undefined) {
        const totalFetched = this.page * this.limit;
        this.hasMore = totalFetched < response.data.total;
      } else {
        this.hasMore = false;
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
}
