import api from 'app/utils/api';

export class VisitorService {
  public async saveGetInTouchRequest(
    name: string,
    email: string,
    companyName: string,
    role: string
  ) {
    return api.post(`/visitor/get-in-touch`, {
      name,
      email,
      companyName,
      role,
    });
  }
}
