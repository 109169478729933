import React, { ReactNode } from 'react';
import './Table.scss';

interface TableProps {
  minHeight?: string;
  paddingBottom?: string;
  overflowX?: 'auto' | 'hidden' | 'scroll' | 'visible';
  maxHeight?: string;
  overflow?: 'auto' | 'hidden' | 'scroll' | 'visible';
  zIndex?: number;
  border?: boolean;
  borderTop?: boolean;
  children: ReactNode;
  borderRadius?: string;
}

const Table: React.FC<TableProps> = (props) => {
  return (
    <div
      className="base"
      style={{
        minHeight: props.minHeight,
        paddingBottom: props.paddingBottom,
        overflowX: props.overflowX,
        maxHeight: props.maxHeight,
        overflow: props.overflow,
        zIndex: props.zIndex,
        borderRadius: props.borderRadius,
      }}
    >
      <table
        style={
          props.border
            ? {
                border: '1px solid rgba(0,0,0,0.2)',
                borderTop: props.borderTop
                  ? '1px solid rgba(0,0,0,0.2)'
                  : '0px',
              }
            : {}
        }
      >
        {props.children}
      </table>
    </div>
  );
};

export default Table;
