// Note: This component takes a component and a loading state as props
//  and renders the component if the loading state is false

import LoadingSpinner from 'app/components/common/LoadingSpinner';

const WaitAndLoadingWithSpinner = ({
  loading,
  component,
}: {
  loading: boolean;
  component: React.ReactNode;
}) => {
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && component}
    </>
  );
};

export default WaitAndLoadingWithSpinner;
