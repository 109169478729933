export const TRANSACTION_TYPE_OPTIONS = (type: string) => {
  switch (type) {
    case 'PURCHASE':
      return 'Buy';
    case 'LISTING':
      return 'List';
    default:
      return type || '-'
  }
};
