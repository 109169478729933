import { CircularProgress } from '@mui/material';

// NOTE: Use these only for components which can access full height
// TODO: Fix the height overflow issue

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingSpinner;
