import {
  ArrowBackRounded,
  ArrowForwardRounded,
  CloseRounded,
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppURLs } from 'app/utils/appURLs';
import { Button } from 'antd';
import { ComplianceService } from 'app/services/compliance';
import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import { Document } from 'app/interfaces/Document';
import ErrorsDrawer from './ErrorsDrawer';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getCustomIdFromUrl } from 'app/utils/url';
import useTransactionDocumentErrorsManager from 'app/atomManagers/transactionDocumentErrorsManager';

const ErrorsStacker = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const errorsManager = useTransactionDocumentErrorsManager();
  const { errors, clearErrorsForTransaction } = errorsManager;
  const [isErrorsModalVisible, setIsErrorsModalVisible] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [transactionData, setTransactionData] = useState<ComplianceTransaction>(
    {} as ComplianceTransaction
  );
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  // const [prevDocument, setPrevDocument] = useState<Document>({} as Document);
  // const [nextDocument, setNextDocument] = useState<Document>({} as Document);

  const [bannerVisble, setBannerVisible] = useState(false);

  useEffect(() => {
    console.log('use effect triggered');

    // Get the transactionId from the URL: transactionID=<string>
    const transactionIdUrlParam = getCustomIdFromUrl('transactionID');
    const documentIdUrlParam = getCustomIdFromUrl('documentID');
    setTransactionId(transactionIdUrlParam || '');

    // If transactionIdUrlParam exists and if there are errors, then show the banner else hide it
    if (transactionIdUrlParam && transactionIdUrlParam !== '') {
      // If there are errors for the transaction, then show the banner
      if (errorsStackedInTransactionCount(transactionIdUrlParam) > 0) {
        setBannerVisible(true);
      }
    } else {
      setBannerVisible(false);
    }

    // Get the transaction data from the transactionId
    ComplianceService.getTransactionByID(transactionIdUrlParam || '')
      .then((response) => {
        setTransactionData(response.data);
        console.log('Transaction ID: ', transactionIdUrlParam);
        console.log('Transaction Data: ', response.data);

        // // Get the index of the transactionData.documents
        // const documentIndex = response.data.documents.findIndex(
        //   (doc: Record<string, any>) => doc._id === documentIdUrlParam
        // );
        // setCurrentDocumentIndex(documentIndex);
        // // If the documentIndex is not the first document, then set the prevDocumentIndex
        // if (documentIndex > 0) {
        //   setPrevDocument(response.data.documents[documentIndex - 1]);
        // }
        // // If the documentIndex is not the last document, then set the nextDocumentIndex
        // if (documentIndex < response.data.documents.length - 1) {
        //   setNextDocument(response.data.documents[documentIndex + 1]);
        // }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [location, errors]); // Changed to use location object directly

  const errorsStackedInTransactionCount = (transactionId: string) => {
    let errorCount = 0;
    if (
      transactionId &&
      transactionId !== '' &&
      errors &&
      errors[transactionId]
    ) {
      Object.keys(errors[transactionId]).forEach((documentType) => {
        errorCount += (errors[transactionId][documentType] || []).length;
      });
    }
    return errorCount;
  };

  // const goToNextDocument = () => {
  //   console.log('Current index: ', currentDocumentIndex);
  //   if (nextDocument && nextDocument._id) {
  //     console.log('Next Document: ', nextDocument);
  //     const documentId = nextDocument._id;
  //     setPrevDocument(
  //       transactionData?.documents
  //         ? transactionData.documents[currentDocumentIndex]
  //         : ({} as Document)
  //     );
  //     setNextDocument(
  //       transactionData?.documents &&
  //         currentDocumentIndex + 2 < transactionData.documents.length
  //         ? transactionData.documents[currentDocumentIndex + 2]
  //         : ({} as Document)
  //     );
  //     setCurrentDocumentIndex(currentDocumentIndex + 1);

  //     // Also set up the nextDocument and prevDocument after navigating
  //     navigate(
  //       AppURLs.complianceDashboardTransactionVerifyDocument(
  //         transactionId,
  //         documentId
  //       )
  //     );
  //   }
  // };

  // const goToPrevDocument = () => {
  //   console.log('Current index: ', currentDocumentIndex);
  //   if (prevDocument && prevDocument._id) {
  //     console.log('Prev Document: ', prevDocument);
  //     const documentId = prevDocument._id;
  //     setNextDocument(
  //       transactionData?.documents
  //         ? transactionData.documents[currentDocumentIndex]
  //         : ({} as Document)
  //     );
  //     setPrevDocument(
  //       transactionData?.documents && currentDocumentIndex - 2 >= 0
  //         ? transactionData.documents[currentDocumentIndex - 2]
  //         : ({} as Document)
  //     );
  //     setCurrentDocumentIndex(currentDocumentIndex - 1);

  //     // Also set up the nextDocument and prevDocument after navigating
  //     navigate(
  //       AppURLs.complianceDashboardTransactionVerifyDocument(
  //         transactionId,
  //         documentId
  //       )
  //     );
  //   }
  // };

  const resetTransactionErrors = () => {
    clearErrorsForTransaction(transactionId);
    setBannerVisible(false);
  };

  return (
    <>
      {bannerVisble &&
        transactionId !== '' &&
        transactionData &&
        errors?.[transactionId] && (
          <Box
            display="flex"
            width="100%"
            color="white"
            bgcolor="#2F78EB"
            padding="0.5rem"
            alignItems="center"
          >
            <Typography
              variant="h6"
              style={{ flexGrow: 1, textAlign: 'center' }}
            >
              {Object.keys(errors).length > 0 &&
                (() => {
                  const getErrorCount = (transactionErrors: any) => {
                    return Object.keys(transactionErrors).reduce(
                      (acc, key) =>
                        key === '_missingDocuments'
                          ? acc + 1
                          : acc + transactionErrors[key].length,
                      0
                    );
                  };

                  const count = getErrorCount(errors[transactionId]);
                  return `Reviewing ${count} ${count === 1 ? 'Error' : 'Errors'}: Action Required`;
                })()}
            </Typography>
            {/* {location.pathname.includes(
              '/compliance/dashboard/transactions/verify'
            ) && (
              <Box display="flex" alignItems="center">
                <IconButton
                  title={
                    prevDocument && prevDocument._id
                      ? `Go to ${documentNameMap[prevDocument.type]}`
                      : ''
                  }
                  style={{
                    color:
                      prevDocument && prevDocument._id ? '#2F78EB' : '#9e9e9e',
                    backgroundColor: 'white',
                    marginRight: '0.25rem',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                  sx={{
                    '&:hover': {
                      transform:
                        prevDocument && prevDocument._id
                          ? 'scale(1.1)'
                          : 'none',
                    },
                  }}
                  onClick={() => goToPrevDocument()}
                  disabled={!prevDocument || !prevDocument._id}
                >
                  <ArrowBackRounded />
                </IconButton>
                <IconButton
                  title={
                    nextDocument && nextDocument._id
                      ? `Go to ${documentNameMap[nextDocument.type]}`
                      : ''
                  }
                  style={{
                    color:
                      nextDocument && nextDocument._id ? '#2F78EB' : '#9e9e9e',
                    backgroundColor: 'white',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                  sx={{
                    '&:hover': {
                      transform:
                        nextDocument && nextDocument._id
                          ? 'scale(1.1)'
                          : 'none',
                    },
                  }}
                  onClick={() => goToNextDocument()}
                  disabled={!nextDocument || !nextDocument._id}
                >
                  <ArrowForwardRounded />
                </IconButton>
              </Box>
            )} */}
            <Button
              style={{ margin: '0.25rem 1rem' }}
              onClick={() => {
                setIsErrorsModalVisible(true);
              }}
            >
              View Errors
            </Button>
            <IconButton
              onClick={() => resetTransactionErrors()}
              style={{ color: 'white', marginRight: '1rem' }}
            >
              <CloseRounded />
            </IconButton>
          </Box>
        )}

      <ErrorsDrawer
        visible={isErrorsModalVisible}
        onClose={() => {
          setIsErrorsModalVisible(false);
        }}
        transactionData={transactionData}
      />
    </>
  );
};

export default ErrorsStacker;
