import { AppURLs } from './appURLs';
import axios from 'axios';
import environment from 'app/config/environment';
import { getFirebaseAccessToken } from './firebase';

const api = axios.create({
  // baseURL: 'https://maxhome.ai/api',
  baseURL: environment.api.API_BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getFirebaseAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      if (error.config.url.includes('/compliance/')) {
        window.location.href = AppURLs.agentLoginSessionExpired();
      } else if (error.config.url.includes('/agent/')) {
        window.location.href = AppURLs.agentLoginSessionExpired();
      } else if (error.config.url.includes('/office-admin')) {
        window.location.href = AppURLs.agentLoginSessionExpired();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
