import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit';

interface UIState {
  orientation: 'portrait' | 'landscape';
  isMobile: boolean;
}

const initialState: UIState = {
  orientation: 'portrait',
  isMobile: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setOrientation: (state, action) => {
      state.orientation = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

const updateOrientation = () => {
  const orientation = window.matchMedia('(orientation: portrait)').matches
    ? 'portrait'
    : 'landscape';
  return uiSlice.actions.setOrientation(orientation);
};

export const setOrientation = createAsyncThunk(
  'ui/setOrientation',
  async (_, { dispatch }) => {
    // Set the initial orientation
    dispatch(updateOrientation());

    const handleResize = () => {
      dispatch(updateOrientation());
    };

    // Update the orientation when the screen orientation changes
    window.addEventListener('resize', handleResize);

    // Clean up the listener when the thunk is cancelled
    return isRejectedWithValue(() => {
      window.removeEventListener('resize', handleResize);
    });
  }
);

const updateIsMobile = () => {
  const isMobile = window.innerWidth <= 820; // adjust the width as necessary
  return uiSlice.actions.setIsMobile(isMobile);
};

export const setIsMobile = createAsyncThunk(
  'ui/setIsMobile',
  async (_, { dispatch }) => {
    // Set the initial isMobile value
    dispatch(updateIsMobile());

    const handleResize = () => {
      dispatch(updateIsMobile());
    };

    // Update the isMobile value when the window size changes
    window.addEventListener('resize', handleResize);

    // Clean up the listener when the thunk is cancelled
    return isRejectedWithValue(() => {
      window.removeEventListener('resize', handleResize);
    });
  }
);

export default uiSlice.reducer;
