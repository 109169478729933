import { validateEnvVariable } from './helpers';

const { REACT_APP_API_BASE_URL } = process.env;

const API_BASE_URL = validateEnvVariable(REACT_APP_API_BASE_URL);

const apiEnvironment = {
  API_BASE_URL,
};

export default apiEnvironment;
