import React from 'react';
import './Paginator.scss';

interface PaginatorProps {
  pageSize?: number;
  offset: number;
  pageNumber: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  background?: string;
  paginate: (page: number) => void;
}

const Paginator: React.FC<PaginatorProps> = ({
  pageSize = 10,
  offset,
  pageNumber,
  totalElements,
  first,
  last,
  background,
  paginate,
}) => {
  const start = (pageNumber - 1) * pageSize + 1;
  const end = Math.min(pageNumber * pageSize, totalElements);

  return (
    <div className="base-paginator" style={{ background: background }}>
      <div className="section">
        {start} - {end} of {totalElements}
      </div>
      <div className="section">
        <div className="nav-holder">
          <div
            className={first ? 'nav-disabled' : 'nav'}
            onClick={() => {
              if (!first) paginate(pageNumber - 1);
            }}
          ></div>
          <div
            className={last ? 'nav-disabled' : 'nav'}
            style={{ transform: last ? 'rotate(0deg)' : '' }}
            onClick={() => {
              if (!last) paginate(pageNumber + 1);
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
