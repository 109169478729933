import './ErrorCard.scss';

import { Box, IconButton, Typography } from '@mui/material';
import { Button, Card, Tooltip, message } from 'antd';
import { CheckRounded, CloseRounded, FlagOutlined } from '@mui/icons-material';

import { ErrorActions } from '../utils/ErrorActions';
import { ErrorSeverityChips } from './ErrorSeverityChip';
import { useState } from 'react';

export const ErrorCardComponent = ({
  error,
  index,
  onClick = () => {},
  selectedError = {},
  errorActionsList = [],
  setErrorActionsList = {},
  verifyDocumentView = true,
}: {
  error: any;
  index: number;
  onClick?: any;
  selectedError?: any;
  errorActionsList?: any;
  setErrorActionsList?: any;
  verifyDocumentView?: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card
      onClick={onClick}
      style={{
        cursor: 'pointer',
        marginBottom: '0.5rem',
        boxShadow: isHovered ? '0 0 5px rgba(0, 0, 0, 0.3)' : 'none',
        borderColor: `${selectedError.name === error.name ? '#2F78EB' : 'white'}`,
        color: 'black',
        backgroundColor: isHovered
          ? error?.severity === 'optional'
            ? '#E6F4FF'
            : '#FFF1F0'
          : 'white',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
      // title={
      //   (error?.severity &&
      //     capitalizeFirstLetter(error?.severity?.toLowerCase())) ||
      //   'Required'
      // }
      >
        <Box display="flex" marginBottom="1rem" alignItems="center">
          {/* <ErrorRounded sx={{ color: error?.severity === 'optional' ? '#2F78EB' : '#FF4D4F', marginRight: '0.5rem', height: '1.5rem', width: '1.5rem' }} /> */}
          <Typography fontSize="1rem" fontWeight={500} marginRight="1rem">
            Action Needed - {error.errorTitle}
          </Typography>
          <Box marginLeft="auto">
            {ErrorSeverityChips[error?.severity || 'required']}
            {/* Default to required */}
          </Box>
          {/* <IconButton>
            <CloseRounded />
          </IconButton> */}
        </Box>

        <Box display="flex" alignItems="center">
          <Typography fontSize="0.8rem" fontWeight={400}>
            <div
              dangerouslySetInnerHTML={{
                __html: error?.descriptionFormattedString || error?.description,
              }}
            />
          </Typography>
        </Box>

        {verifyDocumentView && (
          <Box className="error-card-actions">
            <Button
              shape="round"
              className={`error-card-button ${
                errorActionsList.length > 0 &&
                errorActionsList?.[index].action === ErrorActions.FIX
                  ? 'fix'
                  : ''
              }`}
              onClick={() => {
                console.log('Fixing error: ', error);
                let newErrorActions = [...errorActionsList];
                newErrorActions[index] = { action: ErrorActions.FIX };
                console.log('New Error Actions: ', newErrorActions);
                setErrorActionsList(newErrorActions);
              }}
            >
              Fix
              {errorActionsList.length > 0 &&
                errorActionsList[index].action === ErrorActions.FIX && (
                  <CheckRounded className="check-icon" />
                )}
            </Button>
            <Button
              shape="round"
              className={`error-card-button ${
                errorActionsList.length > 0 &&
                errorActionsList[index].action === ErrorActions.IGNORE
                  ? 'ignore'
                  : ''
              }`}
              onClick={() => {
                console.log('Ignoring error: ', error);
                let newErrorActions = [...errorActionsList];
                newErrorActions[index] = { action: ErrorActions.IGNORE };
                console.log('New Error Actions: ', newErrorActions);
                setErrorActionsList(newErrorActions);
              }}
            >
              Ignore
              {errorActionsList.length > 0 &&
                errorActionsList[index].action === ErrorActions.IGNORE && (
                  <CheckRounded className="check-icon" />
                )}
            </Button>
            <IconButton
              className="flag-button"
              onClick={() => {
                message.info('Max will look into this issue');
              }}
            >
              <FlagOutlined />
            </IconButton>
          </Box>
        )}
      </Tooltip>
    </Card>
  );
};

export default ErrorCardComponent;
