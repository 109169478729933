import { LinearProgress } from '@mui/material';
import React from 'react';

const LoadingProgressBar: React.FC = () => {
  return (
    <div className="loading-progress-bar">
      <LinearProgress />
    </div>
  );
};

export default LoadingProgressBar;
