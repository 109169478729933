import { Route, Routes } from 'react-router-dom';
import './OfficeAdmin.scss';
import ComplianceChecksPage from 'app/components/common/Checks';
import { FormatListBulletedOutlined, MenuOutlined } from '@mui/icons-material';
import { AppURLs } from 'app/utils/appURLs';
import { Module } from './components/Navbar/types';
import { useState } from 'react';
import maxHomeLogo from 'assets/logos/maxhome.svg';
import { AppBar, Box, Toolbar } from '@mui/material';
import CommonLeftNavBar from 'app/components/common/LeftNavbar';
import { ResponsiveRenderer } from 'app/utils/super-components/responsiveRenderer';
import { getLoggedInPartnerLogo } from 'app/utils/logo';

const appBarStyle: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'white',
  boxShadow: 'none',
  paddingBottom: '0.5rem',
};

const logoStyleCompressed: React.CSSProperties = {
  maxWidth: '5rem',
  maxHeight: '2.5rem',
};

const OfficeAdminDashboard = () => {
  const [showMobileNavBar, setShowMobileNavBar] = useState(false);
  const initialModules: Module[] = [
    {
      name: 'Overview',
      icon: FormatListBulletedOutlined,
      path: AppURLs.officeAdminChecks(),
      alignment: 'normal',
    },
  ];

  const DesktopNavBar = (
    <Box
      sx={{ position: 'sticky', left: 0, top: 0, bottom: 0, width: '15rem' }}
    >
      <CommonLeftNavBar initialModules={initialModules} />
    </Box>
  );

  const MobileNavBar = (
    <>
      {showMobileNavBar && (
        <CommonLeftNavBar
          initialModules={initialModules}
          onClick={() => {
            setShowMobileNavBar(false);
          }}
        />
      )}
    </>
  );

  // The Main Navbar for the dashboard. Left side for desktop and fullscreen for mobile.
  const MainNavBar = (
    <ResponsiveRenderer
      FullView={DesktopNavBar}
      CompressedView={MobileNavBar}
    />
  );

  // The App Bar at the top to be shown when the navbar is not visible in the mobile view
  const MobileAppBar = (
    <AppBar position="sticky" style={appBarStyle}>
      <Toolbar>
        <img
          src={getLoggedInPartnerLogo('Compliance')}
          alt="Partner Logo"
          style={logoStyleCompressed}
        />
        <img
          src={maxHomeLogo}
          alt="MaxHome Logo"
          style={{
            ...logoStyleCompressed,
            marginLeft: '0.5rem',
            paddingLeft: '0.5rem',
            borderLeft: '1px solid black',
          }}
        />
        <MenuOutlined
          sx={{ marginLeft: 'auto', color: 'black' }}
          onClick={() => setShowMobileNavBar(true)}
        />
      </Toolbar>
    </AppBar>
  );

  return (
    <>
      <div className="office-admin-dashboard">
        <div className="dashboard-container">
          {MainNavBar}
          <div className="content-container">
            {/* Display the AppBar if it is in mobile view after selection */}
            <ResponsiveRenderer
              CompressedView={MobileAppBar}
              FullView={<></>}
            />
            <Routes>
              <Route
                path="checks"
                element={<ComplianceChecksPage isCompliance={false} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeAdminDashboard;
