import { errorDocumentIdsAndActionsListAtom } from 'app/atoms';
import { useRecoilState } from 'recoil';

const useTransactionErrorDocumentIdsAndActionListManager = () => {
  const [errorDocumentIdsAndActionList, setErrorDocumentIdsAndActionList] =
    useRecoilState(errorDocumentIdsAndActionsListAtom);

  // Function to add a record
  const addData = (
    documentId: string,
    errorActionList: Record<string, string>[]
  ) => {
    if (!Object.keys(errorDocumentIdsAndActionList).includes(documentId)) {
      setErrorDocumentIdsAndActionList(
        (prevIds: Record<string, Record<string, string>[] | string[]>) => {
          const newIds: Record<string, Record<string, string>[] | string[]> = {
            ...prevIds,
            [documentId]: errorActionList,
          };
          return newIds;
        }
      );
    }
  };

  // Function to remove a record
  const removeData = (documentId: string) => {
    setErrorDocumentIdsAndActionList(
      (prevIds: Record<string, Record<string, string>[] | string[]>) => {
        const newIds = { ...prevIds };
        delete newIds[documentId];
        return newIds;
      }
    );
  };

  return {
    errorDocumentIdsAndActionList,
    addData,
    removeData,
  };
};

export default useTransactionErrorDocumentIdsAndActionListManager;
