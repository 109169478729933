import LoadingSpinner from 'app/components/common/LoadingSpinner';
import React, { Suspense } from 'react';

function useSuspense(
  Component: React.LazyExoticComponent<React.ComponentType<any>>
) {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Component />
    </Suspense>
  );
}

export default useSuspense;
