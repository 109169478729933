import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import authSliceReducer from 'features/auth';
import pageSliceReducer from 'features/page';
import uiSliceReducer from 'features/ui';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    page: pageSliceReducer,
    ui: uiSliceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

// import counterReducer from '../features/counter/counterSlice';

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//   },
// });

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;
