import { useLocation, useNavigate } from 'react-router-dom';

import { AppURLs } from 'app/utils/appURLs';
import { CircularProgress } from '@mui/material';
import { getFirebaseAccessToken } from 'app/utils/firebase';
import { message } from 'antd';
import { useEffect } from 'react';

interface WithAuthenticationRequiredOptions {
  onRedirecting?: () => JSX.Element;
  getAccessToken?: () => Promise<string | null>;
}

const defaultOnRedirecting = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <CircularProgress />
  </div>
);

// TODO: Remove this after ascertaining firebase auth works
// const defaultGetAccessToken = async () => {
//   const authToken = getAuthToken();
//   if (!authToken) {
//     return null;
//   }
//   const userSvc = new UserService();
//   try {
//     const response = await userSvc.getUserProfile();
//     console.log("User profile in defaultGetAccessToken: ", response.data);
//     const authToken = getAuthToken();
//     return authToken;
//   } catch (err) {
//     console.error(err);
//     return null;
//   }
// };

const defaultGetAccessToken = async () => {
  const accessToken = await getFirebaseAccessToken();
  return accessToken;
};

// TODO: Implement redirection after login
const withAuthenticationRequired = <P extends object>(
  Component: React.ComponentType<P>,
  Role: string,
  options: WithAuthenticationRequiredOptions = {}
): React.FC<P> => {
  const {
    onRedirecting = defaultOnRedirecting,
    getAccessToken = defaultGetAccessToken,
  } = options;

  return function WithAuthenticationRequired(props: P): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    // const memoizedGetAccessToken = useCallback(() => {
    //   return getAccessToken ? getAccessToken() : Promise.resolve(null);
    // }, [getAccessToken]);

    // TODO: Make Auth redirect back to the page which triggered this auth check

    // useEffect(() => {
    //   (async (): Promise<void> => {
    //     // const authToken = await getAccessToken();
    //     const authToken = await memoizedGetAccessToken();
    //     if (!authToken) {
    //       navigate("/v2/agent/login", { state: { from: location } });
    //       // } else {
    //       //   console.log("User authenticated");
    //       //   if (location.state?.from) {
    //       //     console.log("Navigating to: ", location.state.from);
    //       //     navigate(location.state.from);
    //       //   }
    //     }
    //   })();
    // }, [memoizedGetAccessToken]);

    useEffect(() => {
      (async (): Promise<void> => {
        const firebaseAccessToken = await getFirebaseAccessToken();
        if (!firebaseAccessToken) {
          if (Role === 'agent') {
            navigate(AppURLs.agentLogin(), { state: { from: location.pathname } });
            return;
          } else if (Role === 'compliance') {
            navigate(AppURLs.agentLogin(), { state: { from: location.pathname } });
            return;
          } else if (Role === 'officeAdmin') {
            navigate(AppURLs.agentLogin(), { state: { from: location.pathname } });
            return;
          } else {
            message.error('Invalid login role. Please contact support.');
            navigate('/');
          }
        }
      })();
    }, [location, navigate]);

    return <Component {...props} />;
  };
};

const RoleProtectedRoute = ({
  Component,
  Role,
}: {
  Component: React.ComponentType;
  Role: string;
}) => {
  const ProtectedComponent = withAuthenticationRequired(Component, Role, {
    onRedirecting: defaultOnRedirecting,
    getAccessToken: defaultGetAccessToken as () => Promise<string | null>,
  });

  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   if (location.state?.from) {
  //     console.log("Navigating to: ", location.state.from);
  //     navigate(location.state.from);
  //   }
  // }, []);

  return <ProtectedComponent />;
};

export default RoleProtectedRoute;
