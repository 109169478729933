import { ComplianceService } from 'app/services/compliance';

interface TransactionBuilderOptions {
  page?: number;
  limit?: number;
  createdAtGte?: string;
  createdAtLte?: string;
  closingDateGte?: string;
  closingDateLte?: string;
  status?: string[];
  search?: string;
  transactionTypes?: string[];
}

export class TransactionBuilder {
  private page?: number;
  private limit?: number;
  private createdAtGte?: string;
  private createdAtLte?: string;
  private closingDateGte?: string;
  private closingDateLte?: string;
  private transactionTypes?: string[];
  private status?: string[];
  private search?: string;
  public hasMore: boolean;

  private constructor(options: TransactionBuilderOptions) {
    this.page = options.page;
    this.limit = options.limit;
    this.createdAtGte = options.createdAtGte;
    this.createdAtLte = options.createdAtLte;
    this.closingDateGte = options.closingDateGte;
    this.closingDateLte = options.closingDateLte;
    this.status = options.status;
    this.search = options.search;
    this.hasMore = true;
    this.transactionTypes = options.transactionTypes;
  }

  static builder = class {
    private page?: number;
    private limit?: number;
    private createdAtGte?: string;
    private createdAtLte?: string;
    private closingDateGte?: string;
    private closingDateLte?: string;
    private status?: string[];
    private search?: string;
    private transactionTypes?: string[];

    setPage(page: number): this {
      this.page = page;
      return this;
    }

    setLimit(limit: number): this {
      this.limit = limit;
      return this;
    }

    setCreatedAtGte(date: string): this {
      this.createdAtGte = date;
      return this;
    }

    setCreatedAtLte(date: string): this {
      this.createdAtLte = date;
      return this;
    }

    setClosingDateGte(date: string): this {
      this.closingDateGte = date;
      return this;
    }

    setClosingDateLte(date: string): this {
      this.closingDateLte = date;
      return this;
    }

    setTransactionTypes(types: string[]): this {
      this.transactionTypes = types;
      return this;
    }

    setStatus(status: string[]): this {
      this.status = status;
      return this;
    }

    setSearch(search: string): this {
      this.search = search;
      return this;
    }

    build(): TransactionBuilder {
      return new TransactionBuilder({
        page: this.page,
        limit: this.limit,
        createdAtGte: this.createdAtGte,
        createdAtLte: this.createdAtLte,
        closingDateGte: this.closingDateGte,
        closingDateLte: this.closingDateLte,
        status: this.status,
        search: this.search,
        transactionTypes: this.transactionTypes,
      });
    }
  };

  async fetchNext(): Promise<any> {
    try {
      const response: any = await ComplianceService.getTransactions(
        this.search,
        this.status,
        this.page,
        this.limit,
        this.createdAtGte,
        this.createdAtLte,
        this.closingDateGte,
        this.closingDateLte,
        this.transactionTypes
      );
      if (this.page !== undefined && this.limit !== undefined) {
        const totalFetched = this.page * this.limit;
        this.hasMore = totalFetched < response.data.total;
      } else {
        this.hasMore = false;
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
}
