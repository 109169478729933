import { Box } from '@mui/material';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { useEffect, useState } from 'react';
import WorkAllocationTable from './components/WorkAllocationTable';
import { Button, message } from 'antd';
import OutOfOfficeModal from './components/OutOfOfficeModal';
import ReassignPrimaryAdminModal from './components/ReassignPrimaryAgentModal';
import { WorkAllocationService } from '../util/workAllocationService';
import { OfficeService } from 'app/services/office';

const WorkAllocation = () => {
  const [progressBarLoading, setProgressBarLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oooModalVisible, setOooModalVisible] = useState(false);
  const [reassignModalVisible, setReassignModalVisible] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState<any | null>({});
  const [workAllocationData, setWorkAllocationData] = useState<any[]>([]);
  const [offices, setOffices] = useState([]);
  const [processingTeamMembers, setProcessingTeamMembers] = useState([]);

  const getWorkAllocations = async (loading = false) => {
    if (loading) setLoading(true);
    setProgressBarLoading(true);
    try {
      const response: any = await WorkAllocationService.getWorkAllocations();
      setWorkAllocationData(response?.data);
    } catch (error: any) {
      setWorkAllocationData([]);
      message.error(error.message);
    } finally {
      setProgressBarLoading(false);
      setLoading(false);
    }
  };

  const getOffices = async () => {
    try {
      const response: any = await OfficeService.getOffices('Compliance', '');
      setOffices(response.data.data);
    } catch (error) {
      message.error('Error fetching offices');
    }
  };

  const getProcessingTeamMembers = async () => {
    try {
      const response: any =
        await WorkAllocationService.getProcessingTeamMembers();
      if (response.status === 200) setProcessingTeamMembers(response.data.data);
    } catch (error) {
      message.error('Error fetching processing team members');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          getWorkAllocations(true),
          getOffices(),
          getProcessingTeamMembers(),
        ]);
      } catch (error : any) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const component = (
    <>
      <Box width="100%" boxSizing="border-box">
        {progressBarLoading && (
          <Box width="100%">
            <LoadingProgressBar />
          </Box>
        )}

        <div className="checks-page">
          <div className="header">Offices & Teams</div>
          <div
            className="filter-bar"
            style={{
              display: 'flex',
              justifyContent: 'end',
              padding: '0.5rem',
            }}
          >
            {/* <Button
              onClick={() => setOooModalVisible(true)} // Open modal on click
              style={{
                height: '2.5rem',
                borderRadius: '1rem',
                border: '1px solid #1677FF',
                color: '#1677FF',
              }}
            >
              Set out of office
            </Button> */}
          </div>
          <div className="content-container">
            <WorkAllocationTable
              processingTeamMembers={processingTeamMembers}
              workAllocationData={workAllocationData}
              setOooModalVisible={(visible, office) => {
                setSelectedOffice(office || null);
                setOooModalVisible(visible);
              }}
              setReassignModalVisible={(visible, office) => {
                setSelectedOffice(office || null);
                setReassignModalVisible(visible);
              }}
              getWorkAllocations={getWorkAllocations}
            />
          </div>
        </div>
      </Box>

      {/* OutOfOfficeModal component */}
      <OutOfOfficeModal
        visible={oooModalVisible}
        onCancel={() => setOooModalVisible(false)}
        offices={offices}
        selectedOffice={selectedOffice}
        getWorkAllocations={getWorkAllocations}
        processingTeamMembers={processingTeamMembers}
      />

      {/* ReassignPrimaryAdminModal component */}
      <ReassignPrimaryAdminModal
        selectedOffice={selectedOffice}
        visible={reassignModalVisible}
        onCancel={() => setReassignModalVisible(false)}
        processingTeamMembers={processingTeamMembers}
        getWorkAllocations={getWorkAllocations}
      />
    </>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default WorkAllocation;
