import { AppBar, Box, Toolbar } from '@mui/material';
import {
  ContactsOutlined,
  FormatListBulletedOutlined,
  HomeWorkOutlined,
  MenuOutlined,
  PictureInPictureTwoTone,
} from '@mui/icons-material';
import { Modal, message } from 'antd';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  getAdminProfile,
  getComplianceAdminProfile,
} from 'app/utils/localStorageHandler/userProfile';
import {
  getDocumentIDsFromLocalStorage,
  setDocumentIDsInLocalStorage,
} from 'app/utils/localStorageHandler/document';
import { useEffect, useRef, useState } from 'react';

import { AppURLs } from 'app/utils/appURLs';
import CommonLeftNavBar from 'app/components/common/LeftNavbar';
import ComplianceChecksPage from 'app/components/common/Checks';
import { ComplianceNavBar } from './components/NavBar';
import ComplianceTransactionDetailsPage from './pages/transaction-details';
import ComplianceTransactionsPage from './pages/transactions';
import { ComplianceVerifyDocumentPage } from './pages/transaction-details/verify-document';
import { DocumentService } from 'app/services/document';
import ErrorsStacker from './pages/transaction-details/modules/errors-stacker';
import FileExtractionStatusBar from 'app/components/common/FileExtractionStatusBar';
import { Module } from 'app/pages/Agent/Dashboard/components/types';
import { ResponsiveRenderer } from 'app/utils/super-components/responsiveRenderer';
import TransactionContactsList from './pages/contacts';
import TransactionKeyFacts from 'app/modules/source-of-truth';
import ValidationView from 'app/components/common/CheckValidationView';
import WorkAllocation from 'app/modules/WorkAllocation';
import { documentIDsAtom } from 'app/atoms';
import { getCustomIdFromUrl } from 'app/utils/url';
import { getLoggedInPartnerLogo } from 'app/utils/logo';
import maxHomeLogo from 'assets/logos/maxhome.svg';
import { useRecoilState } from 'recoil';

const appBarStyle: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'white',
  boxShadow: 'none',
  paddingBottom: '0.5rem',
};

const logoStyleCompressed: React.CSSProperties = {
  maxWidth: '5rem',
  maxHeight: '2.5rem',
};

const ComplianceDashboard = () => {
  const location = useLocation();
  const alertBoxRef = useRef(null);
  const [showMobileNavBar, setShowMobileNavBar] = useState(false);

  const [mainBoxHeight, setMainBoxHeight] = useState('100%');

  // Check if the app is waiting on some document extraction
  const [documentIDs, setDocumentIDs] = useRecoilState(documentIDsAtom);

  // This is the useEffect that will run the first when the page is loaded
  // This will update the atom so that the other elements are updated accordingly
  useEffect(() => {
    // Get the fileUploadID from localStorage
    // If it is not an empty list, set isUploading to true

    console.log('Document IDs from state atom is: ', documentIDs);

    // If documentIDs is an empty list, then check local storage
    if (documentIDs.length === 0) {
      const documentIDsFromLocalStorage = JSON.parse(
        getDocumentIDsFromLocalStorage() || '[]'
      );
      if (
        documentIDsFromLocalStorage &&
        documentIDsFromLocalStorage.length > 0
      ) {
        setDocumentIDs([documentIDsFromLocalStorage]);
      } else {
        return;
      }
    }

    // Keep polling for the fileUploadID and upon success/error update the alert as well
    // Poll for 7.5 minutes max (90 attempts at 5 seconds each)
    let counter = 0;
    let maxAttempts = 90;

    const interval = setInterval(async () => {
      // Check the status of the file upload
      // If the status is completed, set isUploading to false
      // If the status is error, set isUploading to false and show an error message
      // If the status is pending, keep polling

      // If the maximum number of attempts has been reached, clear the interval and stop polling
      console.log('Attempt: ', counter);
      if (counter >= maxAttempts) {
        message.error('Document upload timed out');
        setDocumentIDs([]);
        setDocumentIDsInLocalStorage([]);
        clearInterval(interval);
      }

      const documentSvc = new DocumentService();

      let responses = [];
      let response;
      let i: number;
      try {
        for (i = 0; i < documentIDs.length; i++) {
          response = await documentSvc.getDocument(documentIDs[i]);
          responses.push(response);
        }
      } catch {
        message.error(
          'An error occurred while fetching document extraction status'
        );
        setDocumentIDs(documentIDs.filter((id) => id !== documentIDs[i]));
        setDocumentIDsInLocalStorage(
          documentIDs.filter((id) => id !== documentIDs[i])
        );

        if (documentIDs.length === 0) {
          clearInterval(interval);
          return;
        }
      }

      let terminatedResponses = 0;
      for (let j = 0; j < responses.length; j++) {
        response = responses[j];
        console.log('Polling Response: ', response.data);

        // If the document type is 'UNKNOWN', then don't poll anymore. Remove the document from the list
        if (response.data.type === 'UNKNOWN') {
          setDocumentIDs(documentIDs.filter((id) => id !== documentIDs[j]));
          setDocumentIDsInLocalStorage(
            documentIDs.filter((id) => id !== documentIDs[j])
          );
          terminatedResponses++;
        }

        if (response.status === 404) {
          // Handle 404 error
          message.error('File upload not found');
          setDocumentIDs(documentIDs.filter((id) => id !== documentIDs[j]));
          setDocumentIDsInLocalStorage(
            documentIDs.filter((id) => id !== documentIDs[j])
          );
          terminatedResponses++;
        }

        // If response status is not 200, it means that there is an error with the API
        // Abort execution and notify the user of an error and return the code
        if (response.status !== 200) {
          message.error('An error occurred while fetching file upload status');
          setDocumentIDs(documentIDs.filter((id) => id !== documentIDs[j]));
          setDocumentIDsInLocalStorage(
            documentIDs.filter((id) => id !== documentIDs[j])
          );
          terminatedResponses++;
        }

        if (response.data.extraction.status === 'SUCCESS') {
          message.success(
            `${response.data.title} [${response.data.type}] extracted successfully`
          );
          setDocumentIDs(documentIDs.filter((id) => id !== documentIDs[j]));
          setDocumentIDsInLocalStorage(
            documentIDs.filter((id) => id !== documentIDs[j])
          );
          terminatedResponses++;
        } else if (response.data.status === 'FAILED') {
          // message.error(`${response.data.title} [${response.data.type}] extraction failed: ${response.data.error}`);
          Modal.error({
            title: `${response.data.title} [${response.data.type}] extraction failed`,
            content: response.data.error.toString(),
          });
          setDocumentIDs(documentIDs.filter((id) => id !== documentIDs[j]));
          setDocumentIDsInLocalStorage(
            documentIDs.filter((id) => id !== documentIDs[j])
          );
          terminatedResponses++;
        }

        if (terminatedResponses === responses.length) {
          clearInterval(interval);
        }
      }

      // Increment the counter
      counter++;
    }, 5000);

    // Return a function that clears the interval
    return () => {
      clearInterval(interval);
    };
  }, [documentIDs]);

  useEffect(() => {
    if (alertBoxRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const offsetHeight = (entry.target as HTMLElement).offsetHeight;
          console.log('OffsetHeight: ', offsetHeight);
          setMainBoxHeight(`calc(100% - ${offsetHeight}px)`);
        }
      });

      // Start observing the alertBoxRef
      resizeObserver.observe(alertBoxRef.current);

      // Create a variable to store the current value of alertBoxRef
      const currentAlertBoxRef = alertBoxRef.current;

      // Cleanup function to stop observing the alertBoxRef using the variable
      return () => resizeObserver.unobserve(currentAlertBoxRef);
    } else {
      setMainBoxHeight('100%');
    }
  }, [documentIDs]);

  const adminProfile = getAdminProfile();
  const processingAdminProfile = getComplianceAdminProfile();

  let initialModules: Module[] = processingAdminProfile
    ? [
        {
          name: 'Checks',
          icon: PictureInPictureTwoTone,
          path: AppURLs.complianceDashboardChecks(),
          secondaryPaths: [],
          alignment: 'normal',
        },
        {
          name: 'Transactions',
          icon: FormatListBulletedOutlined,
          path: AppURLs.complianceDashboardTransactions(),
          alignment: 'normal',
        },
        {
          name: 'Offices & Teams',
          icon: HomeWorkOutlined,
          path: AppURLs.complianceDashbaordWorkAllocation(),
          alignment: 'normal',
        },
      ]
    : [
        {
          name: 'Checks',
          icon: PictureInPictureTwoTone,
          path: AppURLs.complianceDashboardChecks(),
          secondaryPaths: [],
          alignment: 'normal',
        },
        {
          name: 'Transactions',
          icon: FormatListBulletedOutlined,
          path: AppURLs.complianceDashboardTransactions(),
          alignment: 'normal',
        },
      ];

  let customModules: Module[] = adminProfile
    ? [
        {
          name: 'Documents',
          icon: PictureInPictureTwoTone,
          path: AppURLs.complianceDashboardTransaction(
            getCustomIdFromUrl('transactionID')
          ),
          secondaryPaths: [],
          alignment: 'normal',
        },
        {
          name: 'Facts',
          icon: FormatListBulletedOutlined,
          path: AppURLs.complianceDashboardTransactionFacts(
            getCustomIdFromUrl('transactionID')
          ),
          alignment: 'normal',
        },
        {
          name: 'Contacts',
          icon: ContactsOutlined,
          path: AppURLs.complianceDashboardTransactionContacts(
            getCustomIdFromUrl('transactionID')
          ),
          alignment: 'normal',
        },
      ]
    : [
        {
          name: 'Documents',
          icon: PictureInPictureTwoTone,
          path: AppURLs.complianceDashboardTransaction(
            getCustomIdFromUrl('transactionID')
          ),
          secondaryPaths: [],
          alignment: 'normal',
        },
      ];

  const customNavBarRoutes = [
    AppURLs.complianceDashboardTransaction(''),
    AppURLs.complianceDashboardTransactionContacts(''),
    AppURLs.complianceDashboardTransactionFacts(''),
    AppURLs.complianceDashboardTransactionVerifyDocument('', ''),
  ];

  const [currentModules, setCurrentModules] = useState<any>(initialModules);

  useEffect(() => {
    const showCustomNavBar = customNavBarRoutes.some((route) => {
      const baseRoute = route.split('?')[0]; // Remove query parameters
      return location.pathname.startsWith(baseRoute);
    });
    console.log(showCustomNavBar, 'ShowCustomNavBar');
    if (showCustomNavBar) {
      setCurrentModules(customModules);
    } else {
      setCurrentModules(initialModules);
    }
  }, [location.pathname]);

  const DesktopNavBar = (
    <Box
      sx={{ position: 'sticky', left: 0, top: 0, bottom: 0, width: '15rem' }}
    >
      <CommonLeftNavBar initialModules={currentModules} />
    </Box>
  );

  const MobileNavBar = (
    <>
      {showMobileNavBar && (
        <CommonLeftNavBar
          initialModules={currentModules}
          onClick={() => {
            setShowMobileNavBar(false);
          }}
        />
      )}
    </>
  );

  // The Main Navbar for the dashboard. Left side for desktop and fullscreen for mobile.
  const MainNavBar = (
    <ResponsiveRenderer
      FullView={DesktopNavBar}
      CompressedView={MobileNavBar}
    />
  );

  // The App Bar at the top to be shown when the navbar is not visible in the mobile view
  const MobileAppBar = (
    <AppBar position="sticky" style={appBarStyle}>
      <Toolbar>
        <img
          src={getLoggedInPartnerLogo('Compliance')}
          alt="Partner Logo"
          style={logoStyleCompressed}
        />
        <img
          src={maxHomeLogo}
          alt="MaxHome Logo"
          style={{
            ...logoStyleCompressed,
            marginLeft: '0.5rem',
            paddingLeft: '0.5rem',
            borderLeft: '1px solid black',
          }}
        />
        <MenuOutlined
          sx={{ marginLeft: 'auto', color: 'black' }}
          onClick={() => setShowMobileNavBar(true)}
        />
      </Toolbar>
    </AppBar>
  );

  // TODO: Fine tune for 1 file vs multiple files. Custom messages.
  return (
    <>
      <Box
        height="100vh"
        width="100%"
        sx={{ backgroundColor: '#F6F6F6' }}
        boxSizing="border-box"
      >
        {documentIDs.length > 0 && (
          <FileExtractionStatusBar
            documentIDs={documentIDs}
            onClick={() => {
              setDocumentIDs([]);
              setDocumentIDsInLocalStorage([]);
            }}
            ref={alertBoxRef}
          />
        )}
        <Box height={mainBoxHeight} display="flex" boxSizing="border-box">
          {MainNavBar}
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
            boxSizing="border-box"
          >
            <ErrorsStacker />
            {/* Display the AppBar if it is in mobile view after selection */}
            <ResponsiveRenderer
              CompressedView={MobileAppBar}
              FullView={<></>}
            />
            <Routes>
              <Route
                path="transactions"
                element={<ComplianceTransactionsPage />}
              />
              <Route
                path="checks"
                element={<ComplianceChecksPage isCompliance={true} />}
              />
              <Route path="work-allocation" element={<WorkAllocation />} />
              <Route
                path="transactions/documents"
                element={<ComplianceTransactionDetailsPage />}
              />
              <Route
                path="transactions/facts"
                element={<TransactionKeyFacts />}
              />
              <Route
                path="transactions/contacts"
                element={<TransactionContactsList />}
              />
              <Route
                path="transactions/verify"
                element={<ComplianceVerifyDocumentPage />}
              />
              <Route path="validation" element={<ValidationView />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ComplianceDashboard;
