import React, { useState } from 'react';

import Button from 'app/components/common/Button/Button';
import { Cell } from 'app/components/common/Table';
import { useFlags } from 'flagsmith/react';
import { useNavigate } from 'react-router-dom';

interface RowProps {
  id?: number;
  key?: string;
  width?: string;
  background?: string;
  borderLeft?: string;
  borderTop?: string;
  borderBottom?: string;
  border?: boolean;
  inactive?: boolean;
  error?: boolean;
  highlighter?: boolean;
  cellWidth?: string;
  children: React.ReactNode;
  isCompliance?: boolean;
  dropdownData?: any[];
}

const Row: React.FC<RowProps> = (props) => {
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const flags = useFlags(['view_validation_navigation']);
  const isViewValidationNavigationEnabled =
    flags?.view_validation_navigation?.enabled;

  const toggleDropdown = (e: React.MouseEvent) => {
    if (!(e.target as HTMLElement).closest('.no-toggle')) {
      setIsDropdownVisible(!isDropdownVisible);
    }
  };

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleStatusClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const renderDropdownCell = () => {
    return props.dropdownData?.map((row, rowIndex) => (
      <div
        key={rowIndex}
        style={{
          display: 'flex',
          padding: '1rem',
          borderBottom: rowIndex === 0 ? '1px solid #dfe3eb' : 'none',
        }}
      >
        {row.map((item: any, index: number) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.25rem',
              width: '33%',
              maxWidth: '33%',
            }}
          >
            {item.isButton ? (
              <Button
                width="max-content"
                label={item.label}
                onClick={() => {
                  isViewValidationNavigationEnabled
                    ? window.open(item.value, '_blank')
                    : navigate(item.value);
                }}
              ></Button>
            ) : item.isModal ? (
              <Button
                width="max-content"
                label={item.label}
                onClick={() => item.value(item.check)}
              ></Button>
            ) : (
              <>
                <span
                  style={{
                    color: '#2B3340',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {item.label}
                </span>
                {item.isLink ? (
                  <div>
                    <a
                      href={item.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.text}
                    </a>
                  </div>
                ) : (
                  <span
                    style={{
                      color: '#57677E',
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    {item.value}
                  </span>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <>
      <tbody
        id={props.id?.toString()}
        key={props.key}
        style={{ width: props.width || 'auto', background: props.background }}
      >
        <tr
          style={{
            borderLeft: props.borderLeft,
            borderTop: props.borderTop,
            borderBottom: props.borderBottom,
            cursor: 'pointer',
            transition: 'background-color 0.3s, box-shadow 0.3s',
            backgroundColor: isHovered ? '#f5f5f5' : 'transparent',
            boxShadow: isHovered ? '0 0 5px rgba(0, 0, 0, 0.3)' : 'none',
          }}
          onClick={toggleDropdown}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {Array.isArray(props.children) ? (
            props.children?.map((child, i) =>
              child ? (
                <Cell
                  maxWidth="200px"
                  key={i}
                  border={props.border}
                  inactive={props.inactive}
                  error={props.error}
                  colSpan={child?.props?.colSpan}
                  background={child?.props?.background}
                  highlighter={props.highlighter}
                  cellWidth={props.cellWidth}
                >
                  {child?.props?.children?.type === 'input' ? (
                    <span className="no-toggle" onClick={handleCheckboxClick}>
                      {child}
                    </span>
                  ) : child?.props?.children?.type === 'span' &&
                    child?.props?.children?.props?.onClick ? (
                    <span className="no-toggle" onClick={handleStatusClick}>
                      {child}
                    </span>
                  ) : (
                    child
                  )}
                </Cell>
              ) : null
            )
          ) : (
            <Cell
              maxWidth="200px"
              border={props.border}
              inactive={props.inactive}
              error={props.error}
              highlighter={props.highlighter}
              cellWidth={props.cellWidth}
            >
              {props.children}
            </Cell>
          )}
        </tr>
        {isDropdownVisible && (
          <tr>
            <td
              colSpan={
                Array.isArray(props.children) ? props.children.length : 1
              }
              style={{ padding: '20px' }}
            >
              <div
                style={{
                  background: '#f9f9f9',
                  paddingLeft: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {renderDropdownCell()}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </>
  );
};

export default Row;
