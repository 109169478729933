export const fetchIdFromUrl = () => {
  const pathSegments = window.location.pathname.split('/');
  const id = pathSegments[pathSegments.length - 1]; // Get the last segment
  return id;
};

export const getCustomIdFromUrl = (query: string): any => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(query);
};
