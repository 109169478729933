import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

export const Auth0WithNavigate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const domain = 'maxhome.us.auth0.com';
  // TODO: Move clientId to .env file
  const clientId = 'MQtrA2Trzfc3Qsn1zObD9JE59v0R1fn2';
  const redirectUri = window.location.origin;

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
