import React, { CSSProperties } from 'react';
import './Button.scss';

interface ButtonProps {
  type?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  testId?: string;
  loading?: boolean;
  onClick?: () => void;
  width?: string | number;
  fontSize?: string | number;
  height?: string | number;
  disable?: boolean;
  fontFamily?: string;
  color?: string;
  background?: string;
  padding?: string;
  margin?: string;
  label: string;
  styles?: CSSProperties;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    type,
    testId,
    loading,
    onClick,
    width,
    fontSize,
    height,
    disable,
    fontFamily,
    color,
    background,
    padding,
    margin,
    label,
    styles,
  } = props;

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  };

  const commonStyle: CSSProperties = {
    width,
    fontSize,
    height,
    padding,
    margin,
    color,
    background,
    ...styles,
  };

  switch (type) {
    case 'primary':
      return (
        <div
          data-testid={testId}
          className={loading ? 'primary-loading' : 'primary'}
          onClick={onClick}
          style={commonStyle}
          tabIndex={0}
          onKeyUp={handleKeyUp}
        >
          {label}
        </div>
      );
    case 'secondary':
      return (
        <div
          data-testid={testId}
          className={loading ? 'secondary-loading' : 'secondary'}
          onClick={!disable && !loading ? onClick : undefined}
          style={{
            ...commonStyle,
            opacity: disable ? 0.5 : 1,
            fontWeight: fontFamily === 'Regular' ? 400 : fontFamily,
            color : color,
            background,
          }}
          tabIndex={0}
          onKeyUp={handleKeyUp}
        >
          {label}
        </div>
      );
    case 'tertiary':
      return (
        <div
          data-testid={testId}
          className={loading ? 'tertiary-loading' : 'tertiary'}
          onClick={onClick}
          style={commonStyle}
          tabIndex={0}
          onKeyUp={handleKeyUp}
        >
          {label}
        </div>
      );
    case 'quaternary':
      return (
        <div
          data-testid={testId}
          className={loading ? 'quaternary-loading' : 'quaternary'}
          onClick={onClick}
          style={commonStyle}
          tabIndex={0}
          onKeyUp={handleKeyUp}
        >
          {label}
        </div>
      );
    default:
      return (
        <div
          data-testid={testId}
          className={loading ? 'button-loading' : 'button'}
          onClick={!disable && !loading ? onClick : undefined}
          style={{
            ...commonStyle,
            color: color,
            opacity: disable ? 0.5 : 1,
          }}
          tabIndex={0}
          onKeyUp={handleKeyUp}
        >
          {label}
        </div>
      );
  }
};

export default Button;
