const complianceTransactionStatusMap: Record<string, Record<string, string>> = {
  PRE_LISTING: {
    name: 'Pre-Listing',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
  },
  PRIVATE_LISTING: {
    name: 'Private Listing',
    mainColor: '#800080', // Purple
    backgroundColor: '#F3E5F5',
    fontColor: '#4B0082',
  },
  ACTIVE_LISTING: {
    name: 'Active Listing',
    mainColor: '#0000FF', // Blue
    backgroundColor: '#E6F4FF',
    fontColor: '#003EB3',
  },
  UNDER_CONTRACT: {
    name: 'Under Contract', // Gold
    mainColor: '#FFD700',
    backgroundColor: '#FFF4B8',
    fontColor: '#AD6800',
  },
  WITHDRAWN: {
    name: 'Withdrawn', // Gray
    mainColor: '#808080',
    backgroundColor: '#F5F5F5',
    fontColor: '#505050',
  },
  SOLD: {
    name: 'Sold', // Green
    mainColor: '#008000',
    backgroundColor: '#CFFAD3',
    fontColor: '#099530',
  },
  TERMINATED: {
    name: 'Terminated', // Red
    mainColor: '#FF0000',
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
  },
};

const transactionProcessingStatusMap: Record<string, Record<string, string>> = {
  NOT_STARTED: {
    name: 'Not submitted for Review',
    mainColor: '#808080', // Gray
    backgroundColor: '#F5F5F5',
    fontColor: '#505050',
  },
  PROCESSING: {
    name: 'Under Review',
    mainColor: '#0000FF', // Blue
    backgroundColor: '#E6F4FF',
    fontColor: '#003EB3',
  },
  MAX_APPROVED: {
    name: 'Max Approved',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
  },
  USER_APPROVED: {
    name: 'Approved',
    mainColor: '#008000', // Green
    backgroundColor: '#CFFAD3',
    fontColor: '#099530',
  },
  SENT_TO_AGENT_FOR_UPDATES: {
    name: 'Returned to Agent',
    mainColor: '#800080', // Purple
    backgroundColor: '#F3E5F5',
    fontColor: '#4B0082',
  },
  DOCS_MISSING: {
    name: 'Documents Missing',
    mainColor: '#FF0000', // Red
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
  },
  VALIDATION_FAILED: {
    name: 'Validation Failed',
    mainColor: '#FF0000', // Red
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
  },
};

export { complianceTransactionStatusMap, transactionProcessingStatusMap };
