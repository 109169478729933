import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  agentID: string;
  userID: string;
  authToken: string;
}

const initialState: AuthState = {
  agentID: '',
  userID: '',
  authToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAgentID: (state, action) => {
      state.agentID = action.payload;
    },
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

export const { setAgentID, setUserID, setAuthToken } = authSlice.actions;

export default authSlice.reducer;
