import { Chip } from '@mui/material';

export const REVIEW_STATUS_OPTIONS = [
  'NOT_STARTED',
  'PROCESSING',
  'VALIDATION_FAILED',
  'MAX_APPROVED',
  'USER_APPROVED',
  'NOT_APPLICABLE',
  'READY_TO_BE_SENT_TO_AGENT',
  'SENT_TO_AGENT_FOR_UPDATES',
];

const allStatusChips: { [key: string]: JSX.Element } = {
  NOT_STARTED: (
    <Chip
      label="Not Started"
      size="small"
      sx={{
        backgroundColor: '#F0F0F0',
        color: '#666666',
        borderRadius: '4px',
      }}
    />
  ),
  PROCESSING: (
    <Chip
      label="Processing"
      size="small"
      sx={{
        backgroundColor: '#E6F7FF',
        color: '#0050B3',
        borderRadius: '4px',
      }}
    />
  ),
  VALIDATION_FAILED: (
    <Chip
      label="Validation Failed"
      size="small"
      sx={{
        backgroundColor: '#FFD8A0',
        color: '#8B4500',
        borderRadius: '4px',
      }}
    />
  ),
  MAX_APPROVED: (
    <Chip
      label="Max Approved"
      size="small"
      sx={{
        backgroundColor: '#CFFAD3',
        color: '#099530',
        borderRadius: '4px',
      }}
    />
  ),
  USER_APPROVED: (
    <Chip
      label="Approved"
      size="small"
      sx={{
        backgroundColor: '#CFFAD3',
        color: '#099530',
        borderRadius: '4px',
      }}
    />
  ),
  READY_TO_BE_SENT_TO_AGENT: (
    <Chip
      label="Ready to Send to Agent"
      size="small"
      sx={{
        backgroundColor: '#FFF4B8',
        color: '#AD6800',
        borderRadius: '4px',
      }}
    />
  ),
  SENT_TO_AGENT_FOR_UPDATES: (
    <Chip
      label="Sent to Agent for Updates"
      size="small"
      sx={{
        backgroundColor: '#FFF4B8',
        color: '#AD6800',
        borderRadius: '4px',
      }}
    />
  ),
  NOT_APPLICABLE: (
    <Chip
      label="Not Applicable"
      size="small"
      sx={{
        backgroundColor: '#F0F0F0',
        color: '#666666',
        borderRadius: '4px',
      }}
    />
  ),
  NULL: (
    <Chip
      label="Null"
      size="small"
      sx={{
        backgroundColor: '#FFF1F0',
        color: '#A8071A',
        borderRadius: '4px',
      }}
    />
  ),
};

export const StatusChip = ({ status }: { status?: string }) => {
  if (!status) {
    return allStatusChips['NULL'];
  }
  return (
    allStatusChips?.[status] || (
      <Chip sx={{ borderRadius: '4px' }} label={status} size="small" />
    )
  );
};
