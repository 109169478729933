import { CheckStatusMap } from 'app/components/common/Checks/types';
import { documentNameMap } from 'app/configs/documentNameMap';
import * as XLSX from 'xlsx';

type Transaction = {
  type: string;
  readyToDeposit: boolean;
  remarks: string;
  processingStatus: {
    status: string;
  };
  prismDataUpdateStatus: { prismUpdated: boolean };
  receivingOffice: {
    name: string;
  };
  transactionOffice: {
    name: string;
  };
  transactionName: string;
  transactionAmount: string;
  agentName: string;
};

export const DownloadCheckReport = (data: Transaction[]) => {
  const excelData = data.map((item) => ({
    'Property Address': item?.transactionName || '-',
    Type: documentNameMap[item?.type] || '-',
    'Receiving Office': item?.receivingOffice?.name || '-',
    'Branch Office': item?.transactionOffice?.name || '-',
    'Agent Name': item?.agentName || '-',
    'Ready to Deposit': item?.readyToDeposit ? 'Yes' : 'No',
    Status:
      CheckStatusMap[item?.processingStatus?.status] ||
      item?.processingStatus?.status ||
      '-',
    Remarks: item?.remarks || '-',
  }));

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(excelData);

  const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');

  // Initialize with header widths
  const headers = Object.keys(excelData[0]);
  const colWidths = headers.map((h) => ({ wch: h.length }));

  // Find maximum width needed for each column
  for (let C = range.s.c; C <= range.e.c; ++C) {
    for (let R = range.s.r; R <= range.e.r; ++R) {
      const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
      if (!cell) continue;

      const cellValue = cell.v?.toString() || '';
      colWidths[C].wch = Math.max(colWidths[C].wch, cellValue.length);
    }
    // Add some padding
    colWidths[C].wch = Math.max(colWidths[C].wch + 4, 10);
  }

  ws['!cols'] = colWidths;

  XLSX.utils.book_append_sheet(wb, ws, 'Check Report');

  // Generate buffer
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Create blob and download
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'Maxhome-report.xlsx');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
