import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import { ArrowRightAlt, MoreVert } from '@mui/icons-material';
import {
  Dropdown,
  Empty,
  message,
  Typography,
  Menu as AntdMenu,
  Select,
} from 'antd';
import { getNumericDateString } from 'app/utils/datetime';
import { WorkAllocationService } from 'app/modules/util/workAllocationService';

const rowHeaders = [
  'Office',
  'Primary',
  'Out Of Office',
  'Replacement',
  'Action',
];

interface WorkAllocationTableProps {
  processingTeamMembers: any[];
  workAllocationData: any[];
  setOooModalVisible: (visible: boolean, office?: any) => void;
  setReassignModalVisible: (visible: boolean, office?: any) => void;
  getWorkAllocations: () => Promise<any>;
}

const WorkAllocationTable: React.FC<WorkAllocationTableProps> = ({
  processingTeamMembers,
  workAllocationData,
  setOooModalVisible,
  setReassignModalVisible,
  getWorkAllocations,
}) => {
  const moreOptionsMenu = (office: any) => (
    <AntdMenu style={{ padding: '0.75rem' }}>
      <AntdMenu.Item key="1" onClick={() => setOooModalVisible(true, office)}>
        Add / Edit Out Of Office
      </AntdMenu.Item>
      <AntdMenu.Item
        key="2"
        onClick={() => setReassignModalVisible(true, office)}
      >
        Reassign Primary Admin
      </AntdMenu.Item>
    </AntdMenu>
  );

  const handleReassignPrimaryAdmin = async (newAdmin: string) => {
    const row = anchorEl?.row;
    const payload: any = {
      office: row?.office?._id,
      assignment: row?.assignment?._id,
      replacementAssignment: newAdmin,
      startDate: new Date(Date.now()).toISOString().split('T')[0],
    };
    try {
      const response: any =
        await WorkAllocationService.reassignPrimaryAdmin(payload);
      if (response.status === 200) {
        getWorkAllocations();
        message.success('Primary Admin Reassigned successfully');
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | { row: any }>(null);

  const handleClick = (row: any) => {
    setAnchorEl({ row });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="work allocation table">
        <TableHead>
          <TableRow>
            {rowHeaders.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {workAllocationData.length ? (
            workAllocationData.map((row) => (
              <TableRow
                key={row?.officeName}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography style={{ fontWeight: 'bold' }}>
                    {row?.office?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Select
                      value={row?.assignment?._id}
                      style={{ width: 200 }}
                      onClick={() => handleClick(row)}
                      onChange={(value) => {
                        handleReassignPrimaryAdmin(value);
                        setAnchorEl(null);
                      }}
                      options={processingTeamMembers.map((member) => ({
                        value: member._id,
                        label: member.name,
                      }))}
                      placeholder="Add Primary Agent"
                    />
                    {row?.replacementAssignment && (
                      <>
                        <ArrowRightAlt
                          style={{ margin: '0 1rem', color: '#888' }}
                        />
                        <Typography style={{ color: '#888' }}>
                          {row?.replacementAssignment?.name}(
                          {getNumericDateString(
                            new Date(
                              new Date(row?.lastAvailableDate).getTime() +
                                86400000
                            ).toISOString()
                          )}
                          )
                        </Typography>
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {row?.leaveData.map((item: any, index: number) => (
                      <div key={index}>
                        {getNumericDateString(item?.from) +
                          ' - ' +
                          getNumericDateString(item?.to)}
                      </div>
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {row?.leaveData.map((item: any, index: number) => (
                      <div key={index}>{item?.substitute?.name}</div>
                    ))}
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <IconButton aria-label="more" size="small">
                    <Dropdown
                      overlay={moreOptionsMenu(row)}
                      trigger={['click']}
                    >
                      <MoreVert style={{ cursor: 'pointer' }} />
                    </Dropdown>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <tr>
              <td
                colSpan={rowHeaders.length}
                style={{ textAlign: 'center', padding: '1rem' }}
              >
                <Empty description="No offices found" />
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WorkAllocationTable;
