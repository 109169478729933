import apiEnvironment from './api';
import featureFlagEnvironment from './feature-flag';
import firebaseEnvironment from './firebase';
import pendoEnvironment from './pendo';

const environment = {
  api: apiEnvironment,
  firebase: firebaseEnvironment,
  pendo: pendoEnvironment,
  featureFlag: featureFlagEnvironment,
};

export default environment;
