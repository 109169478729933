import {
  AddRounded,
  ArrowBackRounded,
  ArrowForwardRounded,
  CheckRounded,
  FlagOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  RemoveRounded,
  ReplyAllRounded,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  Breadcrumb,
  Button,
  Card,
  Input,
  Modal,
  Switch,
  Tooltip,
  message,
} from 'antd';
import {
  Document as DocumentInterface,
  ErrorField,
} from 'app/interfaces/Document';
import { Page, Document as PdfDocument, pdfjs } from 'react-pdf';
import {
  ReactElement,
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import { ErrorActions } from '../utils/ErrorActions';
import { ErrorCardComponent } from '../components/ErrorCard';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { StatusChip } from '../components/ReviewStatusChip';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { capitalizeFirstLetter } from 'app/utils/string';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getCustomIdFromUrl } from 'app/utils/url';
import { sendEmail } from 'app/utils/email';
import useTransactionDocumentErrorIdsAndActionListManager from 'app/atomManagers/transactionErrorDocumentIdsAndActionListManager';
import useTransactionDocumentErrorsManager from 'app/atomManagers/transactionDocumentErrorsManager';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// TODO: Move this to somewhere else later. This file is too cluttered as is.

const getXYWidthHeight = (
  normalizedVertices: number[],
  pageWidth: number,
  pageHeight: number,
  zoom: number,
  errorDetection: boolean = false
) => {
  const scale = 1; // in percentage
  const widthExtra = 0; // In px

  const tl_x = normalizedVertices[0];
  const tl_y = normalizedVertices[1];
  const br_x = normalizedVertices[2];
  const br_y = normalizedVertices[3];

  const xVal = tl_x * pageWidth;
  const yVal = tl_y * pageHeight;

  const widthVal = (br_x - tl_x) * pageWidth + widthExtra; // Increase width by 25%
  const heightVal = (br_y - tl_y) * pageHeight * scale; // Increase height by 25%

  const left = `${xVal - (widthVal - (br_x - tl_x) * pageWidth) / 2}px`; // Adjust left position to maintain center
  const top = `${yVal - (heightVal - (br_y - tl_y) * pageHeight) / 2}px`; // Adjust top position to maintain center
  const width = `${widthVal}px`;
  // const height = errorDetection ? `${Math.max(heightVal * zoom, 40 * zoom)}px` : `${heightVal}px`;
  const height = `${heightVal}px`;

  if (errorDetection) {
    console.log('Error: ', { top, left, width, height });
  }
  return { top, left, width, height };
};

const defaultErrorAction = {
  // action: ErrorActions.NULL,
  // We will set to 'IGNORE' by default so that user does not have to select them one by one
  action: ErrorActions.IGNORE,
  // Keeping this as a Record/Object so that it can be easily extended in the future
};

export const ComplianceVerifyDocumentPage = () => {
  const errorsManager = useTransactionDocumentErrorsManager();
  const errorDocumentIdsAndActionListManager =
    useTransactionDocumentErrorIdsAndActionListManager();

  const navigate = useNavigate();

  const location = useLocation();
  const documentID = getCustomIdFromUrl('documentID') || '';
  // TODO: This depends on the prefix pieces count. Make it independent
  const transactionID = getCustomIdFromUrl('transactionID') || '';
  console.log('Transaction ID: ', transactionID);

  const [complianceTransaction, setComplianceTransaction] =
    useState<ComplianceTransaction>({} as ComplianceTransaction);
  const [documentType, setDocumentType] = useState('' as string);

  const [pdfData, setPdfData] = useState('');
  const [errors, setErrors] = useState<ErrorField[]>([]);
  const [rawExtract, setRawExtract] = useState<
    Record<string, [string, number, [number, number, number, number], number]>
  >({});
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageErrors, setPageErrors] = useState([] as any[]);
  const [selectedError, setSelectedError] = useState({} as any);
  const [showAllDetections, setShowAllDetections] = useState(false);
  const errorAnnotationRefs = useRef([]);

  const parentRef = useRef<HTMLDivElement>(null);
  const [pageWidth, setPageWidth] = useState(700);
  const [pageRatio, setPageRatio] = useState(0);
  const [zoom, setZoom] = useState(1);

  const [messageShown, setMessageShown] = useState(false);

  const [errorsCountByPageIndex, setErrorsCountByPageIndex] = useState<
    number[]
  >([]);

  const [errorActionsList, setErrorActionsList] = useState<
    Record<string, string>[]
  >([]);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const [prevDocument, setPrevDocument] = useState<DocumentInterface>(
    {} as DocumentInterface
  );
  const [nextDocument, setNextDocument] = useState<DocumentInterface>(
    {} as DocumentInterface
  );

  useEffect(() => {
    setPageDimensions();

    ComplianceService.getTransactionDocument(transactionID, documentID).then(
      (response) => {
        console.log('Document:', response.data);
        // setPdfUrl(response.data.signedUrl);
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfURL = URL.createObjectURL(pdfBlob);
        setPdfData(pdfURL);
      },
      (error) => {
        console.error('Error fetching document:', error);
      }
    );

    // // Tried to set up the signed URL for the PDF
    // // But it is not working as expected
    // // Will try this again when I have time
    // ComplianceService.getDocumentv2(documentID).then(async (response) => {
    //   console.log('Document:', response.data);
    //   setRawExtract(response.data.document.rawExtract);
    //   setErrors(response.data.document.errorFields);

    //   // Now use the signed URL to get the pdf file
    //   const signedUrl = response.data.signedUrl;
    //   // const signedUrlResponse = await fetch(signedUrl);
    //   const signedUrlResponse = await fetch(`https://cors-anywhere.herokuapp.com/${signedUrl}`);
    //   // const data = await signedUrlResponse.json();
    //   // console.log("Data: ", data);
    //   const pdfBlob = await signedUrlResponse.blob();
    //   // const pdfBlob = new Blob([response.data.signedUrl], { type: 'application/pdf' });
    //   const pdfURL = URL.createObjectURL(pdfBlob);
    //   setPdfData(pdfURL);
    // });

    // // Get the compliance transaction details as well
    getTransactionDocumentDetails();
    initDocumentNavigation();
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', setPageDimensions);

    return () => {
      window.removeEventListener('resize', setPageDimensions);
    };
  }, [parentRef]);

  const initDocumentNavigation = () => {
    ComplianceService.getTransactionByID(transactionID)
      .then((response) => {
        console.log('Transaction:', response.data);

        // Also handle the document navigation buttons
        // Get the index of the transactionData.documents
        const documentIndex = response.data.documents.findIndex(
          (doc: Record<string, any>) => doc._id === documentID
        );
        setCurrentDocumentIndex(documentIndex);
        // If the documentIndex is not the first document, then set the prevDocumentIndex
        if (documentIndex > 0) {
          // Check the document's reviewStatus and set prev document to the first document when traversing backwards that
          // is not in 'PROCESSING' reviewStatus
          let prevDocumentIndex = documentIndex - 1;
          while (
            prevDocumentIndex >= 0 &&
            response.data.documents[prevDocumentIndex].displayStatus ===
              'PROCESSING'
          ) {
            prevDocumentIndex--;
          }
          if (prevDocumentIndex >= 0) {
            setPrevDocument(response.data.documents[prevDocumentIndex]);
          } else {
            setPrevDocument({} as DocumentInterface);
          }
        }
        // If the documentIndex is not the last document, then set the nextDocumentIndex
        if (documentIndex < response.data.documents.length - 1) {
          // Check the document's reviewStatus and set next document to the first document when traversing forwards that
          // is not in 'PROCESSING' reviewStatus
          let nextDocumentIndex = documentIndex + 1;
          while (
            nextDocumentIndex < response.data.documents.length &&
            ['PROCESSING', 'NOT_STARTED'].includes(
              response.data.documents[nextDocumentIndex].displayStatus
            )
          ) {
            nextDocumentIndex++;
          }
          if (nextDocumentIndex < response.data.documents.length) {
            setNextDocument(response.data.documents[nextDocumentIndex]);
          } else {
            setNextDocument({} as DocumentInterface);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching transaction:', error);
      });
  };

  const getTransactionDocumentDetails = () => {
    ComplianceService.getTransactionWithDocumentDetails(
      transactionID,
      documentID
    ).then(
      (response) => {
        console.log('Transaction with Document Details:', response.data);
        setComplianceTransaction(response.data);
        setDocumentType(response.data.document.type);
        let errorsFound = false;
        let errorFields = response.data.document.errorFields;
        console.log('Error fields: ', errorFields);
        setErrors(errorFields);
        setRawExtract(response.data.document.rawExtract);
        if (response.data.document.errorFields.length > 0 && !messageShown) {
          // message.error(`${response.data.document.errorFields.length} issues found!`);
          errorsFound = true;
          setMessageShown(true);
        }

        // Also create the error annotation refs
        errorAnnotationRefs.current = errorFields.map(
          (_: any, i: number) => errorAnnotationRefs.current[i] ?? createRef()
        );

        if (!errorsFound && !messageShown) {
          // message.success('No issues found!');
          setMessageShown(true);
        }

        // Check if the complianceTransaction already has an errorActionsList, if so, set it
        if (
          response.data.document.errorActions &&
          response.data.document.errorActions.length > 0
        ) {
          setErrorActionsList(response.data.document.errorActions);
        } else {
          // Set the error actions list to null depending on the count of errors and their severity
          // let newErrorActions = [];
          // for (let errorField of errorFields) {
          //   if (errorField?.severity === 'optional') {
          //     newErrorActions.push({ action: ErrorActions.IGNORE });
          //   } else {
          //     newErrorActions.push({ action: ErrorActions.FIX });
          //   }
          // }
          // console.log("New Error Actions: ", newErrorActions);
          let errorActions = Array(errorFields.length).fill(defaultErrorAction);
          console.log('Error Actions gonna set to: ', errorActions);
          setErrorActionsList(errorActions);
        }
      },
      (error) => {
        console.error('Error fetching transaction details:', error);
      }
    );
  };

  const setPageDimensions = () => {
    if (parentRef.current) {
      if (errors.length === 0) {
        setPageWidth(parentRef.current.offsetWidth * 0.9);
      } else {
        setPageWidth(parentRef.current.offsetWidth * 0.7);
      }
      console.log('Parent height: ', parentRef.current.clientHeight);
    }
  };

  useEffect(() => {
    // When page changes, render the error bboxes pagewise
    console.log('Errors in the use effect: ', errors);
    const pageErrors = errors.filter((error) => error['pages'].includes(page));
    console.log('Page Errors:', pageErrors);
    setPageErrors(pageErrors);
  }, [page, errors]);

  useEffect(() => {
    if (numPages > 0) {
      let errorsPerPage = Array(numPages).fill(0);
      console.log('Errors per page before: ', errorsPerPage);
      errors.forEach((error: any) => {
        console.log('Error: ', error);

        error.pages.forEach((page: number) => {
          if (typeof page !== 'number' || page < 1 || page > numPages) {
            console.error('Invalid page value:', page, 'in error:', error);
          } else {
            errorsPerPage[page - 1]++;
          }
        });

        // NOTE: This was when we have only one page per error
        // if (
        //   typeof error.page !== 'number' ||
        //   error.page < 1 ||
        //   error.page > numPages
        // ) {
        //   console.error(
        //     'Invalid page value:',
        //     error.page,
        //     'in error:',
        //     error
        //   );
        // } else {
        //   errorsPerPage[error.page - 1]++;
        // }
      });

      setErrorsCountByPageIndex(errorsPerPage);
      console.log('Setting errors count by page index: ', errorsPerPage);
    }
  }, [numPages, errors]);

  // useEffect(() => {
  //   const fetchPDF = async () => {
  //     try {
  //       // const response = await axios.get(pdfUrl, {
  //       //   responseType: 'arraybuffer',
  //       // });
  //       const response = await axios.get(`http://localhost:5174/proxy/${pdfUrl}`, {
  //         responseType: 'arraybuffer',
  //       });
  //       const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  //       const pdfURL = URL.createObjectURL(pdfBlob);
  //       setPdfData(pdfURL);
  //     } catch (error) {
  //       console.error('Error fetching the PDF:', error);
  //     }
  //   };

  //   fetchPDF();
  // }, [pdfUrl]);

  const handleZoomChange = (event: any) => {
    const newZoom = event.target.value as number;
    setZoom(newZoom);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onRenderSuccess = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => {
    // Set the Page Ratio so that we can calculate the page height
    setPageRatio(height / width);
  };

  const goToTransactionsPage = () => {
    navigate(AppURLs.complianceDashboardTransactions());
  };

  const goToDocumentsList = () => {
    // Go back to the previous page
    navigate(AppURLs.complianceDashboardTransaction(transactionID));
  };

  const getErrorAnnotationRef = (error: any): RefObject<HTMLDivElement> => {
    console.log('Error: ', error);
    console.log('Errors: ', errors);
    console.log('Index: ', errors.indexOf(error));
    console.log('Refs: ', errorAnnotationRefs.current);
    return errorAnnotationRefs.current[errors.indexOf(error)];
  };

  const scrollToErrorRef = (error: any) => {
    const errorRef = getErrorAnnotationRef(error);
    console.log('Error ref from the function is: ', errorRef);
    if (errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      let tries = 0;
      const interval = setInterval(() => {
        tries++;
        if (errorRef.current) {
          console.log('Scrolling to error: ', errorRef.current);
          errorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          clearInterval(interval);
        } else if (tries >= 100) {
          clearInterval(interval);
          console.log('Failed to get the ref after 20 tries');
        }
      }, 100);
    }
  };

  const allErrorActionsSet = () => {
    return (
      errorActionsList.length > 0 &&
      errorActionsList.every(
        (errorAction) => errorAction.action !== ErrorActions.NULL
      )
    );
  };

  const approvalDisabled = () => {
    // If the document approval status is COMPLETED, then the approval button should be disabled
    if (
      complianceTransaction?.document?.reviewStatus === 'COMPLETED' ||
      complianceTransaction?.document?.reviewStatus === 'USER_APPROVED'
    ) {
      return true;
    }

    return false;

    // // If there are no errorActions, the button should not be disabled
    // if (errorActionsList.length === 0) {
    //   return false;
    // }
    // // Approval button must be disabled if even of error requires FIX
    // return (
    //   !allErrorActionsSet() ||
    //   errorActionsList.some(
    //     (errorAction) => errorAction.action === ErrorActions.FIX
    //   ) ||
    //   errorActionsList.every(
    //     (errorAction, index) =>
    //       errorAction.action !==
    //       (complianceTransaction?.document?.errorActions?.[index]?.action ??
    //         ErrorActions.IGNORE)
    //   )
    // );
  };

  const returnToAgentDisabled = () => {
    // If not even on error fields requires a fix, then no point sending back to agent. So disable the button.
    return (
      !allErrorActionsSet() ||
      errorActionsList.every(
        (errorAction) => errorAction.action === ErrorActions.IGNORE
      ) ||
      errorActionsList.every(
        (errorAction, index) =>
          errorAction.action ===
          complianceTransaction?.document?.errorActions?.[index]?.action
      )
    );
  };

  const PDFPagesCarousel = (
    <Box
      width="300px"
      overflow="auto"
      borderRadius="8px"
      sx={{
        backgroundColor: 'white',
        boxSizing: 'border-box',
        '::-webkit-scrollbar': {
          width: '0px',
        },
        '::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <PdfDocument file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Box
            key={index}
            textAlign="center"
            padding="1rem"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setPage(index + 1);
              setSelectedError({});
            }}
          >
            <Box
              border={
                page === index + 1 ? '2px solid #1677FF' : '1px solid #D9D9D9'
              }
              display="inline-block"
              sx={{
                userSelect: 'none', // Prevents text selection
                cursor: 'default', // Keeps the cursor as default instead of text selector
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)', // Slightly enlarges the box on hover
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Optional: Adds a shadow for depth
                },
              }}
            >
              <Page
                key={`page_${index + 1} `}
                pageNumber={index + 1}
                width={120}
              />
              {errorsCountByPageIndex[index] > 0 && (
                <Box
                  position="relative"
                  borderRadius="100%"
                  sx={{
                    bottom: '-10px', // Adjust as needed for exact positioning
                    right: '-110px', // Adjust as needed for exact positioning
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    color: 'white',
                    backgroundColor: '#FF4D4F',
                    padding: '0.25rem',
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {errorsCountByPageIndex[index]}
                </Box>
              )}
            </Box>
            <Box color={page === index + 1 ? '#2F78EB' : 'black'}>
              {index + 1}
            </Box>
          </Box>
        ))}
      </PdfDocument>
    </Box>
  );

  const pdfViewAndAnnotationsComponent = (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      padding="0 1rem 0 1rem"
      boxSizing="border-box"
    >
      {/* <Box display="flex" borderRadius="8px 8px 0 0 " alignItems="center" justifyContent="center" width="100%" padding="0.5rem" boxSizing="border-box" sx={{ backgroundColor: 'white' }}>
        <PictureAsPdfRounded color="primary" sx={{ width: '2rem', height: '2rem' }} />
        <Pagination
          style={{ margin: '0 auto 0 auto' }}
          current={page}
          onChange={(page) => { setPage(page); setSelectedError({}); }}
          total={numPages} // Total number of items (this should be set dynamically based on your data)
          // pageSize={2} // Number of items per page
          pageSize={1}
        />
        <Box >
          <IconButton disabled={zoom >= 2} onClick={() => { setZoom(zoom + 0.25) }}>
            <ZoomIn />
          </IconButton>
          <IconButton disabled={zoom <= 0.5} onClick={() => setZoom(zoom - 0.25)}>
            <ZoomOut />
          </IconButton>
        </Box>
      </Box> */}
      <Box
        display="flex"
        borderRadius="8px 8px 0 0 "
        alignItems="center"
        justifyContent="space-around"
        width="100%"
        padding="0.5rem"
        boxSizing="border-box"
        sx={{ backgroundColor: '#EAEAEA' }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            disabled={page <= 1}
            onClick={() => {
              setPage(page - 1);
              setSelectedError({});
            }}
          >
            <KeyboardArrowUpRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box height="2rem" border="1px solid #D3D3D3" />
          <IconButton
            disabled={page >= numPages}
            onClick={() => {
              setPage(page + 1);
              setSelectedError({});
            }}
          >
            <KeyboardArrowDownRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box
            marginLeft="2rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Input
              value={page}
              type="number"
              className="hideNumberInputArrows"
              style={{
                width: '2.5rem',
                height: '2.5rem',
                marginRight: '0.25rem',
                fontSize: '1rem',
              }}
              onChange={(e) => {
                // Convert the input value to a number
                const newPage = Number(e.target.value);
                // Check if the newPage is within the range [1, numPages]
                if (newPage >= 1 && newPage <= numPages) {
                  setPage(newPage);
                } else if (newPage < 1) {
                  setPage(1);
                } else if (newPage > numPages) {
                  setPage(numPages);
                }
              }}
            />
            <Typography fontSize="1rem">of {numPages}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            disabled={zoom >= 1.5}
            onClick={() => {
              setZoom(zoom + 0.25);
            }}
          >
            <AddRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box height="2rem" border="1px solid #D3D3D3" />
          <IconButton
            disabled={zoom <= 0.5}
            onClick={() => {
              setZoom(zoom - 0.25);
            }}
          >
            <RemoveRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box
            marginLeft="2rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Select
              value={zoom}
              onChange={handleZoomChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{
                marginRight: '0.25rem',
                backgroundColor: 'white',
                borderRadius: '8px',
                height: '2.5rem', // Reduced height
                padding: '0 14px', // Adjust padding as needed
                lineHeight: '1.5', // Adjust line height for text alignment
              }}
            >
              <MenuItem value={0.5}>50%</MenuItem>
              <MenuItem value={0.75}>75%</MenuItem>
              <MenuItem value={1}>100%</MenuItem>
              <MenuItem value={1.25}>125%</MenuItem>
              <MenuItem value={1.5}>150%</MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>

      <Box
        width="100%"
        flexWrap="wrap"
        overflow="auto"
        boxSizing="border-box"
        sx={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        <Box
          display="flex"
          margin="0 auto 0 auto"
          width="fit-content"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: '#F6F6F6' }}
        >
          <Box position="relative" width={pageWidth * zoom}>
            <PdfDocument file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
              {/* {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${ index + 1 } `} pageNumber={index + 1} />
            ))} 
            */}
              <Page
                width={pageWidth * zoom}
                key={`page_${page} `}
                pageNumber={page}
                onRenderSuccess={onRenderSuccess}
              />
            </PdfDocument>
            {Object.keys(selectedError).length === 0 &&
              pageErrors.map((error) =>
                error.fields.map((field: any, index: number) => (
                  <div
                    key={index}
                    ref={getErrorAnnotationRef(error)}
                    style={{
                      position: 'absolute',
                      backgroundColor: 'red',
                      opacity: '0.3',
                      ...getXYWidthHeight(
                        field.vertices,
                        pageWidth * zoom,
                        pageWidth * zoom * pageRatio,
                        zoom,
                        true
                      ),
                    }}
                  />
                ))
              )}
            {Object.keys(selectedError).length > 0 &&
              selectedError?.fields?.map((field: any, index: number) => (
                <div
                  key={index}
                  ref={getErrorAnnotationRef(selectedError)}
                  style={{
                    position: 'absolute',
                    backgroundColor: 'red',
                    opacity: '0.3',
                    ...getXYWidthHeight(
                      field.vertices,
                      pageWidth * zoom,
                      pageWidth * zoom * pageRatio,
                      zoom,
                      true
                    ),
                  }}
                />
              ))}
            {/* THE TOOLTIP IS NOT VISIBLE */}
            {showAllDetections &&
              Object.keys(rawExtract).map((key: any, index: number) => (
                <Box key={index}>
                  {rawExtract[key][3] === page && (
                    <div
                      style={{
                        position: 'absolute',
                        backgroundColor: 'green',
                        opacity: '0.3',
                        ...getXYWidthHeight(
                          rawExtract[key][2],
                          pageWidth * zoom,
                          pageWidth * zoom * pageRatio,
                          zoom
                        ),
                      }}
                    >
                      <Tooltip
                        title={rawExtract[key][0]}
                        placement="top"
                        key={index}
                        // sx={{ position: 'absolute' }}
                      >
                        <div
                          style={{
                            ...getXYWidthHeight(
                              rawExtract[key][2],
                              pageWidth * zoom,
                              pageWidth * zoom * pageRatio,
                              zoom
                            ),
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const pdfViewAndAnnotationsComponentWithLoader = (
    <WaitAndLoadingWithSpinner
      loading={!pdfData}
      component={pdfViewAndAnnotationsComponent}
    />
  );

  // TODO: Make this proper!
  const openSendEmail = () => {
    const recipient = complianceTransaction?.agents[0]?.details?.email;
    const recepientName = complianceTransaction?.agents[0]?.details?.name;
    // TODO: The client/customer must be extracted from source of truth for this module - if required
    const subject = 'Reminder: Action Needed - Review your document';
    let body = `Hello ${recepientName},\n\nThis is a reminder for you to review and rectify the ${documentNameMap[documentType]} document of ${complianceTransaction.name}.
    \nThe following needs to be reviewed:\n`;
    for (let i = 0; i < errorActionsList.length; i++) {
      if (errorActionsList[i].action === ErrorActions.FIX) {
        body += `- Page ${errors[i].pages}: ${errors[i].name} - ${errors[i].errorTitle}.\n`;
      }
    }
    body += `\nPlease review and rectify the document before the deadline. \n\nRegards,\nCompliance Team`;

    // Now open the mailto hook
    sendEmail(recipient, subject, body);
  };

  const approveDocument = () => {
    // This request is just to pass on to the backend, what needs to be fixed and what can be ignored.
    // Backend will decide what to do

    const approvalConfirmation = Modal.confirm({
      title: 'Are you sure you want to approve this document?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          This will set all the issues found in the document to <b>IGNORE</b>{' '}
          and mark it as approved.
        </>
      ),
      okText: 'Approve',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk() {
        // Set all the errorActions to IGNORE
        const newErrorActions = errorActionsList.map((errorAction) => {
          return { action: ErrorActions.IGNORE };
        });
        console.log('New Error Actions: ', newErrorActions);

        // Send the approval request
        ComplianceService.approveDocument(
          transactionID,
          documentID,
          newErrorActions
        ).then(
          (response) => {
            console.log('Response from verifyTransactionDocument: ', response);
            message.success('Document verified successfully!');
            // Refresh the page
            getTransactionDocumentDetails();
          },
          (error) => {
            console.error('Error verifying document:', error);
            message.error('Error verifying document');
          }
        );
      },
      onCancel() {
        console.log('Cancel');
        approvalConfirmation.destroy();
      },
    });
  };

  const goToNextDocument = () => {
    console.log('Current index: ', currentDocumentIndex);
    if (nextDocument && nextDocument._id) {
      console.log('Next Document: ', nextDocument);
      const documentId = nextDocument._id;
      setPrevDocument(
        complianceTransaction?.documents
          ? complianceTransaction.documents[currentDocumentIndex]
          : ({} as DocumentInterface)
      );
      setNextDocument(
        complianceTransaction?.documents &&
          currentDocumentIndex + 2 < complianceTransaction.documents.length
          ? complianceTransaction.documents[currentDocumentIndex + 2]
          : ({} as DocumentInterface)
      );
      setCurrentDocumentIndex(currentDocumentIndex + 1);

      // Also set up the nextDocument and prevDocument after navigating
      navigate(
        AppURLs.complianceDashboardTransactionVerifyDocument(
          transactionID,
          documentId
        )
      );
    }
  };

  const goToPrevDocument = () => {
    console.log('Current index: ', currentDocumentIndex);
    if (prevDocument && prevDocument._id) {
      console.log('Prev Document: ', prevDocument);
      const documentId = prevDocument._id;
      setNextDocument(
        complianceTransaction?.documents
          ? complianceTransaction.documents[currentDocumentIndex]
          : ({} as DocumentInterface)
      );
      setPrevDocument(
        complianceTransaction?.documents && currentDocumentIndex - 2 >= 0
          ? complianceTransaction.documents[currentDocumentIndex - 2]
          : ({} as DocumentInterface)
      );
      setCurrentDocumentIndex(currentDocumentIndex - 1);

      // Also set up the nextDocument and prevDocument after navigating
      navigate(
        AppURLs.complianceDashboardTransactionVerifyDocument(
          transactionID,
          documentId
        )
      );
    }
  };

  const component = (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      padding="1rem"
      boxSizing="border-box"
    >
      <Box display="flex" alignItems="center">
        {/* <IconButton color="primary" onClick={goToDocumentsList}>
          <ChevronLeftRounded />
        </IconButton> */}
        {/* <Typography color="primary">Go Back</Typography> */}

        <Breadcrumb style={{ margin: '0.5rem 0' }}>
          <Breadcrumb.Item onClick={goToTransactionsPage}>
            <Typography style={{ cursor: 'pointer' }}>Transactions</Typography>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={goToDocumentsList}>
            <Typography style={{ cursor: 'pointer' }}>
              {complianceTransaction.name}
            </Typography>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography style={{ cursor: 'pointer' }}>
              {documentNameMap[documentType]}
            </Typography>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Box
          display="flex"
          alignItems="center"
          marginLeft="auto"
          marginRight="1rem"
        >
          <Switch
            checked={showAllDetections}
            onChange={(checked) => setShowAllDetections(checked)}
            style={{ marginRight: '0.25rem' }}
          />
          <Typography fontSize="0.8rem" fontWeight={500} marginRight="1rem">
            All Detections
          </Typography>

          <Box display="flex" marginLeft="auto">
            <Box display="flex" alignItems="center">
              <IconButton
                title={
                  prevDocument && prevDocument._id
                    ? `Go to ${documentNameMap[prevDocument.type]}`
                    : ''
                }
                style={{
                  color:
                    prevDocument && prevDocument._id ? '#2F78EB' : '#9e9e9e',
                  backgroundColor: 'white',
                  marginRight: '0.25rem',
                  transition: 'transform 0.2s ease-in-out',
                }}
                sx={{
                  '&:hover': {
                    transform:
                      prevDocument && prevDocument._id ? 'scale(1.1)' : 'none',
                  },
                }}
                onClick={() => goToPrevDocument()}
                disabled={!prevDocument || !prevDocument._id}
              >
                <ArrowBackRounded />
              </IconButton>
              <IconButton
                title={
                  nextDocument && nextDocument._id
                    ? `Go to ${documentNameMap[nextDocument.type]}`
                    : ''
                }
                style={{
                  color:
                    nextDocument && nextDocument._id ? '#2F78EB' : '#9e9e9e',
                  backgroundColor: 'white',
                  transition: 'transform 0.2s ease-in-out',
                }}
                sx={{
                  '&:hover': {
                    transform:
                      nextDocument && nextDocument._id ? 'scale(1.1)' : 'none',
                  },
                }}
                onClick={() => goToNextDocument()}
                disabled={!nextDocument || !nextDocument._id}
              >
                <ArrowForwardRounded />
              </IconButton>
            </Box>
          </Box>
        </Box>

        {/* <Box display="flex" marginLeft="auto" alignItems="center" justifyContent="center" sx={{ backgroundColor: '#EF3C33', color: 'white' }}>
          <WarningAmberRounded /> 10 Issues found!
        </Box> */}
        {/* <Box color="white" sx={{ backgroundColor: '#8BF8A7' }}>
          <CheckCircleFilled /> No Issues Found!
        </Box> */}
      </Box>

      <Box
        display="flex"
        width="100%"
        padding="1rem"
        borderRadius="8px"
        sx={{ backgroundColor: 'white' }}
        margin="0.5rem 0"
        boxSizing="border-box"
      >
        <Box>
          <Typography fontSize="1.1rem" fontWeight={350} color="#7E7E7E">
            {complianceTransaction?.name}
          </Typography>
          <Typography fontSize="1.5rem" fontWeight={600}>
            {documentNameMap[documentType]}
          </Typography>
          <Typography
            fontSize="0.9rem"
            fontWeight={400}
            color="#7E7E7E"
            marginBottom="0.5rem"
          >
            {complianceTransaction?.document?.title}
          </Typography>
          <StatusChip
            status={complianceTransaction?.document?.reviewStatus || ''}
          />
        </Box>
        <Box
          display="flex"
          marginLeft="auto"
          alignItems="center"
          justifyContent="center"
        >
          {!approvalDisabled() && (
            <Button
              shape="round"
              type="primary"
              style={{
                padding: '0 1.5rem',
                marginRight: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => approveDocument()}
            >
              <CheckRounded sx={{ marginRight: '0.25rem' }} /> Approve
            </Button>
          )}

          {/* <Button
            shape="round"
            type="primary"
            style={{
              padding: '0 1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disabled={returnToAgentDisabled()}
            onClick={() => complianceVerified('RETURN_TO_AGENT')}
          >
            <ReplyAllRounded sx={{ marginRight: '0.5rem' }} /> Return to Agent
          </Button> */}

          {!returnToAgentDisabled() && (
            <Button
              danger
              shape="round"
              type="primary"
              style={{
                padding: '0 1.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                errorsManager.clearErrorsForDocument(
                  transactionID,
                  documentType
                );
                let errorsToBePushed = errors.filter(
                  (error) =>
                    errorActionsList[errors.indexOf(error)].action ===
                    ErrorActions.FIX
                );
                errorsManager.addErrors(
                  transactionID,
                  documentType,
                  errorsToBePushed
                );
                console.log('Errors to be pushed: ', errorsToBePushed);
                if (errorsToBePushed.length === 0) {
                  errorDocumentIdsAndActionListManager.removeData(documentID);
                } else {
                  errorDocumentIdsAndActionListManager.addData(
                    documentID,
                    errorActionsList
                  );
                }
              }}
            >
              <ReplyAllRounded sx={{ marginRight: '0.5rem' }} /> Add Errors to
              Stack
            </Button>
          )}
        </Box>
      </Box>

      {/* <Box display="flex" width="100%" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography fontSize="2rem" fontWeight={500} marginLeft="1rem"> Review Documents </Typography>
          {
            errors.length > 0 &&
            <Tooltip
              title={`${ errors.length } Issues Found!`}
            >
              <ErrorRounded style={{ marginLeft: '0.5rem', color: '#FF4D4F', fontSize: '2rem', height: '2.5rem', width: '2.5rem' }} />
            </Tooltip>
          }
          {errors.length === 0 &&
            <Tooltip
              title="No Issues!"
            >
              <CheckCircleFilled style={{ marginLeft: '0.5rem', color: '#2F78EB', fontSize: '2rem', height: '2.5rem', width: '2.5rem' }} />
            </Tooltip>
          }
        </Box>


        <Box display="flex" alignItems="center">
          <Switch checked={showAllDetections} onChange={(checked) => setShowAllDetections(checked)} style={{ marginRight: '0.25rem' }} />
          <Typography fontSize="0.8rem" fontWeight={500}>All Detections</Typography>
        </Box>

      </Box> */}

      {/* <Typography fontSize="1.25rem" fontWeight={450} marginLeft="1rem"></Typography> */}

      <Box
        ref={parentRef}
        display="flex"
        flexGrow={1}
        overflow="auto"
        width="100%"
        marginTop="1rem"
        boxSizing="border-box"
      >
        {PDFPagesCarousel}
        <Box
          display="flex"
          height="100%"
          width="100%"
          overflow="auto"
          boxSizing="border-box"
        >
          {pdfViewAndAnnotationsComponentWithLoader}
        </Box>
        {errors.length > 0 && (
          <Box
            minWidth="15%"
            maxWidth="20rem"
            overflow="auto"
            sx={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {/* <Typography fontSize="1.5rem" marginBottom="1rem"><b>{errors.length}</b> Issues</Typography> */}
            {errors.map((error: ErrorField, index: number) => {
              // Check if errors are properly sorted (all required first, then optional)
              const isProperlyOrdered = errors.every((err, i) => {
                if (i === errors.length - 1) return true;
                if (
                  err.severity === 'required' &&
                  errors[i + 1].severity !== 'required'
                )
                  return true;
                if (
                  err.severity !== 'required' &&
                  errors[i + 1].severity === 'required'
                )
                  return false;
                return true;
              });

              return (
                <>
                  {isProperlyOrdered &&
                    index > 0 &&
                    errors[index - 1].severity === 'required' &&
                    error.severity !== 'required' && (
                      <Box margin="2rem 0" borderBottom="1px solid #D9D9D9" />
                    )}
                  <ErrorCardComponent
                    key={index}
                    index={index}
                    error={error}
                    onClick={() => {
                      if (error) {
                        console.log(
                          'Setting page to first of: ',
                          error['pages']
                        );
                        setSelectedError(error);
                        // If page is not a positive integer, then do nothing
                        if (
                          typeof error['pages'][0] !== 'number' ||
                          error['pages'][0] < 1
                        ) {
                          return;
                        }
                        setPage(error['pages'][0]);
                        scrollToErrorRef(error);
                      }
                    }}
                    selectedError={selectedError}
                    errorActionsList={errorActionsList}
                    setErrorActionsList={setErrorActionsList}
                  />
                </>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );

  return <WaitAndLoadingWithSpinner loading={!pdfData} component={component} />;
};
