import './ValidationView.scss';

import { Button, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getDateAndTimeString, getShortDateString } from 'app/utils/datetime';

import { ComplianceService } from 'app/services/compliance';
import IssueListModal from './IssueListModal';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import alert from './assets/AlertTriangle.svg';
import contract from './assets/image58.png';
import correct from './assets/Check.svg';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getCustomIdFromUrl } from 'app/utils/url';
import home from './assets/home.svg';
import { sendEmail } from 'app/utils/email';

const checklistItems = (check: any) => [
  {
    label: `Check submitted by ${check?.agentName}`,
    date: getDateAndTimeString(check?.checkList?.uploadedToMaxhomeAt)?.date,
    time: getDateAndTimeString(check?.checkList?.uploadedToMaxhomeAt)?.time,
    completed: true,
  },
  {
    label: 'Check added to the loop by Max',
    date: getDateAndTimeString(check?.checkList?.uploadedToDotloopAt)?.date,
    time: getDateAndTimeString(check?.checkList?.uploadedToDotloopAt)?.time,
    completed: check?.checkList?.uploadedToDotloopAt,
  },
  {
    label: 'Validation completed by Max',
    date: getDateAndTimeString(check?.checkList?.validationCompletedAt)?.date,
    time: getDateAndTimeString(check?.checkList?.validationCompletedAt)?.time,
    completed: check?.checkList?.validationCompletedAt,
  },
  {
    label: 'Prism updated by Max',
    date: getDateAndTimeString(check?.checkList?.prismUpdatedAt)?.date,
    time: getDateAndTimeString(check?.checkList?.prismUpdatedAt)?.time,
    completed: check?.checkList?.prismUpdatedAt,
  },
];

const headerTextOptions = {
  PROCESSING: 'Under Review',
  NOT_STARTED: 'Under Review',
  LOOP_NOT_FOUND: 'Loop Not Found',
  DOCS_MISSING: 'Documents Missing',
  VALIDATION_FAILED: 'Validation Failed',
  PRISM_NOT_FOUND: 'Prism Not Found',
  COMPLETED: 'Max Approved',
};

const CheckValidationView: React.FC = () => {
  const [checkDetails, setCheckDetails] = useState<any>(null);
  const [notes, setNotes] = useState('');
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [modalPdfUrl, setModalPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const isAdmin = localStorage.getItem('Admin');

  const headerStatus = checkDetails?.processingStatus
    ?.status as keyof typeof headerTextOptions;

  const prismStatus = checkDetails?.prismDataUpdateStatus;

  const dropDownData = [
    [
      {
        label: 'Branch Office',
        value: checkDetails?.transactionOffice?.name,
      },
      { label: 'Receiving Office', value: checkDetails?.receivingOffice?.name },
    ],
    [
      { label: 'Agent Name', value: checkDetails?.agentName },
      // {
      //   label: 'Maxhome Link',
      //   text: checkDetails?.transactionId ? 'https://www.maxhome.ai' : 'Generating Maxhome Link...',
      //   value: checkDetails?.transactionId
      //     ? `/compliance/dashboard/transactions/${checkDetails?.transactionId}`
      //     : 'N/A',
      //   isLink: true,
      // },
      {
        label: 'Dotloop Link',
        text: checkDetails?.externalSourceID
          ? 'https://www.dotloop.com'
          : 'N/A',
        value: checkDetails?.externalSourceID
          ? `https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : 'N/A',
        isLink: !!checkDetails?.externalSourceID,
      },
    ],
  ];

  useEffect(() => {
    const checkId = getCustomIdFromUrl('checkID');
    if (checkId) {
      ComplianceService.getCheckByID(checkId).then((response) => {
        const data = response?.data;
        setCheckDetails(data);
        setNotes(data?.remarks || '');
        setLoading(false);

        // TODO: Check if it is transactionId or transactionID. Ideally `ID` should be in caps
        if (data?.document?._id) {
          fetchTransactionDocument(data.document._id, data.transactionId).then(
            setPdfUrl
          );
        }
      });
    }
  }, []);

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return 'No Document found';
    }
  };

  const handleViewImage = async (docId: string) => {
    const doc = await fetchTransactionDocument(
      docId,
      checkDetails.transactionId
    );
    setModalPdfUrl(doc);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    if (modalPdfUrl) URL.revokeObjectURL(modalPdfUrl);
  };

  const dropDownCell = () =>
    dropDownData.map((row, rowIndex) => (
      <div
        key={rowIndex}
        className={`dropdown-row-check ${rowIndex === 0 ? 'first-row' : ''}`}
      >
        {row.map((item, index) => (
          <div key={index} className="dropdown-item">
            <span className="item-label">{item.label}</span>
            {item.isLink ? (
              <a href={item.value} target="_blank" rel="noopener noreferrer">
                {item.text}
              </a>
            ) : (
              <span className="item-value">{item.value}</span>
            )}
          </div>
        ))}
      </div>
    ));

  const handleReadyToSubmitChange = async (readyToDeposit: boolean) => {
    try {
      setCheckDetails((prevState: any) => ({
        ...prevState,
        readyToDeposit,
      }));
      await ComplianceService.patchReadyToDeposit(
        checkDetails?._id,
        readyToDeposit
      );
      message.success('Ready to submit status updated successfully!');
    } catch (error) {
      message.error('Error updating ready to submit status');
    }
  };

  const handleSaveNote = async () => {
    try {
      setLoadingButton(true);
      await ComplianceService.patchCheck(
        checkDetails?._id,
        checkDetails?.readyToDeposit,
        notes,
        checkDetails?.processingStatus?.status
      );
      message.success('Notes saved successfully!');
    } catch (error) {
      message.error('Error saving notes');
    } finally {
      setLoadingButton(false);
    }
  };

  const openSendEmail = () => {
    const missingDocs = checkDetails?.processingStatus?.missingDocs;

    const formsMissingText = missingDocs
      ?.map((doc: any) => documentNameMap[doc])
      .join(', ');
    const statusTextMap = {
      LOOP_NOT_FOUND: 'Loop missing',
      DOCS_MISSING: `${formsMissingText} missing`,
      VALIDATION_FAILED: 'Validation issues',
      PRISM_NOT_FOUND: 'Prism entry missing',
      COMPLETED: 'received and uploaded to Dotloop',
    };
    const status = checkDetails?.processingStatus
      ?.status as keyof typeof statusTextMap;
    const headerStatus = statusTextMap[status];
    const recipient = window.location.href.includes('test.maxhome.ai')
      ? 'support@maxhome.ai'
      : checkDetails?.agentEmail || '';
    const recepientName = checkDetails?.agentName.split(' ')[0] || '';
    const address = checkDetails?.address;
    const modifiedCheckAddress =
      [address?.unitNumber, address?.streetAddress]
        .filter(Boolean)
        .join(', ') || checkDetails?.transactionName;
    const documentName = documentNameMap[checkDetails?.type];
    const subject = `${modifiedCheckAddress} | ${documentName} - ${headerStatus}`;
    const errorsList =
      checkDetails?.checkErrors?.errorFields?.checkErrors || [];
    const checkType = documentNameMap[checkDetails?.type];

    let body = `Hello ${recepientName},\n\n`;
    switch (status) {
      case 'LOOP_NOT_FOUND':
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. Please create a loop and submit for review your contract so we can process your sale.\n\nThank you!`;
        break;

      case 'DOCS_MISSING':
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. Please complete and submit for review your ${formsMissingText} so we can process your sale.\n\nThank you!`;
        body += checkDetails?.externalSourceId
          ? `\n Dotloop link: https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : '';
        break;

      case 'VALIDATION_FAILED':
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. The document has been uploaded to the loop, but there are issues with validation.\n\nPlease review the following:\n\n`;
        if (errorsList?.required?.length > 0) {
          body += `Critical errors:\n`;
          for (let i = 0; i < errorsList.required.length; i++) {
            body += `• ${errorsList.required[i]}\n`;
          }
        }
        if (errorsList?.optional?.length > 0) {
          body += `Non-critical errors:\n`;
          for (let i = 0; i < errorsList.optional.length; i++) {
            body += `• ${errorsList.optional[i]}\n`;
          }
        }
        body += checkDetails?.externalSourceId
          ? `\n Dotloop link: https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : '';
        break;

      case 'PRISM_NOT_FOUND':
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. Your receipt has been uploaded to the loop. Please create a transaction in Prism for us to process the funds. Please find a link to Prism here. For any questions please reach out to your office staff.
\n\nhttps://baird.appiancloud.com/suite/sites/Prism\n`;
        break;

      case 'COMPLETED':
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. A copy has been added to your loop and closing will process the funds.
Thank you!`;
        body += checkDetails?.externalSourceId
          ? `\n Dotloop link: https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : '';
        break;

      default:
        body += `Please review the status of your transaction at ${modifiedCheckAddress} and ensure all necessary documentation is submitted.\n\nThank you!`;
        break;
    }

    body += `\n\n\Baird & Warner Central Support Services\nDirect Line: 6307244727\nEmail: centralsupportservices@bairdwarner.com`;

    sendEmail(
      recipient,
      subject,
      body,
      window.location.href.includes('test.maxhome.ai')
        ? 'helpdesk@maxhome.ai'
        : 'centralsupportservices@bairdwarner.com'
    );
  };

  const handleSaveIssues = (updatedIssues: {
    checkErrors: { required: string[]; optional: string[] };
    checkErrorsFormatted: { required: string[]; optional: string[] };
  }) => {
    setCheckDetails((prevState: any) => {
      const newState = {
        ...prevState,
        checkErrors: {
          ...prevState.checkErrors,
          errorFields: {
            ...prevState.checkErrors.errorFields,
            checkErrors: updatedIssues.checkErrors,
            checkErrorsFormatted: updatedIssues.checkErrorsFormatted,
          },
        },
      };
      return newState;
    });
  };
  const component = (
    <>
      <div className="container-transaction">
        <div className="header-transaction">Overview</div>
        <div className="alertBox">
          <div
            className="alertTitle"
            style={{
              backgroundColor:
                checkDetails?.processingStatus?.status !== 'COMPLETED'
                  ? '#ffb800'
                  : '#29845A',
              color:
                checkDetails?.processingStatus?.status !== 'COMPLETED'
                  ? '#251A00'
                  : '#F8FFFB',
            }}
          >
            <img
              src={
                checkDetails?.processingStatus?.status !== 'COMPLETED'
                  ? alert
                  : correct
              }
              alt="Alert Icon"
              className="alertIcon"
              style={{ marginRight: '0.5rem' }}
            />
            {checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.required?.length ||
            checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.optional?.length
              ? `${
                  (checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
                    ?.required?.length || 0) +
                  (checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
                    ?.optional?.length || 0)
                } issues found |`
              : checkDetails?.processingStatus.status === 'DOCS_MISSING' ||
                  checkDetails?.processingStatus.status === 'LOOP_NOT_FOUND' ||
                  checkDetails?.processingStatus.status === 'PRISM_NOT_FOUND'
                ? ''
                : 'No issues found |'}
            <>
              <span style={{ marginLeft: '0.5rem' }}>
                {headerTextOptions[headerStatus]}
              </span>
              {headerStatus === 'DOCS_MISSING' ? (
                <span style={{ marginLeft: '0.5rem' }}>
                  {' : '}
                  {checkDetails?.processingStatus?.missingDocs &&
                    checkDetails?.processingStatus?.missingDocs
                      ?.map((doc: any) => documentNameMap[doc] || '')
                      .join(', ')}
                </span>
              ) : null}
            </>
          </div>
          <ul className="issueList">
            {/* Check if there are required errors and render them */}
            {checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.required?.length > 0 && (
              <>
                {/* Required Tag */}
                <div
                  style={{
                    fontWeight: 'bold',
                    paddingLeft: '0.5rem',
                    borderLeft: '4px solid red',
                  }}
                >
                  Critical errors :
                </div>
                {/* Required Errors List */}
                {checkDetails.checkErrors.errorFields.checkErrorsFormatted.required.map(
                  (error: any, index: number) => (
                    <li
                      style={{ marginLeft: '0.75rem' }}
                      key={`required-${index}`}
                      dangerouslySetInnerHTML={{
                        __html: error,
                      }}
                    />
                  )
                )}
              </>
            )}

            {/* Check if there are optional errors and render them */}
            {checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.optional?.length > 0 && (
              <>
                <div
                  style={{
                    fontWeight: 'bold',
                    paddingLeft: '0.5rem',
                    borderLeft: '4px solid #a4a449',
                  }}
                >
                  Non-critical errors :
                </div>
                {/* Optional Errors List */}
                {checkDetails.checkErrors.errorFields.checkErrorsFormatted.optional.map(
                  (error: any, index: number) => (
                    <li
                      style={{ marginLeft: '0.75rem' }}
                      key={`optional-${index}`}
                      dangerouslySetInnerHTML={{
                        __html: error,
                      }}
                    />
                  )
                )}
              </>
            )}

            {/* Show check completion message when no errors */}
            {!checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.required?.length &&
              !checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
                ?.optional?.length &&
              checkDetails?.processingStatus.status === 'COMPLETED' && (
                <li>Check is ready for submission.</li>
              )}
          </ul>

          <div className="sendButton">
            <Button type="primary" onClick={openSendEmail}>
              Send email
            </Button>
            {isAdmin && !window.location.href.includes('test.maxhome.ai') ? (
              <IssueListModal
                checkErrors={checkDetails?.checkErrors}
                checkId={checkDetails?._id}
                onSave={handleSaveIssues}
              />
            ) : null}
          </div>
        </div>
        <div className="transactionInfo">
          <div className="address">
            <img
              src={home}
              alt="Home Icon"
              className="homeIcon"
              style={{ marginRight: '1rem' }}
            />
            {checkDetails?.transactionName || 'N/A'}
          </div>
          {dropDownCell()}
        </div>
        <div className="transactionInfo">
          <div className="checkSection">
            <span className="checkLabel">Bank check</span>
            {pdfUrl && pdfUrl !== 'No Document found' && (
              <span className="checkDate">
                {`Sent ${getShortDateString(checkDetails?.createdAt)}` || 'N/A'}
              </span>
            )}
            <div className="pdfContainer">
              {pdfUrl ? (
                pdfUrl !== 'No Document found' ? (
                  <embed
                    src={`${pdfUrl}`}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                  />
                ) : (
                  <p>{pdfUrl}</p>
                )
              ) : (
                <p>Loading PDF...</p>
              )}
            </div>
          </div>
          <div className="submitSection">
            <label className="readyLabel">Ready to submit? (Y/N)</label>
            <div className="radioGroup">
              <label>
                <input
                  type="radio"
                  name="readyToSubmit"
                  value="yes"
                  checked={checkDetails?.readyToDeposit === true}
                  onChange={() => handleReadyToSubmitChange(true)}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="readyToSubmit"
                  value="no"
                  checked={checkDetails?.readyToDeposit === false}
                  onChange={() => handleReadyToSubmitChange(false)}
                />
                No
              </label>
            </div>
          </div>
          <div className="notesSection">
            <label className="notesLabel">Remarks</label>
            <textarea
              className="notesTextarea"
              placeholder="Add notes here ..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            ></textarea>
            <Button
              type="primary"
              onClick={handleSaveNote}
              loading={loadingButton}
            >
              {' '}
              Save note
            </Button>
          </div>
          <div className="formsSection">
            <span>Forms & contract</span>
            <div className="formsGrid">
              {checkDetails?.transactionDocuments?.length > 0 ? (
                checkDetails.transactionDocuments.map((doc: any) => (
                  <div key={doc._id} className="formItem">
                    <div className="formRow">
                      <img
                        src={contract}
                        alt="Contract"
                        className="formImage"
                      />
                      <div className="formContent">
                        <span className="formLabel">
                          {documentNameMap[doc.type] || doc.type}
                        </span>
                        <div className="formDate">
                          {getShortDateString(doc?.createdAt)}
                        </div>
                      </div>
                    </div>
                    <Button
                      type="primary"
                      onClick={() => handleViewImage(doc._id)}
                    >
                      View Document
                    </Button>
                  </div>
                ))
              ) : (
                <span>No forms or contracts uploaded</span>
              )}
            </div>
          </div>
          <div className="checklist">
            <div className="checklist-title">Checklist</div>
            <ul className="checklist-list">
              {checklistItems(checkDetails).map((item, index) => (
                <li key={index} className="checklist-item">
                  <div
                    className={`circle ${item.completed ? 'completed' : ''}`}
                  >
                    {item.completed && <span>&#10003;</span>}
                  </div>
                  <div className="checklist-content">
                    <span className="checklist-label">{item.label}</span>
                    {item.completed && (
                      <span className="checklist-date">
                        Dated: {item.time} • {item.date}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        title="Document Preview"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        width="80%"
      >
        {modalPdfUrl ? (
          <embed
            src={`${modalPdfUrl}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        ) : (
          <p>No document found</p>
        )}
      </Modal>
    </>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default CheckValidationView;
