import React, { ReactNode } from 'react';

interface CellProps {
  cellWidth?: string;
  border?: boolean;
  maxWidth?: string;
  error?: boolean;
  inactive?: boolean;
  background?: string;
  colSpan?: number;
  highlighter?: boolean;
  children: ReactNode;
}

const Cell: React.FC<CellProps> = (props) => (
  <td
    className="truncate"
    style={{
      width: props.cellWidth,
      maxWidth: props.maxWidth,
      border: props.border
        ? props.error
          ? '1px solid #ff435e'
          : '1px solid rgba(0,0,0,0.2)'
        : '',
      background: props.inactive
        ? '#f5f5f5'
        : props.background
          ? props.background
          : '',
      color: props.inactive ? '#666666' : '',
      padding: (props?.children as any)?.props?.padding,
      verticalAlign: (props?.children as any)?.props?.verticalAlign,
      backgroundColor:
        props.highlighter && !props.inactive
          ? 'rgb(255 67 94 / 35%)'
          : props.inactive
            ? '#6666'
            : '',
      textAlign: (props?.children as any)?.props?.textAlign,
    }}
    colSpan={props.colSpan}
  >
    {props.children}
  </td>
);

export default Cell;
