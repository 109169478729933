import { validateEnvVariable } from './helpers';

const { REACT_APP_PENDO_API_KEY } = process.env;

const API_BASE_URL = validateEnvVariable(REACT_APP_PENDO_API_KEY);

const pendoEnvironment = {
  API_BASE_URL,
};

export default pendoEnvironment;
