import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const ProtectedRoute = ({
  Component,
}: {
  Component: React.ComponentType;
}) => {
  const onRedirectingComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  };
  const ProtectedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => onRedirectingComponent(),
    loginOptions: {
      appState: { returnTo: window.location.pathname },
    },
  });

  return (
    <Suspense fallback={<CircularProgress />}>
      <ProtectedComponent />
    </Suspense>
  );
};

export default ProtectedRoute;
