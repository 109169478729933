import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { forwardRef } from 'react';

const FileExtractionStatusBar = forwardRef(
  ({ documentIDs, onClick }: { documentIDs: string[]; onClick: any }, ref) => (
    <Box
      display="flex"
      ref={ref}
      width="100%"
      height="fit-content"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      boxSizing="border-box"
      padding="0.75rem"
      sx={{ backgroundColor: '#2F78EB', color: 'white' }}
    >
      <Box margin="auto">
        Max is reviewing {documentIDs.length} document
        {documentIDs.length > 1 ? 's' : ''}...
        <CircularProgress
          sx={{ margin: 'auto 0 auto 1rem', color: 'white' }}
          size={20}
        />
      </Box>
      <CloseOutlined
        sx={{ cursor: 'pointer', color: 'white', float: 'right' }}
        onClick={onClick}
      />
    </Box>
  )
);

export default FileExtractionStatusBar;
