export const subGroupMap: { [key: string]: string } = {
  Contacts: 'Contacts',
  PropertyInformation: 'Property Information',
  PropertyDetails: 'Property Details',
  FinancialDetails: 'Financial Details',
  TransactionDetails: 'Transaction Details',
  FinancingDetails: 'Financing Details',
  TransactionTimeline: 'Transaction Timeline',
  LoanDetails: 'Loan Details',
  ClosingDetails: 'Closing Details',
  Others: 'Others',
  Signature: 'Signature',
  financials: 'Financials',
};

export const sortSectionsByPriority = (
  sections: Array<{ label: string; content: React.ReactNode }>
) => {
  const priorityMap: { [key: string]: number } = {
    PropertyInformation: 1,
    PropertyDetails: 2,
    TransactionTimeline: 3,
    FinancialDetails: 4,
    TransactionDetails : 5,
    Contacts: 6,
    FinancingDetails: 7,
    LoanDetails: 8,
    ClosingDetails: 9,
    Signature: 10,
    Initials : 11,
    Others: 12
  };

  return sections.sort((a, b) => {
    const aKey =
      Object.keys(subGroupMap).find((key) => subGroupMap[key] === a.label) ||
      a.label;
    const bKey =
      Object.keys(subGroupMap).find((key) => subGroupMap[key] === b.label) ||
      b.label;
    const aPriority = priorityMap[aKey] || Number.MAX_SAFE_INTEGER;
    const bPriority = priorityMap[bKey] || Number.MAX_SAFE_INTEGER;
    return aPriority - bPriority;
  });
};
