import {
  getCompliancePartnerID,
  getOfficeAdminPartnerID,
} from 'app/utils/localStorageHandler/userProfile';

import api from 'app/utils/api';

export class OfficeService {
  /**
   * Get offices associated with the logged in user
   * (This can correspond to the Transaction/Receiving office)
   *
   * @param queryString - Query string to filter the offices
   * @param page - Page number - default is 1
   * @param limit - Number of items per page - default is 10
   *
   * @returns
   * List of offices based on the query string
   */
  static async getOffices(
    mode: string = 'Compliance',
    search: string,
    page: number = 1,
    limit: number = 4
  ) {
    let partnerID = null;
    if (mode === 'Compliance') {
      partnerID = getCompliancePartnerID() || null;
    } else if (mode === 'OfficeAdmin') {
      partnerID = getOfficeAdminPartnerID() || null;
    }
    if (!partnerID) {
      return Promise.reject('Partner ID not found');
    }
    return api.get(
      `/office?search=${search}&page=${page}&limit=${limit}&partnerID=${partnerID}`
    );
  }
}
