import { Box, IconButton, Typography } from '@mui/material';
import { Card, Drawer } from 'antd';
import React, { useEffect } from 'react';

import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import EmailPreviewModal from './EmailPreview';
import { EmailRounded } from '@mui/icons-material';
import { ErrorCardComponent } from '../../components/ErrorCard';
import { ErrorSeverityChips } from '../../components/ErrorSeverityChip';
import { documentNameMap } from 'app/configs/documentNameMap';
import useTransactionDocumentErrorsManager from 'app/atomManagers/transactionDocumentErrorsManager';
import useTransactionErrorDocumentIdsAndActionListManager from 'app/atomManagers/transactionErrorDocumentIdsAndActionListManager';

const ErrorsDrawer: React.FC<{
  visible: boolean;
  onClose: () => void;
  transactionData: ComplianceTransaction;
}> = ({ visible, onClose, transactionData }) => {
  const [errorsCount, setErrorsCount] = React.useState(0);
  const errorsManager = useTransactionDocumentErrorsManager();
  const errorDocumentIdsAndActionListManager =
    useTransactionErrorDocumentIdsAndActionListManager();
  const [showEmailPreviewModal, setShowEmailPreviewModal] =
    React.useState(false);

  useEffect(() => {
    let errorCount = 0;
    if (
      transactionData &&
      transactionData._id &&
      errorsManager.errors?.[transactionData._id]
    ) {
      Object.keys(errorsManager.errors?.[transactionData._id]).forEach(
        (documentType) => {
          if (documentType === '_missingDocuments') {
            errorCount += 1;
          } else {
            errorCount += (
              errorsManager.errors?.[transactionData._id]?.[documentType] || []
            ).length;
          }
        }
      );
    }
    setErrorsCount(errorCount);
  }, [errorsManager.errors, transactionData]);

  return (
    <>
      <Drawer
        title={
          <Box display="flex" alignItems="center">
            <Box>
              <Typography fontSize="1.3rem" fontWeight="500">
                {transactionData?.name}
              </Typography>
              {`${errorsCount} Errors`}
            </Box>

            <IconButton
              style={{
                backgroundColor: '#2F78EB',
                color: 'white',
                marginLeft: 'auto',
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                transition: 'transform 0.3s ease-in-out',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.1)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
              onClick={() => setShowEmailPreviewModal(true)}
            >
              <EmailRounded />
            </IconButton>
          </Box>
        }
        visible={visible}
        onClose={onClose}
        width={600}
        style={{ backgroundColor: '#F6F6F6' }}
      >
        {transactionData &&
          transactionData?._id &&
          errorsManager.errors?.[transactionData?._id] &&
          errorsManager.errors[transactionData?._id] &&
          Object.keys(errorsManager.errors[transactionData?._id]).map(
            (documentType) => {
              if (documentType === '_missingDocuments') {
                console.log(
                  'Missing Documents: ',
                  errorsManager.errors[transactionData?._id][
                    '_missingDocuments'
                  ]
                );
                return (
                  <div key={documentType}>
                    <h3>Transaction Errors</h3>
                    <Card
                      hoverable
                      style={{
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                        color: 'black',
                        transition: 'all 0.3s ease',
                        backgroundColor: 'white',
                      }}
                    >
                      <Box
                        display="flex"
                        marginBottom="1rem"
                        alignItems="center"
                      >
                        {/* <ErrorRounded sx={{ color: error?.severity === 'optional' ? '#2F78EB' : '#FF4D4F', marginRight: '0.5rem', height: '1.5rem', width: '1.5rem' }} /> */}
                        <Typography
                          fontSize="1rem"
                          fontWeight={500}
                          marginRight="1rem"
                        >
                          Action Needed - Missing Documents
                        </Typography>
                        <Box marginLeft="auto">
                          {ErrorSeverityChips['required']}
                        </Box>
                      </Box>
                      <Box alignItems="center">
                        {errorsManager.errors[transactionData?._id][
                          '_missingDocuments'
                        ]?.map((missingDocumentType, index) => (
                          <Typography
                            fontSize="0.8rem"
                            fontWeight={400}
                            key={index}
                          >
                            • &nbsp;{' '}
                            {documentNameMap[String(missingDocumentType)]}
                          </Typography>
                        ))}
                      </Box>
                    </Card>
                  </div>
                );
              }
              return (
                <div key={documentType}>
                  <h3>{documentNameMap[documentType]}</h3>
                  {errorsManager.errors[transactionData?._id][
                    documentType
                  ]?.map((error, index) => (
                    <ErrorCardComponent
                      key={index}
                      error={error}
                      index={index}
                      onClick={() => {}}
                      verifyDocumentView={false}
                    />
                  ))}
                </div>
              );
            }
          )}
      </Drawer>
      <EmailPreviewModal
        open={showEmailPreviewModal}
        onCancel={() => {
          setShowEmailPreviewModal(false);
        }}
        errors={
          transactionData?._id
            ? errorsManager.errors[transactionData._id]
            : ([] as any)
        }
        errorDocumentIdsAndActionList={
          errorDocumentIdsAndActionListManager.errorDocumentIdsAndActionList as Record<
            string,
            Record<string, string>[]
          >
        }
        transactionData={transactionData}
      />
    </>
  );
};

export default ErrorsDrawer;
