// import * as firebaseui from 'firebaseui'

import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { getApp, getApps, initializeApp } from 'firebase/app';

import environment from 'app/config/environment';
import { firebaseAuth } from 'App';

// import firebase from 'firebase/compat/app';

// const firebaseConfig = {
//   apiKey: environment.firebase.FIREBASE_API_KEY,
//   authDomain: environment.firebase.FIREBASE_AUTH_DOMAIN,
//   projectId: environment.firebase.FIREBASE_PROJECT_ID,
//   storageBucket: environment.firebase.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: environment.firebase.FIREBASE_MESSAGING_SENDER_ID,
//   appId: environment.firebase.FIREBASE_APP_ID,
//   measurementId: environment.firebase.FIREBASE_MEASUREMENT_ID
// };

// let app;
// if (!getApps().length) {
//   app = initializeApp(firebaseConfig);
// } else {
//   app = getApps()[0];
// }

// const auth = getAuth(app);

const firebaseSignUpWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const response = await createUserWithEmailAndPassword(
    firebaseAuth,
    email,
    password
  );
  return response;
};

const firebaseSignInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const response = await signInWithEmailAndPassword(
    firebaseAuth,
    email,
    password
  );
  return response;
};

// TOOO: Uncomment this if you want to enable FirebaseUI's sign in with email and password
// const firebaseSignInWithEmailPasswordUI = async (email: string, password: string) => {
//   // const app = initializeApp(firebaseConfig);
//   const app = firebase.initializeApp(firebaseConfig);
//   const auth = getAuth(app);

//   let ui = firebaseui.auth.AuthUI.getInstance();

//   if (!ui) {
//     ui = new firebaseui.auth.AuthUI(auth);
//   }

//   ui.start('#firebaseui-auth-container', {
//     signInOptions: [
//       firebase.auth.EmailAuthProvider.PROVIDER_ID
//     ],
//     // Other config options...
//   });
// }

const getFirebaseAccessToken = async () => {
  // const auth = getAuth();

  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      firebaseAuth,
      (user) => {
        unsubscribe();
        if (user) {
          user.getIdToken().then(resolve).catch(reject);
        } else {
          resolve(null);
        }
      },
      reject
    );
  });
};

const firebaseSignOut = async () => {
  const response = await firebaseAuth.signOut();
  return response;
};

export {
  firebaseSignUpWithEmailAndPassword,
  firebaseSignInWithEmailAndPassword,
  // firebaseSignInWithEmailPasswordUI,
  getFirebaseAccessToken,
  firebaseSignOut,
};
