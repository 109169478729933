export const CheckStatusMap: Record<string, string> = {
  NOT_STARTED: 'Under Review',
  PROCESSING: 'Under Review',
  LOOP_NOT_FOUND: 'Needs Action : Loop Not Found',
  DOCS_MISSING: 'Needs Action : Documents Missing',
  VALIDATION_FAILED: 'Needs Action : Validation Failed',
  PRISM_NOT_FOUND: 'Needs Action : Prism Not Found',
  COMPLETED: 'Max Approved',
  DEPOSITED: 'Deposited',
};

export const CheckStatusMapOriginal: Record<string, string> = {
  NOT_STARTED: 'Not Started',
  PROCESSING: 'Processing',
  LOOP_NOT_FOUND: 'Needs Action : Loop Not Found',
  DOCS_MISSING: 'Needs Action : Documents Missing',
  VALIDATION_FAILED: 'Needs Action : Validation Failed',
  PRISM_NOT_FOUND: 'Needs Action : Prism Not Found',
  COMPLETED: 'Max Approved',
};
