import api from 'app/utils/api';

export class AgentService {
  public static getProperties(status: string = '') {
    return api.get(`/agent/contracts?status=${status}`);
  }

  public static addContact(
    role: string,
    name: string,
    phone: string,
    email: string
  ) {
    return api.post(`/contact/add`, {
      role,
      name,
      phone,
      email,
    });
  }

  public static getContacts() {
    return api.get(`/agent/contacts`);
  }

  public static inviteBuyer(
    token: string,
    email: string,
    message: string = ''
  ) {
    return api.post(`/contract/invite`, {
      token: token,
      email: email,
      message: message,
    });
  }

  public static updateTask(
    taskID: string,
    taskName: string = '',
    description: string = '',
    dueDate: string = '',
    status: string = '',
    agentNote: string = ''
  ) {
    let requestBody = Object();
    if (taskName !== '') {
      requestBody['taskName'] = taskName;
    }
    if (description !== '') {
      requestBody['description'] = description;
    }
    if (status !== '') {
      requestBody['status'] = status;
    }
    if (agentNote !== '') {
      requestBody['agentNote'] = agentNote;
    }

    return api.put(`/task/${taskID}`, requestBody);
  }

  public static completeTask(taskID: string) {
    const requestBody = {
      status: 'Completed',
    };

    return api.put(`/task/${taskID}`, requestBody);
  }

  public static deleteTask(taskID: string) {
    return api.delete(`/task/${taskID}`);
  }

  public static createBuyerAgencyAgreement(
    buyer1Name: string,
    buyer1Email: string,
    buyer1Phone: string,
    buyerAddress: string,
    startDate: string,
    endDate: string,
    dualAgency: boolean,
    commissionPercentage: number,
    effectiveArea: string,
    buyer2Name: string = '',
    buyer2Email: string = '',
    buyer2Phone: string = ''
  ) {
    let requestBody = {
      startDate: startDate,
      endDate: endDate,
      dualAgency: dualAgency,
      commissionPercentage: commissionPercentage.toString(),
      effectiveArea: effectiveArea,
      buyers: [
        {
          name: buyer1Name,
          email: buyer1Email,
          phone: buyer1Phone,
        },
      ],
      buyerAddress: buyerAddress,
    };
    if (buyer2Name !== '' && buyer2Email !== '' && buyer2Phone !== '') {
      requestBody['buyers'].push({
        name: buyer2Name,
        email: buyer2Email,
        phone: buyer2Phone,
      });
    }
    return api.post(`/agent/buyer-agency/create`, requestBody);
  }

  public static uploadBuyerAgencyAgreement(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    return api.post(`/agent/buyer-agency/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public static getClients() {
    return api.get(`/agent/clients`);
  }

  public static getDocument(documentID: string) {
    return api.get(`/agent/document/${documentID}`);
  }

  public static getUpcomingAndOverdueTasks() {
    return api.get(`/agent/tasks/upcoming-and-overdue`);
  }

  public static getTasksDueOn(dueDate: string) {
    return api.get(`/agent/tasks/due-on?dueDate=${dueDate}`);
  }

  public static uploadContract(
    mlsID: string,
    buyerName: string,
    buyerPhone: string,
    buyerEmail: string,
    file: any
  ) {
    const formData = new FormData();
    formData.append('mlsID', mlsID);
    formData.append('customerName', buyerName);
    formData.append('customerPhone', buyerPhone);
    formData.append('customerEmail', buyerEmail);
    formData.append('file', file);

    return api.post(`/contract/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
