import { setIsMobile, setOrientation } from 'features/ui';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store';
import { useEffect } from 'react';

export const ResponsiveRenderer = ({
  CompressedView,
  FullView,
}: {
  CompressedView: React.ReactNode;
  FullView: React.ReactNode;
}) => {
  const { orientation, isMobile } = useSelector((state: RootState) => state.ui);

  // Inside your component
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOrientation() as any); // Dispatch the setOrientation action creator
    dispatch(setIsMobile() as any); // Cast setIsMobile to AnyAction before dispatching
  }, [dispatch]);

  // useEffect(() => {
  //   console.log("Orientation: ", orientation);
  //   console.log("isMobile: ", isMobile);
  // }, [orientation, isMobile]);

  return (
    <>
      {orientation === 'portrait' && isMobile && CompressedView}
      {orientation === 'portrait' && !isMobile && FullView}
      {orientation === 'landscape' && isMobile && CompressedView}
      {orientation === 'landscape' && !isMobile && FullView}
    </>
  );
};
