import { Chip } from '@mui/material';
import { ReactElement } from 'react';

export const ErrorSeverityChips: Record<string, ReactElement> = {
  required: (
    <Chip
      label="Required"
      size="small"
      sx={{ backgroundColor: 'red', color: 'white', borderRadius: '4px' }}
    />
  ),
  optional: (
    <Chip
      label="Optional"
      size="small"
      sx={{ backgroundColor: '#E6F4FF', color: '#003EB3', borderRadius: '4px' }}
    />
  ),
};
