import { ErrorsByTransaction, errorsAtom } from 'app/atoms';

import { ErrorField } from 'app/interfaces/Document';
import { useRecoilState } from 'recoil';

const useTransactionDocumentErrorsManager = () => {
  const [errors, setErrors] = useRecoilState<ErrorsByTransaction>(errorsAtom);

  // Function to add multiple errors
  const addErrors = (
    transactionId: string,
    documentId: string,
    errors: ErrorField[] | string[]
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [transactionId]: {
        ...(prevErrors[transactionId] || {}),
        [documentId]: errors,
      },
    }));
  };

  // Function to clear all errors for a transaction document
  const clearErrorsForDocument = (
    transactionId: string,
    documentId: string
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [transactionId]: {
        ...(prevErrors[transactionId] || {}),
        [documentId]: [],
      },
    }));
  };

  const clearErrorsForTransaction = (transactionId: string) => {
    // Delete the kvp associated with transactionId
    const updatedErrors = { ...errors };
    delete updatedErrors[transactionId];
    setErrors(updatedErrors);
  };

  const resetAllErrors = () => {
    setErrors({});
  };

  return {
    errors,
    addErrors,
    clearErrorsForDocument,
    clearErrorsForTransaction,
    resetAllErrors,
  };
};

export default useTransactionDocumentErrorsManager;
