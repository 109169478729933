import {
  Modal as AntdModal,
  Empty,
  Input,
  Popconfirm,
  Table,
  message,
} from 'antd';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Close, Delete, Search } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';

import AddressForm from 'app/components/common/AddressForm/AddressForm';
import { AddressService } from 'app/components/common/AddressForm/AddressService/AddressService';
import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import { TRANSACTION_TYPE_OPTIONS } from '../utils/transactionTypeMap';
import { TransactionBuilder } from '../utils/transactionBuilder';
import TransactionFilterComponent from '../../components/TransactionFilterComponent';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { getMMDDYYYYString } from 'app/utils/datetime';
import { transactionProcessingStatusMap } from '../../components/colorScheme';
import useDebounce from 'app/hooks/UseDebounce';
import { useNavigate } from 'react-router-dom';

function formatString(str: string) {
  if (!str) return '';
  // Convert the entire string to lowercase
  str = str.toLowerCase();
  // Replace all instances of _ or - with a space
  str = str.replace(/[_-]/g, ' ');
  // Capitalize the first letter of each word
  str = str.replace(/\b\w/g, (c) => c.toUpperCase());
  return str;
}

const ComplianceTransactionsPage: React.FC = () => {
  const navigate = useNavigate();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const [loading, setLoading] = useState(true);
  const [progressBarLoading, setProgressBarLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionName, setTransactionName] = useState('');
  const [clientName, setClientName] = useState('');
  const [transactionType, setTransactionType] = useState('');

  const [refreshCount, setRefreshCount] = useState(0);

  const [transactions, setTransactions] = useState<ComplianceTransaction[]>([]);

  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Statuses filter
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [createdAtFilter, setCreatedAtFilter] = useState<{
    gte: string;
    lte: string;
  }>({
    gte: '',
    lte: '',
  });
  const [closingDateFilter, setClosingDateFilter] = useState<{
    gte: string;
    lte: string;
  }>({
    gte: '',
    lte: '',
  });
  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState<
    string[]
  >([]);

  // Address state
  const defaultAddress = {
    unitNumber: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    placeId: '',
  };
  const [formData, setFormData] = useState({
    propertyAddress: { value: '', label: '' },
    selectedAgentName: { value: '', label: '' },
    selectedDocumentType: { value: '', label: '' },
    selectedOffice: { value: '', label: '' },
    address: defaultAddress,
  });

  const [buttonLoading, setButtonLoading] = useState(false);
  const [transactionAlreadyExists, setTransactionAlreadyExists] =
    useState(false);
  const [alreadyExisitingTransaction, setAlreadyExisitingTransaction] =
    useState<any>(null);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [agentOptions, setAgentOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [resetKey, setResetKey] = useState(0); // This is used to unmount components and clear the states

  const debouncedAgentSearchTerm =
    useDebounce(formData?.selectedAgentName?.label, 500) || '';

  useEffect(() => {
    fetchAgents();
  }, [debouncedAgentSearchTerm]);

  const getTransactions = useCallback(
    (showLoading: boolean = true) => {
      if (showLoading) {
        setLoading(true);
      } else {
        setProgressBarLoading(true);
      }

      const transactionBuilder = new TransactionBuilder.builder()
        .setPage(currentPage)
        .setLimit(pageSize)
        .setStatus(selectedStatuses)
        .setCreatedAtGte(createdAtFilter.gte)
        .setCreatedAtLte(createdAtFilter.lte)
        .setClosingDateGte(closingDateFilter.gte)
        .setClosingDateLte(closingDateFilter.lte)
        .setSearch(debouncedSearchValue)
        .setTransactionTypes(selectedTransactionTypes)
        .build();

      transactionBuilder.fetchNext().then(
        (response) => {
          const transactionsWithKeys = response.data.map(
            (transaction: ComplianceTransaction) => ({
              ...transaction,
              key: transaction._id,
            })
          );
          setTransactions(transactionsWithKeys);
          setTotalTransactions(response.total);
          setCurrentPage(response.queryParams.page);
          setPageSize(response.queryParams.limit);
          setLoading(false);
          setProgressBarLoading(false);
          setInitialLoadComplete(true);
        },
        (error) => {
          console.error('Error fetching transactions:', error);
          message.error(`Error fetching transactions: ${error}`);
          setLoading(false);
          setProgressBarLoading(false);
        }
      );
    },
    [
      currentPage,
      pageSize,
      selectedStatuses,
      createdAtFilter,
      closingDateFilter,
      debouncedSearchValue,
      selectedTransactionTypes,
    ]
  );

  const handlePaginationChange = useCallback(
    (page: number, newPageSize?: number) => {
      if (newPageSize && newPageSize !== pageSize) {
        setPageSize(newPageSize);
        setCurrentPage(1);
      } else if (page !== currentPage) {
        setCurrentPage(page);
      }
    },
    [currentPage, pageSize]
  );

  // Add filter handler
  const handleFilterChange = useCallback(
    (
      statuses: string[],
      createdAt: { gte: string; lte: string },
      closingDate: { gte: string; lte: string },
      transactionTypes: string[]
    ) => {
      setSelectedStatuses(statuses);
      setCreatedAtFilter(createdAt);
      setClosingDateFilter(closingDate);
      setSelectedTransactionTypes(transactionTypes);
      setCurrentPage(1); // Reset to first page when filters change
    },
    [] // Empty dependency array since this function doesn't depend on any props or state
  );

  useEffect(() => {
    const showLoading = !initialLoadComplete;
    getTransactions(showLoading);
  }, [
    refreshCount,
    selectedStatuses,
    createdAtFilter.gte,
    createdAtFilter.lte,
    closingDateFilter.gte,
    closingDateFilter.lte,
    selectedTransactionTypes,
    currentPage,
    pageSize,
    debouncedSearchValue,
  ]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const resetFormData = () => {
    setFormData({
      propertyAddress: { value: '', label: '' },
      selectedAgentName: { value: '', label: '' },
      selectedDocumentType: { value: '', label: '' },
      selectedOffice: { value: '', label: '' },
      address: defaultAddress,
    });
  };

  const handleFormSubmit = () => {
    // Handle form submission logic here
    console.log('Transaction Name:', transactionName);
    console.log('Client Name:', clientName);
    console.log('Transaction Type:', transactionType);

    // Close the modal
    addTransaction();
  };

  const fetchAgents = useCallback(async () => {
    setLoadingAgents(true);
    try {
      const response = await ComplianceService.getAgent(
        debouncedAgentSearchTerm
      );
      const agents = response?.data?.data.map((agent: any) => ({
        label: agent.name,
        value: agent._id,
      }));
      setAgentOptions(agents);
    } catch (error) {
      message.error('Failed to load agents');
    } finally {
      setLoadingAgents(false);
    }
  }, [debouncedAgentSearchTerm]);

  const handleSelect = (field: keyof typeof formData, option: any) => {
    setFormData({ ...formData, [field]: option });
  };

  const addTransaction = async () => {
    setButtonLoading(true);
    if (!formData.address.placeId) {
      message.error('Please select an address from dropdown');
      setButtonLoading(false);
      return;
    }
    try {
      const unitNumber = formData?.address?.unitNumber;
      const placeId = formData?.address?.placeId;
      const response: any = await AddressService.validateAddress(
        placeId,
        unitNumber,
        formData?.selectedAgentName?.value
      );
      if (response.status === 200) {
        console.log('Address validated successfully:', response.data);
        setIsModalOpen(false);
        setTransactionAlreadyExists(true);
        setAlreadyExisitingTransaction(response.data);
        return;
      }
    } catch (error) {
      console.error('Error validating address:', error);
    }
    ComplianceService.createTransaction(formData).then(
      (response) => {
        console.log('Transaction created:', response.data);
        setRefreshCount(refreshCount + 1);
        setButtonLoading(false);
        setIsModalOpen(false);
        setTransactionName('');
        setClientName('');
        setTransactionType('');
        setResetKey((prevKey) => prevKey + 1);
        navigate(AppURLs.complianceDashboardTransaction(response?.data?._id));
      },
      (error) => {
        console.error('Error creating transaction:', error);
        setButtonLoading(false);
        message.error(`Error creating transaction: ${error}`);
      }
    );
  };

  const handleTransactionClick = (transactionID: string) => {
    console.log('Transaction ID:', transactionID);
    // navigate(`/v2/compliance/dashboard/transactions/${transactionID}`);
    navigate(AppURLs.complianceDashboardTransaction(transactionID));
  };

  const archiveTransaction = (transactionID: string) => {
    console.log('Archiving transaction:', transactionID);
    ComplianceService.archiveTransaction(transactionID).then((response) => {
      console.log('Transaction archived:', response.data);
      setRefreshCount(refreshCount + 1);
      message.success('Transaction archived successfully');
    });
  };

  useEffect(() => {
    resetFormData();
    setResetKey((prevKey) => prevKey + 1);
  }, [isModalOpen]);

  // const transactionTypeOptions = [
  //   { value: 'PURCHASE', label: 'Purchase' },
  //   { value: 'LISTING', label: 'Listing' },
  // ];

  // const TransactionCard = ({
  //   transaction,
  //   onClick,
  // }: {
  //   transaction: ComplianceTransaction;
  //   onClick: any;
  // }) => {
  //   const archiveTransaction = () => {
  //     console.log('Archiving transaction:', transaction._id);
  //     ComplianceService.archiveTransaction(transaction._id).then((response) => {
  //       console.log('Transaction archived:', response.data);
  //       setRefreshCount(refreshCount + 1);
  //       message.success('Transaction archived successfully');
  //     });
  //   };

  //   return (
  //     <Box borderRadius="8px" sx={{ backgroundColor: 'white' }}>
  //       <Box padding="1rem" display="flex" onClick={() => onClick()}>
  //         <Box>
  //           <Typography fontSize="1.2rem" fontWeight={550}>
  //             {transaction.name}
  //           </Typography>
  //           <Typography fontSize="0.9rem" fontWeight={350}>
  //             {/* {transaction?.customers[0]?.details?.name } */}
  //             {`Buyer: ${transaction?.sourceOfTruth?.data?.buyer?.value}   Seller: ${transaction?.sourceOfTruth?.data?.seller?.value}`}
  //           </Typography>
  //           <Chip
  //             size="small"
  //             label={
  //               complianceTransactionStatusMap?.[transaction.status]?.name ||
  //               formatString(transaction.status)
  //             }
  //             sx={{
  //               backgroundColor:
  //                 complianceTransactionStatusMap?.[transaction.status]
  //                   ?.backgroundColor || '#2F78EB',
  //               color:
  //                 complianceTransactionStatusMap?.[transaction.status]
  //                   ?.fontColor || 'white',
  //               borderRadius: '4px',
  //               marginTop: '1rem',
  //             }}
  //           />
  //         </Box>
  //         <Popconfirm
  //           title="Archive Transaction"
  //           description="Are you sure to archive this transaction?"
  //           onConfirm={(e) => {
  //             archiveTransaction();
  //             e?.stopPropagation();
  //           }}
  //           onCancel={(e) => {
  //             e?.stopPropagation();
  //           }}
  //           okText="Yes"
  //           cancelText="No"
  //         >
  //           <IconButton
  //             sx={{ marginLeft: 'auto', marginBottom: 'auto', color: 'black' }}
  //             onClick={(e) => e.stopPropagation()}
  //           >
  //             <ArchiveRounded />
  //           </IconButton>
  //         </Popconfirm>
  //       </Box>
  //     </Box>
  //   );
  // };

  const component = (
    <>
      {progressBarLoading && (
        <Box width="100%">
          <LoadingProgressBar />
        </Box>
      )}

      <Box style={{ padding: '1rem' }}>
        <AntdModal
          title={
            <Box display="flex" alignItems="center" gap={1}>
              <ExclamationCircleOutlined
                style={{ color: '#faad14', fontSize: '1.25rem' }}
              />
              <Typography fontSize="1.25rem" fontWeight={500}>
                Transaction Already Exists
              </Typography>
            </Box>
          }
          open={transactionAlreadyExists}
          onCancel={() => {
            setTransactionAlreadyExists(false);
            setAlreadyExisitingTransaction(null);
            setButtonLoading(false);
          }}
          footer={[
            <Button
              key="view"
              variant="contained"
              onClick={() => {
                window.open(
                  AppURLs.complianceDashboardTransaction(
                    alreadyExisitingTransaction?._id
                  ),
                  '_self'
                );
              }}
              sx={{ textTransform: 'none' }}
            >
              Go to transaction
            </Button>,
          ]}
        >
          <Typography>
            A transaction for this address already exists with the following
            details:
          </Typography>
          <Box
            mt={2}
            p={2}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
            }}
          >
            <Typography>
              <b>Property Address</b> :{' '}
              {alreadyExisitingTransaction?.name || 'N/A'}
            </Typography>
          </Box>
        </AntdModal>

        <AntdModal
          title={
            <Typography fontSize="1.25rem" fontWeight={500} marginBottom={2}>
              Create Transaction
            </Typography>
          }
          open={isModalOpen}
          onCancel={handleCloseModal}
          onOk={handleFormSubmit}
          cancelText="Cancel"
          okText={buttonLoading ? 'Submitting...' : 'Submit'}
          okButtonProps={{ loading: buttonLoading }}
        >
          <AddressForm key={resetKey} query={''} setFormData={setFormData} />
          <Autocomplete
            key={`agent-${resetKey}`}
            options={agentOptions}
            getOptionLabel={(option) => option.label}
            loading={loadingAgents}
            style={{ marginTop: '1rem' }}
            onInputChange={(event, value) =>
              handleSelect('selectedAgentName', { label: value, value: '' })
            }
            value={formData.selectedAgentName}
            onChange={(event, value) =>
              handleSelect('selectedAgentName', value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Select agent's name"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingAgents ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </AntdModal>

        <Box
          width="100%"
          padding="1rem"
          boxSizing="border-box"
          style={{ border: '1px solid #d8d8d8', borderRadius: '1rem' }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="2rem" fontWeight={500} fontFamily="Roboto">
              Transactions
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              sx={{ textTransform: 'None' }}
            >
              Create Transaction
            </Button>
          </Box>
          <Box
            width="100%"
            display="flex"
            paddingTop="0.5rem"
            gap="1rem"
            justifyContent="flex-end"
            paddingBottom="1rem"
          >
            <Input
              value={searchValue}
              size="large"
              placeholder="Search"
              suffix={
                searchValue === '' ? (
                  <IconButton
                    disabled={true}
                    sx={{
                      padding: '0.25rem',
                      color: 'black',
                      '&.Mui-disabled': {
                        color: 'black',
                      },
                    }}
                  >
                    <Search />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setSearchValue('');
                    }}
                    sx={{
                      padding: '0.25rem',
                      color: 'black',
                      '&.Mui-disabled': {
                        color: 'black',
                      },
                    }}
                  >
                    <Close />
                  </IconButton>
                )
              }
              style={{ maxWidth: '25rem' }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <TransactionFilterComponent
              onFilterChange={handleFilterChange}
              initialFilters={{
                statuses: [],
                statusLabels: [],
                transactionTypes: [],
              }}
            />
          </Box>

          {/* <Box display="flex" padding="1rem" alignItems="center">
          <TuneOutlined
            sx={{ height: '1.5rem', width: '1.5rem', marginRight: '0.5rem' }}
          />
          Filter by status:
          <Box> */}
          {/* <Chip 
              label="All"
              sx={{ 
                backgroundColor: 'white', 
                color: 'black', 
                margin: '0.5rem', 
                // border: `1px solid black`,
                border: filterStatuses.length === 0 ? '4px solid black' : '1px solid black',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                },
              }}
              onClick={() => setFilterStatuses([])}
            /> */}
          {/* {Object.keys(complianceTransactionStatusMap).map(
              (status, index) => (
                <Chip
                  key={index}
                  label={complianceTransactionStatusMap[status].name}
                  sx={{
                    backgroundColor:
                      complianceTransactionStatusMap[status].backgroundColor,
                    color: complianceTransactionStatusMap[status].fontColor,
                    margin: '0.5rem',
                    border: filterStatuses.includes(status)
                      ? `4px solid ${complianceTransactionStatusMap[status].fontColor}`
                      : ``,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.1)',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => {
                    if (filterStatuses.includes(status)) {
                      setFilterStatuses(
                        filterStatuses.filter((s) => s !== status)
                      );
                    } else {
                      setFilterStatuses([...filterStatuses, status]);
                    }
                  }}
                  disabled={progressBarLoading}
                />
              )
            )}
          </Box>
        </Box> */}

          {/* <Table dataSource={transactions} style={{ cursor: 'pointer' }} onRow={(record) => ({
          onClick: () => handleTransactionClick(record._id),
        })}>
          <Table.Column title="Name" dataIndex="name" key="address" />
          <Table.Column title="Client Name" dataIndex="clientName" key="clientName" />
          <Table.Column title="Category" dataIndex="category" key="category" />
        </Table> */}

          {/* {transactions.length > 0 ? (
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            {transactions.map((transaction, index) => (
              <Box
                key={index}
                height="100%"
                width="20rem"
                borderRadius="16px"
                margin="0.5rem"
                flexGrow={1}
                sx={{
                  cursor: 'pointer',
                  transition: 'box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                  },
                }}
              >
                <TransactionCard
                  transaction={transaction}
                  onClick={() => handleTransactionClick(transaction._id)}
                />
              </Box>
            ))}
            {transactions.length % 2 === 1 && (
              <Box
                height="100%"
                width="20rem"
                borderRadius="16px"
                margin="0.5rem"
                flexGrow={1}
                sx={{
                  visibility: 'hidden',
                }}
              ></Box>
            )}
            {transactions.length % 3 === 1 && (
              <Box
                height="100%"
                width="20rem"
                borderRadius="16px"
                margin="0.5rem"
                flexGrow={1}
                sx={{
                  visibility: 'hidden',
                }}
              ></Box>
            )}
            {transactions.length % 4 === 1 && (
              <Box
                height="100%"
                width="20rem"
                borderRadius="16px"
                margin="0.5rem"
                flexGrow={1}
                sx={{
                  visibility: 'hidden',
                }}
              ></Box>
            )}
            {transactions.length % 5 === 1 && (
              <Box
                height="100%"
                width="20rem"
                borderRadius="16px"
                margin="0.5rem"
                flexGrow={1}
                sx={{
                  visibility: 'hidden',
                }}
              ></Box>
            )}
          </Box>
        ) : (
          <Box width="100%" padding="5rem" boxSizing="border-box">
            <Empty description="No Transactions Found!" />
          </Box>
        )} */}

          {transactions.length > 0 ? (
            <Box>
              <Table
                dataSource={transactions}
                style={{ cursor: 'pointer' }}
                onRow={(record) => ({
                  onClick: () => handleTransactionClick(record._id),
                })}
                pagination={{
                  pageSize: pageSize,
                  total: totalTransactions,
                  current: currentPage,
                  showSizeChanger: true,
                  onChange: handlePaginationChange,
                }}
                components={{
                  header: {
                    cell: (props: any) => (
                      <th
                        {...props}
                        style={{
                          ...props.style,
                          background: 'rgba(216, 216, 216, 0.44)',
                        }}
                      />
                    ),
                  },
                }}
              >
                <Table.Column
                  title="Property Address"
                  dataIndex="name"
                  key="name"
                />
                <Table.Column
                  title="Transaction Type"
                  dataIndex="type"
                  key="type"
                  render={(type: string) => TRANSACTION_TYPE_OPTIONS(type)}
                />
                <Table.Column
                  title="Status"
                  dataIndex="processingStatus"
                  key="processingStatus"
                  render={(processingStatus: any) =>
                    transactionProcessingStatusMap?.[processingStatus?.status]
                      ?.name || formatString(processingStatus?.status)
                  }
                />
                <Table.Column
                  title="Closing Date"
                  dataIndex="closingDate"
                  key="closingDate"
                  render={(closingDate: any) =>
                    closingDate ? getMMDDYYYYString(closingDate) : '-'
                  }
                />
                <Table.Column
                  title="Delete"
                  dataIndex="_id"
                  key="_id"
                  render={(transactionID: string) => {
                    return (
                      <Popconfirm
                        title="Delete Transaction"
                        description="Are you sure to delete this transaction?"
                        onConfirm={(e) => {
                          archiveTransaction(transactionID);
                          e?.stopPropagation();
                        }}
                        onCancel={(e) => {
                          e?.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <IconButton
                          sx={{
                            marginLeft: 'auto',
                            marginBottom: 'auto',
                            color: 'black',
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Delete />
                        </IconButton>
                      </Popconfirm>
                    );
                  }}
                />
              </Table>
            </Box>
          ) : (
            <Box width="100%" padding="5rem" boxSizing="border-box">
              <Empty description="No Transactions Found!" />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <Box width="100%" boxSizing="border-box">
        <WaitAndLoadingWithSpinner loading={loading} component={component} />
      </Box>
    </>
  );
};

export default ComplianceTransactionsPage;
