import React, { ReactNode } from 'react';

interface HeaderProps {
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';
  display?: string;
  headerBorder?: boolean;
  background?: string;
  textAlign?: 'left' | 'right' | 'center';
  children: ReactNode;
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <thead
      style={{
        position: props.position || 'static',
        display: props.display || 'contents',
      }}
    >
      <tr>
        {Array.isArray(props.children) ? (
          props.children?.map(
            (child, i) =>
              child && (
                <th
                  key={i}
                  style={{
                    border: props.headerBorder ? '1px solid  #999' : '',
                    background: props.background,
                    textAlign: props.textAlign,
                  }}
                >
                  {child}
                </th>
              )
          )
        ) : (
          <th
            style={{
              border: props.headerBorder ? '1px solid  #999' : '',
            }}
          >
            {props.children}
          </th>
        )}
      </tr>
    </thead>
  );
};

export default Header;
