import './ComplianceCheck.scss';

import { Badge, Box, Chip, TextField, Tooltip } from '@mui/material';
import { Button, Empty, Modal, Popconfirm, message } from 'antd';
import { CheckStatusMap, CheckStatusMapOriginal } from './types';
import { Delete, LoopRounded } from '@mui/icons-material';
import { Header, Row, Table } from 'app/components/common/Table';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CheckBuilder } from 'app/services/checkBuilder';
import { ComplianceService } from 'app/services/compliance';
import { DownloadCheckReport } from './util/DownloadCheckReport';
import FilterComponent from './FilterComponent';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import Paginator from 'app/components/common/Paginator';
import UploadDocumentComponent from './UploadDocumentComponent';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import dayjs from 'dayjs';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getShortDateString } from 'app/utils/datetime';
import useDebounce from 'app/hooks/UseDebounce';

interface ComplianceChecksPageProps {
  isCompliance?: boolean;
}

interface LabelObject {
  label: string;
  count: number;
}

const LabelObjectChip = ({
  label,
  count,
}: {
  label: string;
  count: number;
}) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        marginRight: '0.75rem',
      }}
    >
      <Chip label={label} />
      {count === 2 && (
        <Badge
          badgeContent={
            <LoopRounded style={{ height: '1rem', width: '1rem' }} />
          }
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
      )}
    </div>
  );
};

const ComplianceChecksPage = ({ isCompliance }: ComplianceChecksPageProps) => {
  const initialFilters = {
    statuses: isCompliance
      ? ['LOOP_NOT_FOUND,DOCS_MISSING,VALIDATION_FAILED,PRISM_NOT_FOUND']
      : [],
    checkTypes: [],
    transactionOffices: [],
    readyToScan: null,
    readyToScanLabel: 'Ready to Submit',
    statusLabels: isCompliance ? ['Needs Action'] : [],
  };
  const [progressBarLoading, setProgressBarLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [checks, setChecks] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    initialFilters.statuses
  );
  const [selectedCheckType, setSelectedCheckType] = useState<string[]>([]);
  const [selectedTransactionOfficeID, setSelectedTransactionOfficeID] =
    useState<string[]>([]);
  const [selectedReceivingOfficeID, setSelectedReceivingOfficeID] = useState<
    string[]
  >([]);
  const [readyToScan, setReadyToScan] = useState<boolean | null>(
    initialFilters.readyToScan
  );
  const [dateFilter, setDateFilter] = useState<{ gte: string; lte: string }>({
    gte: '',
    lte: '',
  });
  const [depositDateFilter, setDepositDateFilter] = useState<{
    gte: string;
    lte: string;
  }>({
    gte: '',
    lte: '',
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPdfUrl, setModalPdfUrl] = useState<string | null>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [activeStatusDropdown, setActiveStatusDropdown] = useState<
    number | null
  >(null);
  const statusDropdownRef = useRef<HTMLDivElement>(null);

  // To keep track of the active filters and render the chips
  const [filterLabelObjects, setFilterLabelObjects] = useState<LabelObject[]>(
    []
  );
  const [buttonFilterLabel, setButtonFilterLabel] = useState<string | null>(
    null
  );
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const toggleStatusDropdown = (index: number) => {
    if (!isAdmin) return;
    setActiveStatusDropdown((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      statusDropdownRef.current &&
      !statusDropdownRef.current.contains(event.target as Node)
    ) {
      setActiveStatusDropdown(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleChangeStatus = async (check: any, status: string) => {
    try {
      await ComplianceService.patchCheck(
        check?._id,
        check?.readyToDeposit,
        check?.notes,
        status
      );

      setChecks((prevChecks) =>
        prevChecks.map((c) =>
          c._id === check._id
            ? { ...c, processingStatus: { ...c.processingStatus, status } }
            : c
        )
      );

      setActiveStatusDropdown(null);
      message.success(
        `Check status updated to  ${CheckStatusMapOriginal[status]}!`
      );
    } catch (error: any) {
      message.error('Error updating status:', error);
    }
  };

  const isAdmin = localStorage.getItem('Admin');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  /**
   * Transaction and Receiving office labels are identical
   * In case of identical labels, update the count of the label
   *
   * @param labels - Array of strings
   * @returns mergedLabels - Array of objects of interface LabelObject
   */
  const mergeIdenticalLabels = (labels: string[]) => {
    let mergedLabels = [];
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      const labelIndex = mergedLabels.findIndex((l) => l.label === label);
      if (labelIndex === -1) {
        mergedLabels.push({
          label: label,
          count: 1,
        });
      } else {
        mergedLabels[labelIndex].count++;
      }
    }

    console.log('Merged Labels: ', mergedLabels);
    return mergedLabels;
  };

  const generateDropdownData = (check: any) => {
    return [
      [
        { label: 'Branch Office', value: check?.transactionOffice?.name },
        { label: 'Receiving Office', value: check?.receivingOffice?.name },
        ...((isCompliance &&
          (check.processingStatus.status === 'VALIDATION_FAILED' ||
            check.processingStatus.status === 'COMPLETED' ||
            check.processingStatus.status === 'LOOP_NOT_FOUND' ||
            check.processingStatus.status === 'DOCS_MISSING' ||
            check.processingStatus.status === 'PRISM_NOT_FOUND')) ||
        isAdmin
          ? [
              {
                label: 'View Validation',
                value: `/compliance/dashboard/validation?checkID=${check?._id}`,
                isButton: true,
              },
            ]
          : []),
      ],
      [
        { label: 'Agent Name', value: check?.agentName },
        // {
        //   label: 'Maxhome Link',
        //   text: check?.transactionId ? 'https://www.maxhome.ai' :  'Generating Maxhome Link...',
        //   value: check?.transactionId
        //     ? `/compliance/dashboard/transactions/${check?.transactionId}`
        //     : 'N/A',
        //   isLink: true,
        // },
        {
          label: 'Dotloop Link',
          text: check?.externalSourceID ? 'https://www.dotloop.com' : 'N/A',
          value: check?.externalSourceID
            ? `https://www.dotloop.com/m/loop?viewId=${check?.externalSourceID}`
            : 'N/A',
          isLink: check?.externalSourceID ? true : false,
        },
        {
          label: 'View Document',
          value: handleViewPdf,
          check: check,
          isModal: true,
        },
      ],
    ];
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleRowSelection = (e: any, rowId: number) => {
    setSelectedRowIds((prevSelectedRowIds) =>
      prevSelectedRowIds.includes(rowId)
        ? prevSelectedRowIds.filter((id) => id !== rowId)
        : [...prevSelectedRowIds, rowId]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setSelectAll(checked);

    if (checked) {
      setSelectedRowIds(checks.map((_, i) => i));
    } else {
      setSelectedRowIds([]);
    }
  };

  const handleButtonFilterClick = (index: number) => {
    setActiveIndex((prev) => {
      if (prev === index) {
        // If clicking the same button, clear the filter
        setButtonFilterLabel(null);
        setDateFilter({ gte: '', lte: '' });
        return null;
      } else {
        // Set new filter
        const filter = buttonFilters[index];
        setButtonFilterLabel(filter.label);
        setDateFilter({
          gte: filter.gte,
          lte: filter.lte || dayjs().endOf('day').format('YYYY-MM-DD'),
        });
        return index;
      }
    });
    setPage(1);
  };

  const rowHeaders = isCompliance
    ? [
        'Select All',
        'Property Address',
        'Receiving Office',
        'Type of Check',
        'Submission Date',
        'Ready to Submit',
        'Status',
        'Remarks',
        'Delete',
      ]
    : [
        'Select All',
        'Property Address',
        'Receiving Office',
        'Type of Check',
        'Submission Date',
        'Ready to Submit',
        'Remarks',
        'Delete',
      ];

  const buttonFilters = [
    {
      label: 'Submitted Today',
      gte: dayjs().startOf('day').format('YYYY-MM-DD'),
      lte: '',
      isDepositDateFilter: false,
    },
    {
      label: 'Ready Today',
      gte: dayjs().startOf('day').format('YYYY-MM-DD'),
      lte: '',
      isDepositDateFilter: true,
    },
  ];

  const handlePagination = (newPage: number) => {
    setPage(newPage);
  };

  const handleDownloadReport = async () => {
    try {
      setIsGeneratingReport(true);
      // Create a check builder with current filters but fetch all results
      let checkBuilder;
      let response;

      // If all rows are selected, fetch all checks with current filters
      if (selectAll) {
        checkBuilder = new CheckBuilder.builder()
          .setPage(1)
          .setLimit(totalElements) // Fetch all elements at once
          .setStatus(selectedStatus.join(','))
          .setCheckType(selectedCheckType.join(','))
          .setTransactionOfficeID(selectedTransactionOfficeID.join(','))
          .setReceivingOfficeID(selectedReceivingOfficeID.join(','))
          .setCreatedAtGte(dateFilter.gte)
          .setCreatedAtLte(dateFilter.lte)
          .setSearch(debouncedSearchTerm)
          .setReadyToDeposit(readyToScan)
          .setReadyToDepositMarkedAtGte(depositDateFilter.gte)
          .setReadyToDepositMarkedAtLte(depositDateFilter.lte)
          .build();

        response = await checkBuilder.fetchNext();
      }

      // If specific rows are selected, filter those rows else set all checks
      const checksToDownload =
        selectedRowIds.length > 0 && !selectAll
          ? checks.filter((_, index) => selectedRowIds.includes(index))
          : response.data;

      DownloadCheckReport(checksToDownload);
      setSelectedRowIds([]);
      setSelectAll(false);
    } catch (error) {
      message.error('Failed to generate report');
      console.error('Error generating report:', error);
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return 'No Document found';
    }
  };

  const handleViewPdf = async (check: any) => {
    const doc = await fetchTransactionDocument(
      check.document,
      check.transactionId
    );
    setModalPdfUrl(doc);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    if (modalPdfUrl) URL.revokeObjectURL(modalPdfUrl);
  };

  const refreshTableData = useCallback(
    (source?: string) => {
      setProgressBarLoading(true);

      const isUploadSource = source === 'upload';
      const currentPage = isUploadSource ? 1 : page;
      const currentStatus = isUploadSource ? '' : selectedStatus.join(',');
      const currentCheckType = isUploadSource
        ? ''
        : selectedCheckType.join(',');
      const currentTransactionOfficeID = isUploadSource
        ? ''
        : selectedTransactionOfficeID.join(',');
      const currentReceivingOfficeID = isUploadSource
        ? ''
        : selectedReceivingOfficeID.join(',');
      const currentFilter = isUploadSource
        ? { gte: '', lte: '', isDepositDateFilter: false }
        : buttonFilters[activeIndex!] || {};
      const currentSearchTerm = isUploadSource ? '' : debouncedSearchTerm;
      const currentReadyToScan = isUploadSource ? null : readyToScan;
      const gteDateFilter = dateFilter.gte
        ? dateFilter.gte
        : currentFilter.isDepositDateFilter
          ? ''
          : currentFilter.gte || '';
      const lteDateFilter = dateFilter.lte
        ? dateFilter.lte
        : currentFilter.isDepositDateFilter
          ? ''
          : currentFilter.lte || '';
      const gtedepositDateFilter = depositDateFilter.gte
        ? depositDateFilter.gte
        : currentFilter.isDepositDateFilter
          ? currentFilter.gte || ''
          : '';
      const ltedepositDateFilter = depositDateFilter.lte
        ? depositDateFilter.lte
        : currentFilter.isDepositDateFilter
          ? currentFilter.lte || ''
          : '';

      if (isUploadSource) {
        setPage(1);
        setSelectedStatus([]);
        setActiveIndex(null);
        setSearchTerm('');
        setReadyToScan(null);
        setSelectedRowIds([]);
      }
      console.log('depositDateFilter: ', depositDateFilter);

      const checkBuilder = new CheckBuilder.builder()
        .setPage(currentPage)
        .setLimit(10)
        .setStatus(currentStatus)
        .setCheckType(currentCheckType)
        .setTransactionOfficeID(currentTransactionOfficeID)
        .setReceivingOfficeID(currentReceivingOfficeID)
        .setCreatedAtGte(gteDateFilter)
        .setCreatedAtLte(lteDateFilter)
        .setSearch(currentSearchTerm)
        .setReadyToDeposit(currentReadyToScan)
        .setReadyToDepositMarkedAtGte(gtedepositDateFilter)
        .setReadyToDepositMarkedAtLte(ltedepositDateFilter)
        .build();

      checkBuilder.fetchNext().then((response: any) => {
        setChecks(response.data);
        setTotalElements(response.total);
        setHasMore(checkBuilder.hasMore);
        setProgressBarLoading(false);
        setLoading(false);
        setSelectedRowIds([]);
        setSelectAll(false);
      });
    },
    [
      dateFilter,
      depositDateFilter,
      page,
      selectedStatus,
      selectedCheckType,
      selectedTransactionOfficeID,
      selectedReceivingOfficeID,
      readyToScan,
      activeIndex,
      debouncedSearchTerm,
      filterLabelObjects,
    ]
  );

  const handleDeleteCheck = async (id: string) => {
    try {
      const response = await ComplianceService.deleteCheck(id);
      if (response.status === 200) {
        message.success('Check deleted successfully!');
        refreshTableData();
      }
    } catch (error: any) {
      message.error(error);
      console.error('Error deleting check:', error);
    }
  };

  useEffect(() => {
    refreshTableData();
  }, [refreshTableData]);

  // Modify the useEffect that updates filterLabelObjects
  useEffect(() => {
    setFilterLabelObjects((prevLabels) => {
      // Remove any existing button filter label
      const filteredLabels = prevLabels.filter(
        (label) => !buttonFilters.some((filter) => filter.label === label.label)
      );

      // Add the new button filter label if it exists
      if (buttonFilterLabel) {
        return [...filteredLabels, { label: buttonFilterLabel, count: 1 }];
      }

      return filteredLabels;
    });
  }, [buttonFilterLabel]);

  const component = (
    <>
      <Box width="100%" boxSizing="border-box">
        {progressBarLoading && (
          <Box width="100%">
            <LoadingProgressBar />
          </Box>
        )}

        <div className="checks-page">
          <div className="header">Overview</div>

          <div className="content-container">
            <div className="section-header">
              Checks uploaded
              {isCompliance ? (
                <Button
                  disabled={selectedRowIds.length === 0}
                  style={{ height: '40px', borderRadius: '12px' }}
                  type="primary"
                  onClick={handleDownloadReport}
                  loading={isGeneratingReport}
                >
                  {isGeneratingReport
                    ? 'Generating Report...'
                    : 'Download Report'}
                </Button>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    disabled={selectedRowIds.length === 0}
                    style={{ height: '40px', borderRadius: '12px' }}
                    type="primary"
                    onClick={handleDownloadReport}
                    loading={isGeneratingReport}
                  >
                    {isGeneratingReport
                      ? 'Generating Report...'
                      : 'Download Report'}
                  </Button>
                  <UploadDocumentComponent
                    onSuccess={() => {
                      refreshTableData('upload');
                    }}
                  />
                </div>
              )}
            </div>

            <div className="filter-bar">
              <div className="button-filters">
                {buttonFilters.map((filter, i) => (
                  <div
                    className={`filter-button-new ${i === activeIndex ? 'active' : ''}`}
                    key={i}
                    onClick={() => handleButtonFilterClick(i)}
                  >
                    {filter.label}
                  </div>
                ))}
              </div>

              <div className="search-filters">
                <TextField
                  label="Search for property"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearchChange(e);
                  }}
                  InputProps={{
                    style: {
                      height: '3rem',
                      backgroundColor: 'white',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      top: '-5px',
                    },
                  }}
                />

                <FilterComponent
                  isCompliance={isCompliance}
                  buttonFilterLabel={buttonFilterLabel}
                  setButtonFilterLabel={setButtonFilterLabel}
                  setActiveIndex={setActiveIndex}
                  onFilterChange={(
                    statuses: string[],
                    checkTypes: string[],
                    transactionOfficeIDs: string[],
                    receivingOfficeIDs: string[],
                    readyToScan: boolean | null,
                    labels: string[],
                    dateFilter: { gte: string; lte: string },
                    depositDateFilter: { gte: string; lte: string }
                  ) => {
                    setSelectedStatus(statuses);
                    setSelectedCheckType(checkTypes);
                    setSelectedTransactionOfficeID(transactionOfficeIDs);
                    setSelectedReceivingOfficeID(receivingOfficeIDs);
                    setReadyToScan(readyToScan);
                    setFilterLabelObjects(mergeIdenticalLabels(labels));
                    setDateFilter(dateFilter);
                    setDepositDateFilter(depositDateFilter);
                    setPage(1);
                  }}
                  initialFilters={initialFilters}
                />
              </div>
            </div>

            {filterLabelObjects.length > 0 && (
              <div
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  padding: '1rem 1rem 0 1rem',
                  alignItems: 'left',
                  justifyContent: 'left',
                  display: 'flex',
                }}
              >
                {filterLabelObjects.map((labelObject, i) => (
                  <LabelObjectChip
                    key={i}
                    label={labelObject.label}
                    count={labelObject.count}
                  />
                ))}
              </div>
            )}

            <div className="table-section">
              <Table borderRadius="12px">
                <Header background="#d8d8d870">
                  {rowHeaders.map((header, i) => (
                    <span key={i}>
                      {header === 'Select All' ? (
                        <Tooltip title="Select All" placement="top">
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '0.25rem',
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                              style={{ height: '14px', width: '14px' }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        header
                      )}
                    </span>
                  ))}
                </Header>
                {checks.length ? (
                  checks.map((check, i) => (
                    <Row
                      key={`${i}`}
                      isCompliance={isCompliance}
                      dropdownData={generateDropdownData(check)}
                    >
                      {
                        <span>
                          <input
                            type="checkbox"
                            style={{ height: '14px', width: '14px' }}
                            checked={selectedRowIds.includes(i)}
                            onChange={(e) => handleRowSelection(e, i)}
                          />
                        </span>
                      }
                      {/* <Tooltip
                        title={check?.transactionName || ''}
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              fontSize: '18px',
                              lineHeight: '22px',
                              padding: '12px',
                            },
                          },
                        }}
                      > */}
                      <span className="multiple-lines">
                        {check?.transactionName || '-'}
                      </span>
                      <span>{check?.receivingOffice?.name}</span>
                      {/* </Tooltip> */}
                      <span>{documentNameMap[check?.type]}</span>
                      <span>{getShortDateString(check?.createdAt)}</span>
                      <span>{check?.readyToDeposit ? 'Yes' : 'No'}</span>
                      {isCompliance ? (
                        <span
                          className="no-toggle multiple-lines"
                          onClick={(e) => {
                            if (isAdmin) {
                              e.stopPropagation();
                              toggleStatusDropdown(i);
                            }
                          }}
                        >
                          {CheckStatusMap[check?.processingStatus?.status] ||
                            '-'}
                          {activeStatusDropdown === i && (
                            <div
                              ref={statusDropdownRef}
                              className="status-dropdown"
                            >
                              {Object.entries(CheckStatusMapOriginal).map(
                                ([statusKey, statusValue]) => (
                                  <div
                                    key={statusKey}
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleChangeStatus(check, statusKey);
                                    }}
                                  >
                                    {statusValue}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </span>
                      ) : null}
                      {/* <Tooltip
                        title={check?.remarks || ''}
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              fontSize: '18px',
                              lineHeight: '22px',
                              padding: '12px',
                            },
                          },
                        }}
                      > */}
                      <span className="multiple-lines">
                        {check?.remarks || '-'}
                      </span>
                      {/* </Tooltip> */}
                      <span className="no-toggle">
                        <Popconfirm
                          className="no-toggle"
                          title="Are you sure you want to delete this check?"
                          onConfirm={() => handleDeleteCheck(check?._id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Delete />
                        </Popconfirm>
                      </span>
                    </Row>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={rowHeaders.length}
                      style={{ textAlign: 'center', padding: '1rem' }}
                    >
                      <Empty description="No checks found" />
                    </td>
                  </tr>
                )}
              </Table>
            </div>

            {checks.length > 0 ? (
              <div className="pagination">
                <Paginator
                  offset={page}
                  totalElements={totalElements}
                  first={page === 1}
                  last={!hasMore}
                  pageNumber={page}
                  pageSize={10}
                  paginate={(newPage) => {
                    handlePagination(newPage);
                  }}
                  background="#f5f5f5"
                />
              </div>
            ) : null}
          </div>
        </div>
      </Box>
      <Modal
        title="Document Preview"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        width="80%"
      >
        {modalPdfUrl ? (
          <embed
            src={`${modalPdfUrl}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        ) : (
          <p>No document found</p>
        )}
      </Modal>
    </>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default ComplianceChecksPage;
