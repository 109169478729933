import './LandingPage.scss';

import { Box, Typography } from '@mui/material';
import { Button, Divider, Form, Input, Tooltip, message } from 'antd';
import { IdcardOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';

import { AppURLs } from 'app/utils/appURLs';
import { Modal } from 'antd';
import Navbar from './components/Navbar';
import React from 'react';
import { ResponsiveRenderer } from 'app/utils/super-components/responsiveRenderer';
import { VisitorService } from 'app/services/visitor';
import { WorkOutlineOutlined } from '@mui/icons-material';
import maxHomeLogo from 'assets/logos/maxhome.svg';
import { useNavigate } from 'react-router-dom';

const navLinks = [
  { label: 'Home', href: '' },
  { label: 'Press', href: '#press' },
];

const CustomInput = React.forwardRef((props: any, ref) => {
  const { icon, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.75rem',
      }}
    >
      {React.cloneElement(icon, {
        style: { marginRight: '1rem', fontSize: '1.5rem' },
      })}
      <Input ref={ref} size="large" {...rest} />
    </div>
  );
});

const GetInTouch: React.FC = () => {
  const navigate = useNavigate();

  // ---------- States & Variables ----------

  const [form] = Form.useForm();

  // ---------- Functions ----------

  // - FormData related functions
  const validateFormData = (data: Record<string, any>) => {
    const errors = {
      name: '',
      email: '',
      companyName: '',
      role: '',
    };

    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      errors.email = 'Invalid email format';
    }

    if (!data.companyName.trim()) {
      errors.companyName = 'Company name is required';
    }

    if (!data.role.trim()) {
      errors.role = 'Role is required';
    }

    return !Object.values(errors).some((error) => error !== '');
  };

  const onFinish = (values: any) => {
    console.log('Form values:', values);

    if (validateFormData(values)) {
      const visitorSvc = new VisitorService();
      visitorSvc
        .saveGetInTouchRequest(
          values.name,
          values.email,
          values.companyName,
          values.role
        )
        .then((res) => {
          console.log('Response:', res);
          Modal.success({
            title: 'Request Sent!',
            content:
              'Thanks for your interest. We will be in touch with you soon',
            onOk() {
              form.resetFields();
              navigate(AppURLs.landingPage());
            },
          });
        })
        .catch((err) => {
          console.error(err);
          message.error('Failed to update task - ' + err.message);
        });
    } else {
      Modal.error({
        title: 'Invalid Form',
        content: 'Please fill out all the fields correctly',
      });
    }
  };

  // ---------- Render ----------

  const FormComponent = ({ viewType = 'full' }: { viewType?: string }) => {
    const formComponentStyle =
      viewType === 'full'
        ? ({
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            padding: '1.5rem 2rem',
            minWidth: '25rem',
            maxWidth: '30vw',
            boxSizing: 'border-box',
            marginRight: '7rem',
          } as React.CSSProperties)
        : ({
            backgroundColor: 'white',
            borderRadius: '16px',
            padding: '1.5rem 2rem',
            width: '100%',
            boxSizing: 'border-box',
            margin: '3rem 1rem 3rem 1rem',
            zIndex: 2,
          } as React.CSSProperties);
    return (
      <Form
        form={form}
        style={formComponentStyle}
        layout="vertical"
        onFinish={onFinish}
      >
        <h4 style={{ textAlign: 'left', marginBottom: '2rem' }}>
          Fill out the form and we'll send you an email with demo dates and
          times.
        </h4>

        <Form.Item
          name="name"
          rules={[{ required: true, message: 'Please enter your name!' }]}
        >
          <CustomInput placeholder="Name" icon={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please enter your email!' },
            { type: 'email', message: 'Please enter a valid email address!' },
          ]}
        >
          <CustomInput
            placeholder="Work Email Address"
            type="email"
            icon={<MailOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="companyName"
          rules={[
            { required: true, message: 'Please enter your company name!' },
          ]}
        >
          <CustomInput
            placeholder="Company Name"
            icon={<WorkOutlineOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="role"
          rules={[{ required: true, message: 'Please enter your role/title!' }]}
        >
          <CustomInput
            placeholder="Role/Title in Company"
            icon={<IdcardOutlined />}
          />
        </Form.Item>
        <Form.Item shouldUpdate style={{ textAlign: 'center' }}>
          {() => {
            return (
              <Tooltip
                title={
                  ''
                  // form.getFieldsError().some(({ errors }) => errors.length > 0)
                  //   ? 'Please fix the errors in the form'
                  //   : 'Please fill out the form'
                }
              >
                <Button
                  type="primary"
                  style={{ borderRadius: '25px', marginTop: '0.75rem' }}
                  size="large"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form
                      .getFieldsError()
                      .some(({ errors }) => errors.length > 0)
                  }
                >
                  Submit
                </Button>
              </Tooltip>
            );
          }}
        </Form.Item>
      </Form>
    );
  };

  const FooterComponent = ({ viewType = 'full' }: { viewType?: string }) => {
    const contentBoxStyle =
      viewType === 'full'
        ? ({
            display: 'flex',
            width: '100%',
            boxSizing: 'border-box',
          } as React.CSSProperties)
        : ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            boxSizing: 'border-box',
          } as React.CSSProperties);

    return (
      <Box
        width="100vw"
        padding="2rem"
        boxSizing="border-box"
        sx={{
          backgroundColor: 'white',
        }}
      >
        <img
          src={maxHomeLogo}
          alt="MaxHome Logo"
          style={{ margin: '1rem 0 3rem 0', maxHeight: '2rem' }}
        />

        {/* <Box display="flex" width="100%" boxSizing="border-box"> */}
        <Box style={contentBoxStyle}>
          <Box maxWidth="30rem" padding="0 1rem 1rem 0">
            <Typography fontWeight={550} fontSize="1.2rem" color="primary">
              Our Team
            </Typography>
            <Typography fontSize="1rem">
              We are a team of passionate individuals who are dedicated to
              making your real estate business more efficient and profitable.
            </Typography>
          </Box>

          {/* TODO: Add action to open mail here */}
          <Box maxWidth="30rem" padding="0 1rem 1rem 0">
            <Typography fontWeight={550} fontSize="1.2rem" color="primary">
              Contact Us
            </Typography>
            <Typography fontSize="1rem">hello@maxhome.ai</Typography>
          </Box>

          <Box
            maxWidth="30rem"
            padding="0 1rem 1rem 0"
            marginLeft={viewType === 'full' ? 'auto' : 0}
          >
            <Typography fontWeight={550} fontSize="1.2rem" color="primary">
              Legal
            </Typography>
            {/* TODO: Link Terms & Conditions and Privacy Policy  */}
            {/* <Typography fontSize="1rem">Terms & Conditions</Typography> */}
            <Typography
              fontSize="1rem"
              onClick={() => navigate(AppURLs.privacyPolicy())}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Privacy Policy
            </Typography>
          </Box>
        </Box>

        <Box width="10rem" margin="3rem auto 0 auto">
          <Divider
            style={{ backgroundColor: '#4293E9', marginBottom: '0.5rem' }}
          />
          <Typography
            fontSize="0.9rem"
            fontWeight={500}
            color="primary"
            textAlign="center"
          >
            © {new Date().getFullYear()} MaxHome.ai
          </Typography>
        </Box>
      </Box>
    );
  };

  const FullView = (
    <Box
      sx={{
        position: 'relative',
        background: '#E5EEFF',
        height: '100vh',
        width: '100vw',
        boxSizing: 'border-box',
        overflow: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          width: '0px',
          background: 'transparent',
        },
      }}
    >
      <Navbar links={navLinks} bookADemoPage={true} />
      {/* Blue overlay covering 80vh */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '80vh',
          backgroundColor: '#2F78EB',
          zIndex: 0,
        }}
      />

      {/* Content container */}
      <div
        style={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        {/* Left section with text */}
        <Box
          boxSizing="border-box"
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="1rem"
          sx={{ flexGrow: 1 }}
        >
          <Box
            maxWidth="50vw"
            display="flex"
            flexDirection="column"
            marginBottom="10rem"
            boxSizing="border-box"
          >
            <Typography
              lineHeight="4.4rem"
              color="white"
              fontSize="3.7rem"
              fontWeight={900}
              textAlign="left"
            >
              Get in touch with our team to learn how{' '}
              <span style={{ color: '#73E0A9' }}>
                AI can revolutionize your business
              </span>
            </Typography>
          </Box>
        </Box>

        <FormComponent viewType="full" />
      </div>

      <FooterComponent viewType="full" />
    </Box>
  );

  const CompressedView = (
    <Box
      sx={{
        backgroundColor: '#E5EEFF',
        boxSizing: 'border-box',
        overflow: 'auto', // Enable scrolling if needed
        scrollbarWidth: 'none', // For Firefox
        msOverflowStyle: 'none', // For Internet Explorer and Edge
        '&::-webkit-scrollbar': {
          // For Chrome, Safari, and Opera
          display: 'none',
        },
      }}
    >
      <Navbar links={navLinks} bookADemoPage={true} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          backgroundColor: '#2F78EB',
          boxSizing: 'border-box',
          zIndex: 0,
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="1rem"
        boxSizing="border-box"
        margin="2rem 1rem 2rem 1rem"
      >
        <Typography
          lineHeight="3.4rem"
          fontSize="3.2rem"
          fontWeight={800}
          color="white"
          textAlign="left"
          zIndex={1}
        >
          Get in touch with our team to learn how{' '}
          <span style={{ color: '#73E0A9' }}>
            AI can revolutionize your business
          </span>
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" boxSizing="border-box">
        <FormComponent viewType="compressed" />
      </Box>

      <FooterComponent viewType="compressed" />
    </Box>
  );

  return (
    <>
      <ResponsiveRenderer FullView={FullView} CompressedView={CompressedView} />
    </>
  );
};

export default GetInTouch;
