import React, { useState, useEffect } from 'react';
import { Modal, Select, DatePicker, Button, Typography, message } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import './ReassignPrimaryAdminModal.scss';
import { WorkAllocationService } from 'app/modules/util/workAllocationService';
import dayjs, { Dayjs } from 'dayjs';

interface ReassignPrimaryAdminModalProps {
  visible: boolean;
  onCancel: () => void;
  selectedOffice: any | null;
  processingTeamMembers: any[] | [];
  getWorkAllocations(): Promise<any>;
}

const ReassignPrimaryAdminModal: React.FC<ReassignPrimaryAdminModalProps> = ({
  visible,
  onCancel,
  selectedOffice,
  processingTeamMembers,
  getWorkAllocations,
}) => {
  const [newAdmin, setNewAdmin] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  // Reset states when modal is closed
  useEffect(() => {
    if (!visible) {
      setNewAdmin(undefined);
      setStartDate(null);
      setButtonLoading(false);
    }
  }, [visible]);

  const handleReassignPrimaryAdmin = async () => {
    setButtonLoading(true);
    const payload: any = {
      office: selectedOffice?.office?._id,
      assignment: selectedOffice?.assignment?._id,
      replacementAssignment: newAdmin,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    };
    try {
      const response: any =
        await WorkAllocationService.reassignPrimaryAdmin(payload);
      if (response.status === 200) {
        getWorkAllocations();
        message.success('Primary Admin Reassigned successfully');
        onCancel(); // Close the modal on success
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setButtonLoading(false);
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Reassign Primary Admin"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={buttonLoading}
          onClick={handleReassignPrimaryAdmin}
          disabled={!newAdmin || !startDate}
        >
          Save
        </Button>,
      ]}
      className="resign-primary-admin-modal"
    >
      <div className="input-group">
        <HomeOutlined style={{ marginRight: '1rem' }} />
        <Typography.Text>{selectedOffice?.office?.name}</Typography.Text>
      </div>

      <div className="input-group">
        <UserOutlined style={{ marginRight: '1rem' }} />
        <Typography.Text>{selectedOffice?.assignment?.name}</Typography.Text>
        <ArrowRightOutlined style={{ margin: '0 1rem' }} />
        <Select
          placeholder="Select New Primary Admin"
          onChange={setNewAdmin}
          className="full-width"
          style={{ width: '100%', height: '2.5rem' }}
          options={processingTeamMembers.map((member) => ({
            value: member._id,
            label: member.name,
          }))}
          value={newAdmin}
        />
      </div>

      <div className="input-group">
        <CalendarOutlined style={{ marginRight: '1rem' }} />
        <DatePicker
          placeholder="Select Start Date"
          onChange={setStartDate}
          className="full-width"
          style={{ width: '100%', height: '2.5rem' }}
          value={startDate}
          disabledDate={disabledDate}
        />
      </div>
    </Modal>
  );
};

export default ReassignPrimaryAdminModal;
