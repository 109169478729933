import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import './CookieBanner.scss';
import { UserService } from 'app/services/user';

interface CookieBannerProps {
  privacyPolicyUrl: string;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ privacyPolicyUrl }) => {
  const [dataTrackingScreenShown, setDataTrackingScreenShown] = useState(false);
  const updateUserDataTracking = async () => {
    try {
      const response = await UserService.updateUserDataTracker();
      if (response.status === 200) {
        console.log('User data tracking updated successfully');
      }
    } catch (error) {
      console.log('Error updating user data tracking:', error);
    }
  };

  useEffect(() => {
    updateUserDataTracking();
  }, []);

  return !dataTrackingScreenShown ? (
    <Box className="cookie-banner">
      <Typography variant="body1" className="cookie-banner__text">
        We and our third-party partners use cookies and other similar tracking
        technologies, including session replay tools, to monitor your
        interactions on our website and/or services, enhance your user
        experience, and improve our website and/or services. By clicking
        "Accept" or continuing to use our site, you agree to our use of cookies
        and similar technologies. For more information on our use of cookies and
        similar technologies, please read our{' '}
        <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </Typography>
      <Box className="cookie-banner__buttons">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setDataTrackingScreenShown(true)}
        >
          Accept
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default CookieBanner;
