export const loadClarity = (callback) => {
  const existingScript = document.getElementById('clarityAnalytics');
  if (!existingScript) {
    const script = document.createElement('script');
    script.id = 'clarityAnalytics';
    script.defer = true;
    const scriptText = document.createTextNode(
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', 'o40c3kv4ob')
    );
    script.appendChild(scriptText);
    document.body.appendChild(script);
    setTimeout(() => {
      if (typeof callback === 'function') callback();
    }, 100);
  }
};

export const initializeClarity = async (userId, screenName, subScreenName) => {
  try {
    loadClarity();
    window.clarity('identify', userId, userId, screenName, subScreenName);
    window.clarity('set', 'screen', subScreenName);
  } catch (error) {
    console.log(error);
  }
};
