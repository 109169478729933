import React, { useEffect, useState } from 'react';
import {
  Modal,
  Select,
  DatePicker,
  Button,
  Space,
  Typography,
  Divider,
  message,
  Spin,
} from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import './OutOfOfficeModal.scss';
import dayjs, { Dayjs } from 'dayjs';
import { WorkAllocationService } from 'app/modules/util/workAllocationService';

const { RangePicker } = DatePicker;
const { Text } = Typography;

interface OfficeSelectorProps {
  office?: any | null;
  setOffice: (value: any) => void;
  offices: { _id: string; name: string }[];
  selectedOffice?: any | null;
}

interface OutOfOfficeModalProps {
  visible: boolean;
  onCancel: () => void;
  offices: { _id: string; name: string }[];
  selectedOffice?: any | null;
  getWorkAllocations: () => Promise<any>;
  processingTeamMembers: any[];
}

const OfficeSelector: React.FC<OfficeSelectorProps> = ({
  office,
  setOffice,
  offices,
  selectedOffice,
}) => {
  return (
    <div className="input-group">
      <HomeOutlined style={{ marginRight: '1rem' }} />
      {selectedOffice?.office?.name ? (
        <Typography.Text>{selectedOffice.office.name}</Typography.Text>
      ) : (
        <Select
          placeholder="Search for an Office"
          showSearch
          options={offices.map((office) => ({
            value: office._id,
            label: office.name,
          }))}
          style={{ width: '100%', height: '2.5rem' }}
          value={office}
          onChange={(value) => setOffice(value)}
          optionFilterProp="label"
        />
      )}
    </div>
  );
};

const OutOfOfficeModal: React.FC<OutOfOfficeModalProps> = ({
  visible,
  onCancel,
  offices,
  selectedOffice = null,
  getWorkAllocations,
  processingTeamMembers,
}) => {
  const [admins, setAdmins] = useState(selectedOffice?.leaveData || []);
  const [office, setOffice] = useState({});
  const [dates, setDates] = useState<
    { from: dayjs.Dayjs | null; to: dayjs.Dayjs | null }[]
  >(
    selectedOffice?.leaveData?.map((admin: any) => ({
      from: admin.from ? dayjs(admin.from) : null,
      to: admin.to ? dayjs(admin.to) : null,
    })) || []
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState<number | null>(null);

  const addAdmin = () => {
    setAdmins([...admins, {}]);
    setDates([...dates, { from: null, to: null }]);
  };

  const deleteAdmin = (index: number) => {
    const newAdmins = [...admins];
    newAdmins.splice(index, 1);
    setAdmins(newAdmins);
    const newDates = [...dates];
    newDates.splice(index, 1);
    setDates(newDates);
  };

  const removeAdmin = async (index: number) => {
    if (
      admins[index].from &&
      admins[index].to &&
      admins[index].substitute._id
    ) {
      setDeletingIndex(index);
      const payload = {
        office: selectedOffice.office._id,
        assignment: selectedOffice?.assignment?._id,
        id : admins[index]?._id,
        from: admins[index].from,
        to: admins[index].to,
      };

      try {
        const response: any =
          await WorkAllocationService.deleteWorkAllocation(payload);
        if (response.status === 200) {
          deleteAdmin(index);
          message.success('Successfully deleted out of office');
        } else {
          throw new Error('Error deleting out of office');
        }
      } catch (error: any) {
        message.error(error.message);
      } finally {
        setDeletingIndex(null);
      }
    } else {
      deleteAdmin(index);
    }
  };

  const handleDateChange = (
    index: number,
    dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    const newDates = [...dates];
    newDates[index] = {
      from: dateRange ? dateRange[0] : null,
      to: dateRange ? dateRange[1] : null,
    };
    if (admins[index].substitute && !(admins[index].from && admins[index].to)) {
      // if substitute is selected first
      admins[index] = {
        from: dateRange ? dateRange[0] : null,
        to: dateRange ? dateRange[1] : null,
        substitute: {
          _id: admins[index].substitute._id,
        },
      };
    } else if (!(admins[index].from && admins[index].to)) {
      // if no substitute is selected
      admins[index] = {
        from: dateRange ? dateRange[0] : null,
        to: dateRange ? dateRange[1] : null,
      };
    }
    setDates(newDates);
  };

  const handleAdminChange = (index: number, admin: any) => {
    const newAdmins = [...admins];
    newAdmins[index] = {
      ...newAdmins[index],
      substitute: {
        _id: admin,
      },
    };
    setAdmins(newAdmins);
  };

  const saveOooStatus = async () => {
    setButtonLoading(true);
    let oooPayload: any = {};
    oooPayload = {
      office: selectedOffice?.office?._id,
      assignment: selectedOffice?.assignment?._id,
      leaveDataList: [],
    };

    admins.forEach((admin: any, index: number) => {
      if (Object.keys(selectedOffice).length) {
        oooPayload.leaveDataList.push({
          id : admin?._id,
          substitute: admin?.substitute?._id,
          from: dates[index].from?.format('YYYY-MM-DD'),
          to: dates[index].to?.format('YYYY-MM-DD'),
        });
      }
      if (!Object.keys(selectedOffice).length) {
        oooPayload.leaveDataList.push({
          from: dates[index].from?.format('YYYY-MM-DD'),
          to: dates[index].to?.format('YYYY-MM-DD'),
        });
      }
    });
    try {
      let response: any;
      if (Object.keys(selectedOffice).length) {
        response = await WorkAllocationService.editWorkAllocation(oooPayload);
      } else {
        response = await WorkAllocationService.addWorkAllocation(oooPayload);
      }
      if (response.status === 200) {
        getWorkAllocations();
        onCancel();
        setButtonLoading(false);
        message.success('Out of Office updated successfully');
      }
    } catch (error: any) {
      setButtonLoading(false);
      message.error(error.message);
    }
  };

  // Set initial admins and dates
  useEffect(() => {
    if (selectedOffice?.leaveData) {
      setAdmins(selectedOffice.leaveData);
      setDates(
        selectedOffice.leaveData.map((admin: any) => ({
          from: admin.from ? dayjs(admin.from) : null,
          to: admin.to ? dayjs(admin.to) : null,
        }))
      );
    }
  }, [selectedOffice]);

  // Reset states when modal is closed
  useEffect(() => {
    if (!visible) {
      setAdmins(selectedOffice?.leaveData || []);
      setOffice({});
      setDates(
        selectedOffice?.leaveData?.map((admin: any) => ({
          from: admin.from ? dayjs(admin.from) : null,
          to: admin.to ? dayjs(admin.to) : null,
        })) || []
      );
      setButtonLoading(false);
      setDeletingIndex(null);
    }
  }, [visible, selectedOffice]);

  const disabledDate = (current: Dayjs) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <Modal
      className="out-of-office-modal"
      title="Out Of Office"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={buttonLoading}
          onClick={() => saveOooStatus()}
        >
          Save
        </Button>,
      ]}
    >
      <OfficeSelector
        office={office}
        setOffice={setOffice}
        offices={offices}
        selectedOffice={selectedOffice}
      />
      <div className="input-group">
        <UserOutlined style={{ marginRight: '1rem' }} />
        <Typography.Text>{selectedOffice?.assignment?.name}</Typography.Text>
      </div>
      <Divider />
      {admins.map((admin: any, index: number) => (
        <div key={index} className="admin-section">
          <div className="admin-section-header">
            <Text strong className="section-title">
              OOO #{index + 1}
            </Text>
            {deletingIndex === index ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              <DeleteOutlined
                className="delete-admin"
                onClick={() => removeAdmin(index)}
              />
            )}
          </div>
          <Space direction="vertical" size={8} className="admin-inputs">
            <div className="input-group">
              <UserOutlined style={{ marginRight: '1rem' }} />
              <Select
                placeholder="Select a Replacement Admin"
                style={{ width: '100%', height: '2.5rem' }}
                defaultValue={admin?.substitute?.name}
                options={processingTeamMembers.map((member: any) => ({
                  label: member?.name,
                  value: member?._id,
                }))}
                onChange={(value) => handleAdminChange(index, value)}
                value={admin?.substitute?._id}
              />
            </div>
            <div className="input-group">
              <CalendarOutlined style={{ marginRight: '1rem' }} />
              <RangePicker
                style={{ width: '100%', height: '2.5rem' }}
                value={[dates[index].from, dates[index].to]}
                onChange={(dateRange) => handleDateChange(index, dateRange)}
                disabledDate={disabledDate}
              />
            </div>
          </Space>
          {index <= admins.length - 1 && <Divider />}
        </div>
      ))}
      <Button type="link" onClick={addAdmin}>
        Add Another Out Of Office
      </Button>
    </Modal>
  );
};

export default OutOfOfficeModal;
