/**
 * Compares two values of any type and returns true if they match
 * @param value1 First value to compare
 * @param value2 Second value to compare
 * @returns boolean indicating if values match
 */
export function compareValues(value1: any, value2: any): boolean {
  // Handle null/undefined
  if (value1 == null && value2 == null) return true;
  if (value1 == null || value2 == null) return false;

  // Handle dates
  if (value1 instanceof Date && value2 instanceof Date) {
    return value1.getTime() === value2.getTime();
  }

  // Handle arrays
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;
    return value1.every((item, index) => compareValues(item, value2[index]));
  }

  // Handle objects
  if (typeof value1 === 'object' && typeof value2 === 'object') {
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);
    if (keys1.length !== keys2.length) return false;
    return keys1.every((key) => compareValues(value1[key], value2[key]));
  }

  // Handle primitives (string, number, boolean)
  return value1 === value2;
}
