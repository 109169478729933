import React, { useState } from 'react';
import { Button, message } from 'antd';
import {
  Modal,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Fab,
  CircularProgress,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { ComplianceService } from 'app/services/compliance';

interface CheckErrors {
  errorFields?: {
    checkErrors?: {
      required?: string[];
      optional?: string[];
    };
    checkErrorsFormatted?: {
      required?: string[];
      optional?: string[];
    };
  };
}

interface IssueListModalProps {
  checkErrors?: CheckErrors;
  checkId: string;
  onSave: (updatedIssues: {
    checkErrors: { required: string[]; optional: string[] };
    checkErrorsFormatted: { required: string[]; optional: string[] };
  }) => void;
}

const IssueListModal: React.FC<IssueListModalProps> = ({
  checkErrors,
  checkId,
  onSave,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [requiredIssues, setRequiredIssues] = useState<string[]>(
    checkErrors?.errorFields?.checkErrors?.required || []
  );
  const [optionalIssues, setOptionalIssues] = useState<string[]>(
    checkErrors?.errorFields?.checkErrors?.optional || []
  );
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editType, setEditType] = useState<'required' | 'optional' | null>(
    null
  );
  const [editValue, setEditValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditIndex(null);
    setEditType(null);
    setEditValue('');
  };

  const handleEdit = (
    index: number,
    type: 'required' | 'optional',
    value: string
  ) => {
    setEditIndex(index);
    setEditType(type);
    setEditValue(value);
  };

  const handleSave = () => {
    if (editType === 'required' && editIndex !== null) {
      const newRequiredIssues = [...requiredIssues];
      newRequiredIssues[editIndex] = editValue;
      setRequiredIssues(newRequiredIssues);
    } else if (editType === 'optional' && editIndex !== null) {
      const newOptionalIssues = [...optionalIssues];
      newOptionalIssues[editIndex] = editValue;
      setOptionalIssues(newOptionalIssues);
    }
    setEditIndex(null);
    setEditType(null);
    setEditValue('');
  };

  const handleDelete = (index: number, type: 'required' | 'optional') => {
    if (type === 'required') {
      setRequiredIssues(requiredIssues.filter((_, i) => i !== index));
    } else {
      setOptionalIssues(optionalIssues.filter((_, i) => i !== index));
    }
  };

  const handleAdd = (type: 'required' | 'optional') => {
    if (type === 'required') {
      setRequiredIssues([...requiredIssues, '']);
      handleEdit(requiredIssues.length, type, '');
    } else {
      setOptionalIssues([...optionalIssues, '']);
      handleEdit(optionalIssues.length, type, '');
    }
  };

  const handleModalSave = async () => {
    setIsLoading(true);
    const updatedIssues = {
      checkErrors: { required: requiredIssues, optional: optionalIssues },
      checkErrorsFormatted: {
        required: requiredIssues,
        optional: optionalIssues,
      },
    };

    try {
      await ComplianceService.updateCheckErrors(checkId, updatedIssues);
      onSave(updatedIssues);
      message.success('Errors updated successfully');
      handleClose();
    } catch (error: any) {
      console.error('Error updating issues:', error);
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderIssueList = (issues: string[], type: 'required' | 'optional') => (
    <List>
      {issues.map((issue, index) => (
        <ListItem key={index}>
          {editIndex === index && editType === type ? (
            <TextField
              fullWidth
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              onBlur={handleSave}
            />
          ) : (
            <ListItemText primary={issue} />
          )}
          <IconButton onClick={() => handleEdit(index, type, issue)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(index, type)}>
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
      <Fab
        color="primary"
        size="small"
        onClick={() => handleAdd(type)}
        style={{ marginBottom: '0.5rem' }}
      >
        <AddIcon />
      </Fab>
    </List>
  );

  return (
    <>
      <Button type="primary" onClick={handleOpen}>
        Edit Issues
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            minWidth: '500px',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <h3>Edit Issues</h3>
          <span style={{ fontWeight: '600' }}>Critical Errors:</span>
          {renderIssueList(requiredIssues, 'required')}
          <span style={{ fontWeight: '600', marginTop: '0.5rem' }}>
            Non-critical Errors:
          </span>
          {renderIssueList(optionalIssues, 'optional')}
          <Button
            style={{ marginTop: '8px' }}
            type="primary"
            onClick={handleModalSave}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default IssueListModal;
