import './index.scss';

import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Breadcrumb,
  Button,
  Card,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Modal,
  Radio,
  Select,
  Table,
  Upload,
  UploadFile,
  message,
} from 'antd';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  FileOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { ErrorRounded, SendRounded } from '@mui/icons-material';
import {
  REVIEW_STATUS_OPTIONS,
  StatusChip,
} from './components/ReviewStatusChip';
import {
  complianceTransactionStatusMap,
  transactionProcessingStatusMap,
} from '../../components/colorScheme';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import { Document } from 'app/interfaces/Document';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import { TRANSACTION_TYPE_OPTIONS } from '../utils/transactionTypeMap';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { capitalizeFirstLetter } from 'app/utils/string';
import { documentIDsAtom } from 'app/atoms';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getAdminProfile } from 'app/utils/localStorageHandler/userProfile';
import { getCustomIdFromUrl } from 'app/utils/url';
import { setDocumentIDsInLocalStorage } from 'app/utils/localStorageHandler/document';
import { useRecoilState } from 'recoil';
import useTransactionDocumentErrorsManager from 'app/atomManagers/transactionDocumentErrorsManager';

const { Option } = Select;

interface TransactionDetailsProps {
  _id: string;
  name: string;
  clientName: string;
  status: string;
  type: string;
  documents: Document[];
  sourceOfTruth: Record<string, any>; // TODO: Once the schema is final, write a proper interface for this
  realtorAgentID: string;
  realtorAgent: Record<string, any>;
  createdAt: string;
  updatedAt: string;
  agents: Record<string, any>[];
  processingStatus: any;
  closingDate?: string;
}

interface TransactionCompletesnessResponse {
  data: {
    allDocumentsPresent: boolean;
    missingDocuments: string[];
    presentCount: number;
    missingCount: number;
  };
}

// interface CheckValidationResult {
//   checkType: string;
//   isValid: boolean;
//   presentDocuments: string[];
//   missingDocuments: string[];
// }

const ComplianceTransactionDetailsPage = () => {
  const navigate = useNavigate();

  const errorsManager = useTransactionDocumentErrorsManager();

  const transactionID = getCustomIdFromUrl('transactionID') || '';
  const [documentIDs, setDocumentIDs] = useRecoilState(documentIDsAtom);
  const [agents, setAgents] = useState<Record<string, any>[]>([]);
  const [assignedAgent, setAssignedAgent] = useState<string>(''); // TODO: Fetch this from the backend
  const realtorSelectorRef = useRef<HTMLSelectElement>(null);

  const [loading, setLoading] = useState(true);
  const [progressBarLoading, setProgressBarLoading] = useState(false);

  const [showTransactionOverview, setShowTransactionOverview] = useState(false);
  const [transactionOverviewGroups, setTransactionOverviewGroups] = useState<
    string[]
  >([]);

  const [downloadingId, setDownloadingId] = useState<string | null>(null);

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return null;
    }
  };

  const handleDownloadPdf = async (doc: any) => {
    try {
      const documentUrl = await fetchTransactionDocument(
        doc._id,
        getCustomIdFromUrl('transactionID')
      );
      if (documentUrl) {
        const link = document.createElement('a');
        link.href = documentUrl;
        link.download = `${doc.title || 'document'}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(documentUrl);
      } else {
        message.error('Failed to download the document');
      }
    } catch (error) {
      message.error('An error occurred while downloading the document');
    }
  };

  const handleStatusChange = async (documentId: string, newStatus: string) => {
    try {
      setProgressBarLoading(true);
      await ComplianceService.updateDocumentReviewStatus(documentId, newStatus);
      message.success('Document status updated successfully');
      getTransaction(false); // Refresh the data
    } catch (error: any) {
      console.error('Error updating document status:', error);
      message.error(
        error?.response?.data?.message || 'Failed to update document status'
      );
    } finally {
      setProgressBarLoading(false);
    }
  };

  const handleDownload = async (record: Document) => {
    if (downloadingId) return; // Skip if already downloading
    try {
      setDownloadingId(record._id);
      await handleDownloadPdf(record);
    } finally {
      setDownloadingId(null);
    }
  };

  const columns = [
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    // },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'displayStatus',
      key: 'displayStatus',
      render: (displayStatus: string, record: Document) => {
        return (
          <Select
            value={displayStatus}
            onChange={(value) => handleStatusChange(record._id, value)}
            onClick={(e) => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
            style={{ minWidth: '6rem' }}
            bordered={false}
          >
            {REVIEW_STATUS_OPTIONS.map((status) => (
              <Option key={status} value={status}>
                <StatusChip status={status} />
              </Option>
            ))}
          </Select>
        );
      },
    },
    // TODO: Add this on the backend
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date: string) => {
        const formattedDate = new Date(date).toLocaleString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      title: 'Critical Errors',
      dataIndex: 'errorFields',
      key: 'errorFields',
      render: (_: any, record: Document) => {
        return record.displayStatus !== 'PROCESSING' &&
          record.errorFields?.length > 0 ? (
          <Box
            borderRadius="100%"
            sx={{
              fontSize: '0.75rem',
              fontWeight: 500,
              color: 'white',
              backgroundColor: '#FF4D4F',
              padding: '0.25rem',
              width: '1rem',
              height: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {record?.errorFields?.filter(
              (errorField) => errorField && errorField.severity === 'required'
            )?.length || 0}
          </Box>
        ) : null;
      },
    },
    {
      title: 'Download',
      key: 'download',
      width: 100,
      render: (_: any, record: Document) => (
        <Button
          type="text"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(record);
          }}
          disabled={downloadingId !== null}
          icon={
            downloadingId === record._id ? (
              <LoadingOutlined spin />
            ) : (
              <DownloadOutlined />
            )
          }
        />
      ),
    },
  ];

  const [complianceTransaction, setComplianceTransaction] =
    useState<TransactionDetailsProps>({} as TransactionDetailsProps);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([]);
  const [faultyFiles, setFaultyFiles] = useState<string[]>([]);

  // const [uploadFileType, setUploadFileType] = useState('il_bw_rtsma');
  const [view, setView] = useState('file');

  const [folders, setFolders] = useState<any[]>([]);
  const [foldersCollapsed, setFoldersCollapsed] = useState<
    Record<string, boolean>
  >({});

  const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
  const [missingDocuments, setMissingDocuments] = useState<string[]>([]);
  const [complianceTransactionType, setComplianceTransactionType] =
    useState<string>('');
  const [remarks, setRemarks] = useState<string>('');
  const [savingRemarks, setSavingRemarks] = useState(false);
  const adminProfile = getAdminProfile();

  // TODO: See if this needs to keep updating or so, ideally shouldnt be an issue
  // Remove this since it's not needed
  // useEffect(() => {
  //   // Fetch the agents associated with the partner
  //   ComplianceService.getAgents().then(
  //     (response) => {
  //       console.log('Agents:', response.data);
  //       setAgents(response.data);
  //     },
  //     (error) => {
  //       console.error('Error fetching agents:', error);
  //       message.error(`Error fetching agents: ${error}`);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    // Fetch transaction details here
    console.log('Transaction ID', transactionID);
    getTransaction();
  }, [transactionID, documentIDs]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    uploadFiles();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFilesToUpload([]);
  };

  const updateRemarks = () => {
    if (!remarks) {
      message.error('Please enter remarks');
      return;
    }
    setSavingRemarks(true);
    ComplianceService.updateRemarks(transactionID, remarks)
      .then(
        (response) => {
          console.log('Remarks updated:', response.data);
          message.success('Remarks updated successfully');
        },
        (error) => {
          console.error('Error updating remarks:', error);
          message.error('Failed to update remarks');
        }
      )
      .finally(() => {
        setSavingRemarks(false);
      });
  };

  const getTransaction = (showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    } else {
      setProgressBarLoading(true);
    }
    ComplianceService.getTransactionByID(transactionID).then(
      (response) => {
        console.log('Transaction Details:', response.data);
        // Add a key field to each document
        const transactionWithKeys = {
          ...response.data,
          documents: response.data.documents.map((doc: any, index: number) => ({
            ...doc,
            key: index,
          })),
        };
        organizeDocumentsIntoFolders(response.data.documents);
        setComplianceTransaction(transactionWithKeys);
        setRemarks(response.data.remarks || '');

        // Set up the completeness check
        setMissingDocuments(response.data?.processingStatus?.missingDocs);
        setMissingDocumentsCount(
          response.data?.processingStatus?.missingDocs?.length || 0
        );

        if (transactionWithKeys?.sourceOfTruth?.data) {
          let sourceOfTruthGroups: string[] = [];
          // Iterate through complianceTransaction?.sourceOfTruth?.data object
          // Iterate over the key value pairs. In the value, check for the 'group' key
          // If the group key is not present in the transactionOverviewGroups array, add it
          // If the group key is present, skip
          Object.entries(transactionWithKeys.sourceOfTruth.data).forEach(
            ([key, value]) => {
              if (
                typeof value === 'object' &&
                value !== null &&
                'group' in value
              ) {
                if (
                  typeof value.group === 'string' &&
                  !sourceOfTruthGroups.includes(value.group)
                ) {
                  sourceOfTruthGroups.push(value.group);
                }
              }
            }
          );
          setTransactionOverviewGroups(sourceOfTruthGroups);
        }
        // setAssignedAgent(
        //   response?.data?.agents[response?.data?.agents?.length - 1]?._id
        // );
        setLoading(false);
        setProgressBarLoading(false);
      },
      (error) => {
        console.error('Error fetching transaction details:', error);
        message.error(`Error fetching transaction details: ${error}`);
        setLoading(false);
        setProgressBarLoading(false);
      }
    );
  };

  const beforeUpload = (file: UploadFile, fileList: Array<UploadFile>) => {
    console.log('In before Upload');
    const isPdf = file.type === 'application/pdf';
    console.log(`- File => ${file.name}: ${isPdf ? 'Accepted' : 'Rejected'}`);

    if (!isPdf) {
      // Optionally, add file to a list of faulty files for UI feedback
      setFaultyFiles([...faultyFiles, file.name]);
      Modal.error({
        title: 'Incorrect file format',
        content: `${file.name} is not a PDF. Please remove it and try again.`,
      });
      return Upload.LIST_IGNORE; // Prevents adding the file to the list
    }

    // TODO: See if we need to widen the scope of this check
    // Check for duplicate files based on name and size
    const isDuplicate = filesToUpload.some(
      (existingFile) =>
        existingFile.name === file.name && existingFile.size === file.size
    );

    if (isDuplicate) {
      Modal.warning({
        title: 'Duplicate file detected',
        content: `${file.name} already exists. Please upload a different file.`,
      });
      return Upload.LIST_IGNORE; // Prevents adding the duplicate file to the list
    }

    // Filter out the faulty file if it's being retried
    setFaultyFiles(
      faultyFiles.filter((faultyFileName) => faultyFileName !== file.name)
    );

    // Add the file to the list for upload
    setFilesToUpload([
      ...filesToUpload,
      ...fileList.filter(
        (file) =>
          file.type === 'application/pdf' &&
          !filesToUpload.some(
            (existingFile) =>
              existingFile.name === file.name && existingFile.size === file.size
          )
      ),
    ]);

    return false; // return false to prevent auto uploading
  };

  const uploadFiles = () => {
    if (filesToUpload && filesToUpload.length > 0) {
      setLoading(true);
      ComplianceService.uploadDocuments(transactionID, filesToUpload).then(
        (response) => {
          console.log('Documents uploaded:', response.data);
          let updatedDocumentIDs = [...documentIDs];
          for (let i = 0; i < response.data.data.documentIDs.length; i++) {
            if (!documentIDs.includes(response.data.data.documentIDs[i])) {
              updatedDocumentIDs.push(response.data.data.documentIDs[i]);
            }
          }
          setDocumentIDs(updatedDocumentIDs);
          setDocumentIDsInLocalStorage(updatedDocumentIDs);
          setFilesToUpload([]);
          setIsModalVisible(false);
          getTransaction();
          setLoading(false);
        },
        (error) => {
          console.error('Error uploading document:', error);
          setLoading(false);
          Modal.error({
            title: error?.response?.data?.message || 'Upload Failed',
            content: (
              <ul>
                {error?.response?.data?.unknownFiles?.map((file: string) => (
                  <li key={file}>{file}</li>
                ))}
              </ul>
            ),
          });
        }
      );
    } else {
      message.error('Please select a file to upload.');
    }
  };

  const handleDocumentClick = (document: Document) => {
    // If Realtor has not been assigned, don't allow
    if (document.displayStatus === 'PROCESSING') {
      return;
    }
    if (!complianceTransaction?.agents[0]) {
      message.error('Please assign an Agent before verifying documents.');
      // TODO: The following line does not work. Debug it later.
      realtorSelectorRef.current?.focus();
      return;
    }
    navigate(
      AppURLs.complianceDashboardTransactionVerifyDocument(
        transactionID,
        document._id
      )
    );
  };

  const goToTransactions = () => {
    navigate(AppURLs.complianceDashboardTransactions());
  };

  const handleViewChange = (e: any) => {
    setView(e.target.value);
  };

  const organizeDocumentsIntoFolders = (documents: Document[]) => {
    const folders: any[] = [];
    documents.forEach((doc) => {
      const folderDetails = doc.folder;
      if (!folderDetails) {
        // TODO: Refine this
        if (!folders.some((folder) => folder.type === 'UNKNOWN')) {
          folders.push({
            name: 'No Folder',
            documents: [doc],
            status: 'NULL',
            internalStatus: 'NULL',
            refreshNeeded: false,
            createdAt: 'UNDEFINED',
            updatedAt: 'UNDEFINED',
            type: 'UNKNOWN',
          });
        } else {
          const folderIndex = folders.findIndex(
            (folder) => folder.type === 'UNKNOWN'
          );
          folders[folderIndex].documents.push(doc);
        }
      } else {
        if (folders.some((folder) => folder.type === folderDetails.type)) {
          const folderIndex = folders.findIndex(
            (folder) => folder.type === folderDetails.type
          );
          folders[folderIndex].documents.push(doc);
        } else {
          folders.push({
            ...folderDetails,
            documents: [doc],
          });
        }
      }
    });
    // return folders;
    setFolders(folders);

    let updatedFoldersCollapsed = { ...foldersCollapsed };
    folders.map((folder) => {
      updatedFoldersCollapsed[folder.type] = false;
      return null;
    });
    setFoldersCollapsed(updatedFoldersCollapsed);
  };

  const toggleFolder = (folderType: string) => {
    const newFoldersCollapsed = { ...foldersCollapsed };
    newFoldersCollapsed[folderType] = !newFoldersCollapsed[folderType];
    setFoldersCollapsed(newFoldersCollapsed);
  };

  const updateComplianceTransactionStatus = (status: string) => {
    ComplianceService.updateStatus(transactionID, status).then(
      (response) => {
        console.log('Transaction status updated:', response.data);
        message.success(
          'Transaction status updated to ' +
            complianceTransactionStatusMap[status].name
        );
        getTransaction();
      },
      (error) => {
        console.error('Error updating transaction status:', error);
        message.error(`Error updating transaction status: ${error}`);
      }
    );
  };

  const assignRealtor = (agentID: string) => {
    setProgressBarLoading(true);
    ComplianceService.assignRealtor(transactionID, agentID, 'UNKNOWN').then(
      (response) => {
        console.log('Agent assigned:', response.data);
        message.success(
          `Transaction assigned to ${agents.find((agent) => agent._id === agentID)?.name}`
        );
        setProgressBarLoading(false);
        getTransaction(false);
      },
      (error) => {
        console.error('Error assigning agent:', error);
        message.error(`Error assigning agent: ${error}`);
        setProgressBarLoading(false);
      }
    );
  };

  const triggerDocumentValidation = () => {
    setProgressBarLoading(true);
    ComplianceService.validateDocuments(transactionID).then(
      (response) => {
        setProgressBarLoading(false);
        getTransaction();
        console.log('Cross Document Validation:', response.data);
        message.success('Successfully validated documents');
      },
      (error) => {
        setProgressBarLoading(false);
        console.error('Error validating documents:', error);
        message.error(`Error validating documents: ${error}`);
      }
    );
  };

  const sortDocuments = (documents: any[]) => {
    const regularDocs = documents.filter(
      (doc) => doc.type !== 'USER_UPLOADED_MULTI_DOC'
    );
    const multiDocs = documents.filter(
      (doc) => doc.type === 'USER_UPLOADED_MULTI_DOC'
    );

    return [
      ...regularDocs,
      // Add a separator object if there are both types of documents
      ...(regularDocs.length > 0 && multiDocs.length > 0
        ? [
            {
              _id: 'separator',
              isSeparator: true,
            },
          ]
        : []),
      ...multiDocs,
    ];
  };

  /**
   * Use this function to add the missing documents error to the errors manager
   * The 'documentType' key will be '_missingDocuments'
   * This will act as a special key and handled differently.
   */
  const addDocumentsMissingError = () => {
    if (missingDocumentsCount > 0) {
      errorsManager.addErrors(
        transactionID,
        '_missingDocuments',
        missingDocuments
      );
    }
  };

  // const FolderView = () => {
  //   return (
  //     <Box
  //       width="100%"
  //       borderRadius="8px"
  //       padding="2rem"
  //       sx={{ backgroundColor: 'white' }}
  //       boxSizing="border-box"
  //     >
  //       {/* Header */}
  //       <Box
  //         display="flex"
  //         width="100%"
  //         justifyContent="space-between"
  //         alignItems="center"
  //       >
  //         <Box flex={1} paddingRight="2rem">
  //           <Typography fontSize="0.9rem" fontWeight={550}>
  //             NAME
  //           </Typography>
  //         </Box>
  //         <Box flex={1}>
  //           <Typography fontSize="0.9rem" fontWeight={550}>
  //             STATUS
  //           </Typography>
  //         </Box>
  //         <Box flex={0.1}></Box>
  //       </Box>
  //       <Divider style={{ margin: '1rem 0 1rem 0' }} />

  //       {/* Folders */}
  //       {folders.length > 0 ? (
  //         folders.map((folder: any, index: number) => (
  //           <Box
  //             width="100%"
  //             sx={{
  //               cursor: 'pointer',
  //             }}
  //             key={index}
  //           >
  //             <Box
  //               display="flex"
  //               width="100%"
  //               justifyContent="space-between"
  //               alignItems="center"
  //             >
  //               <Box
  //                 flex={1}
  //                 display="flex"
  //                 alignItems="center"
  //                 paddingRight="2rem"
  //                 onClick={() => {
  //                   toggleFolder(folder.type);
  //                 }}
  //               >
  //                 <IconButton
  //                   onClick={() => toggleFolder(folder.type)}
  //                   style={{
  //                     color: foldersCollapsed[folder.type]
  //                       ? '#2F78EB'
  //                       : 'inherit',
  //                   }}
  //                 >
  //                   {foldersCollapsed[folder.type] ? (
  //                     <FolderOpenOutlined />
  //                   ) : (
  //                     <FolderOutlined />
  //                   )}
  //                 </IconButton>
  //                 {folder.name}
  //               </Box>
  //               <Box
  //                 flex={1}
  //                 onClick={() => {
  //                   toggleFolder(folder.type);
  //                 }}
  //               >
  //                 {/* TODO: Unhide this after fixing the backend */}
  //                 {/* <StatusChip status={folder.status || 'NULL'} /> */}
  //               </Box>
  //               <Box flex={0.1}>
  //                 <IconButton>
  //                   <MoreOutlined />
  //                 </IconButton>
  //               </Box>
  //             </Box>
  //             {foldersCollapsed[folder.type] &&
  //               folder?.documents.length > 0 && (
  //                 <Box marginTop="1rem" sx={{ cursor: 'pointer' }}>
  //                   {folder.documents.map((doc: Document, index: number) => (
  //                     <Box
  //                       display="flex"
  //                       width="100%"
  //                       justifyContent="space-between"
  //                       alignItems="center"
  //                       key={index}
  //                       // margin="0.75rem 0 0.75rem 0"
  //                       padding="1rem 0 1rem 0"
  //                       sx={{
  //                         transition: 'background-color 0.3s ease',
  //                         '&:hover': {
  //                           backgroundColor: '#f5f5f5', // Light grey background on hover
  //                           // Add any other hover styles or animations here
  //                         },
  //                       }}
  //                       onClick={() => {
  //                         handleDocumentClick(doc._id);
  //                       }}
  //                     >
  //                       <Box
  //                         flex={1}
  //                         display="flex"
  //                         paddingLeft="2rem"
  //                         alignItems="center"
  //                       >
  //                         <Typography fontSize="0.9rem">{doc.title}</Typography>
  //                       </Box>
  //                       <Box flex={1}>
  //                         <StatusChip status={doc.reviewStatus} />
  //                       </Box>
  //                       <Box flex={0.1}>
  //                         <Box
  //                           borderRadius="100%"
  //                           sx={{
  //                             // margin: 'auto 1rem auto auto',
  //                             fontSize: '0.75rem',
  //                             fontWeight: 500,
  //                             color: 'white',
  //                             backgroundColor: '#FF4D4F',
  //                             padding: '0.25rem',
  //                             width: '1rem',
  //                             height: '1rem',
  //                             display: 'flex',
  //                             alignItems: 'center',
  //                             justifyContent: 'center',
  //                           }}
  //                         >
  //                           {doc?.errorFields.length}
  //                         </Box>
  //                         {/* <IconButton><MoreOutlined /></IconButton> */}
  //                       </Box>
  //                     </Box>
  //                   ))}
  //                 </Box>
  //               )}
  //             <Divider style={{ margin: '1rem 0 1rem 0' }} />
  //           </Box>
  //         ))
  //       ) : (
  //         <Box
  //           display="flex"
  //           width="100%"
  //           justifyContent="center"
  //           alignItems="center"
  //         >
  //           <Empty description="No Files/Folders found" />
  //         </Box>
  //       )}
  //     </Box>
  //   );
  // };

  // Disabled the color coding for now - Replacing it with just red for missing/not-found fields
  const getRowClassName = (record: any) => {
    // if (record?.priority === 1) {
    //   return 'high-confidence-row';
    // } else if (record?.priority === 2) {
    //   return 'medium-confidence-row';
    // } else if (record?.priority > 2) {
    //   return 'low-confidence-row';
    // }
    // return '';
    if (!record?.value || record?.value === 'not found') {
      return 'low-confidence-row';
    }
    return '';
  };

  const component = (
    <Box width="100%">
      {progressBarLoading && <LoadingProgressBar />}

      {/* <Drawer
        title="Transaction Overview"
        open={showTransactionOverview}
        closable={true}
        onClose={() => setShowTransactionOverview(false)}
        width="650px"
      >
        {transactionOverviewGroups.map((group, index) => (
          <Box key={index}>
            <Collapse
              items={[
                {
                  key: index,
                  label: capitalizeFirstLetter(group),
                  children: (
                    <Table
                      dataSource={
                        complianceTransaction?.sourceOfTruth?.data
                          ? Object.entries(
                              complianceTransaction?.sourceOfTruth?.data
                            )
                              .filter(([key, value]) => {
                                const typedValue = value as { group: string }; // Type assertion
                                return typedValue.group === group;
                              })
                              .map(([key, value]) => {
                                if (
                                  typeof value === 'object' &&
                                  value !== null
                                ) {
                                  return {
                                    ...value,
                                    key,
                                  };
                                } else {
                                  return {
                                    key,
                                  };
                                }
                              })
                          : []
                      }
                      columns={[
                        {
                          title: 'Title',
                          dataIndex: 'title',
                          key: 'title',
                          render: (title: string, record: any) => {
                            return (
                              <Tooltip title={record.description}>
                                <Typography fontWeight={450} fontSize="0.9rem">
                                  {title}
                                </Typography>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: 'Value',
                          dataIndex: 'value',
                          key: 'value',
                          render: (value: string, record: any) => {
                            return (
                              <Typography fontSize="0.9rem">{value}</Typography>
                            );
                          },
                        },
                        {
                          title: 'Source',
                          dataIndex: 'source',
                          key: 'source',
                          render: (source: string) => {
                            return (
                              <Typography fontSize="0.9rem">
                                {documentNameMap[source]}
                              </Typography>
                            );
                          },
                        },
                      ]}
                      pagination={false}
                      rowClassName={getRowClassName}
                    />
                  ),
                },
              ]}
            />
            <Divider />
          </Box>
        ))}
      </Drawer> */}

      <Box width="100%" padding="1rem" boxSizing="border-box">
        <Box
          display="flex"
          width="100%"
          marginBottom="0.5rem"
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb style={{ margin: '1rem 0 1rem 0', display: 'flex' }}>
            {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
            <Breadcrumb.Item onClick={() => goToTransactions()}>
              <Typography fontSize="0.9rem" style={{ cursor: 'pointer' }}>
                {' '}
                Transactions{' '}
              </Typography>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Typography fontSize="0.9rem">
                {complianceTransaction.name}
              </Typography>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Box display="flex" marginLeft="auto">
            {/* <Radio.Group
              value={view}
              onChange={handleViewChange}
              style={{ margin: '0 0.5rem 0 auto' }}
            >
              <Radio.Button value="file" style={{ marginBottom: '1rem' }}>
                <FileOutlined />
              </Radio.Button>
              <Radio.Button value="folder" style={{ marginBottom: '1rem' }}>
                <FolderOutlined />
              </Radio.Button>
            </Radio.Group> */}

            {adminProfile ? (
              <Button
                type="default"
                onClick={triggerDocumentValidation}
                disabled={progressBarLoading}
                style={{ marginRight: '0.5rem' }}
              >
                Validate Documents
              </Button>
            ) : null}

            <Button
              type="primary"
              style={{ marginLeft: 'auto' }}
              onClick={showModal}
            >
              Upload Document
            </Button>
          </Box>
        </Box>

        <Box display="flex">
          {Object.keys(complianceTransaction).length > 0 && (
            <Card style={{ marginBottom: '1rem', width: '100%' }}>
              <Box display="flex">
                <Box>
                  <Typography
                    fontSize="1.5rem"
                    fontWeight={500}
                    display="flex"
                    alignItems="center"
                  >
                    {complianceTransaction.name}
                    {transactionOverviewGroups.length > 0 && (
                      <IconButton
                        onClick={() => {
                          setShowTransactionOverview(true);
                        }}
                      >
                        <InfoCircleOutlined />
                      </IconButton>
                    )}
                  </Typography>
                  <Typography fontSize="1.0rem" fontWeight={350}>
                    {complianceTransaction?.sourceOfTruth?.data?.buyer
                      ?.value && (
                      <>
                        {' '}
                        <b>Buyer: </b>{' '}
                        {Array.isArray(
                          complianceTransaction?.sourceOfTruth?.data?.buyer
                            ?.value
                        ) ? (
                          complianceTransaction?.sourceOfTruth?.data?.buyer?.value.map(
                            (buyer: string, index: number) => (
                              <span key={index}>
                                {buyer}
                                {index <
                                  complianceTransaction?.sourceOfTruth?.data
                                    ?.buyer?.value.length -
                                    1 && ', '}
                              </span>
                            )
                          )
                        ) : (
                          <span>
                            {
                              complianceTransaction?.sourceOfTruth?.data?.buyer
                                ?.value
                            }
                          </span>
                        )}
                      </>
                    )}
                    &nbsp;&nbsp;
                    {complianceTransaction?.sourceOfTruth?.data?.seller
                      ?.value && (
                      <>
                        <b>Seller: </b>
                        {Array.isArray(
                          complianceTransaction?.sourceOfTruth?.data?.seller
                            ?.value
                        ) ? (
                          complianceTransaction?.sourceOfTruth?.data?.seller?.value.map(
                            (buyer: string, index: number) => (
                              <span key={index}>
                                {buyer}
                                {index <
                                  complianceTransaction?.sourceOfTruth?.data
                                    ?.seller?.value.length -
                                    1 && ', '}
                              </span>
                            )
                          )
                        ) : (
                          <span>
                            {
                              complianceTransaction?.sourceOfTruth?.data?.seller
                                ?.value
                            }
                          </span>
                        )}
                      </>
                    )}
                  </Typography>
                </Box>
                {missingDocumentsCount > 0 && missingDocuments.length > 0 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="0 0 auto auto"
                  >
                    <Tooltip
                      title={
                        <Box>
                          {missingDocuments.map((doc, index) => (
                            <Box key={index} display="flex" alignItems="center">
                              <Typography fontSize="0.9rem">
                                {index + 1}. {documentNameMap?.[doc] || doc}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      }
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          cursor: 'pointer',
                          transition: 'transform 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                      >
                        <ErrorRounded
                          sx={{
                            height: '2rem',
                            width: '2rem',
                            color: '#FF4D4F',
                          }}
                        />
                        <Typography marginLeft="0.25rem">
                          <b>{missingDocumentsCount}</b>
                          {missingDocumentsCount === 1
                            ? ' Document Missing'
                            : ' Documents Missing'}
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Tooltip title="Send Email">
                      <IconButton
                        onClick={() => {
                          addDocumentsMissingError();
                        }}
                      >
                        <SendRounded
                          style={{
                            marginLeft: '0.25rem',
                            fontSize: '1.25rem',
                            cursor: 'pointer',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {missingDocumentsCount === 0 && (
                  <Box
                    margin="0 0 auto auto"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckCircleOutlined
                      style={{ color: '#52C41A', fontSize: '2rem' }}
                    />
                    <Typography marginLeft="0.25rem" marginRight="0.25rem">
                      All Documents Uploaded
                    </Typography>
                  </Box>
                )}
              </Box>
              <Divider style={{ margin: '0.5rem 0 0.5rem 0' }} />
              <Box
                display="flex"
                marginTop="1rem"
                width="auto"
                justifyContent="space-between"
                sx={{
                  '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    gap: '0.5rem',
                  },
                }}
              >
                <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Review Status
                  </Typography>
                  <Typography fontSize="1rem">
                    {' '}
                    {transactionProcessingStatusMap[
                      complianceTransaction?.processingStatus?.status
                    ]?.name || '-'}
                  </Typography>
                </Box>
                {/* <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Type
                  </Typography> */}
                {/* <Typography
                    fontSize="1rem"
                    fontWeight={400}
                    marginBottom="1rem"
                  > */}
                {/* TODO: Once the logic for this is ascertained, fix it */}
                {/* {complianceTransactionType || '-'} */}
                {/* </Typography>
                </Box> */}
                {/* <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Status
                  </Typography>
                  <Box>
                    <Select
                      value=""
                      displayEmpty
                      variant="standard"
                      size="small"
                      sx={{
                        display: 'flex',
                        border: 'none',
                        padding: '0.1rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        '&:before': {
                          borderBottom: 'none',
                        },
                        '&:after': {
                          borderBottom: 'none',
                        },
                      }}
                      renderValue={() => {
                        return (
                          <Chip
                            size="small"
                            label={
                              complianceTransactionStatusMap[
                                complianceTransaction.status
                              ].name
                            }
                            sx={{
                              backgroundColor:
                                complianceTransactionStatusMap[
                                  complianceTransaction.status
                                ].backgroundColor,
                              color:
                                complianceTransactionStatusMap[
                                  complianceTransaction.status
                                ].fontColor,
                              borderRadius: '4px',
                            }}
                          />
                        );
                      }}
                    >
                      {Object.keys(complianceTransactionStatusMap).map(
                        (status) => (
                          <MenuItem
                            key={status}
                            value={status}
                            onClick={() =>
                              updateComplianceTransactionStatus(status)
                            }
                          >
                            <Chip
                              size="small"
                              label={
                                complianceTransactionStatusMap[status].name
                              }
                              sx={{
                                backgroundColor:
                                  complianceTransactionStatusMap[status]
                                    .backgroundColor,
                                color:
                                  complianceTransactionStatusMap[status]
                                    .fontColor,
                                borderRadius: '8px',
                              }}
                            />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </Box>
                </Box> */}
                <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Closing Date
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    fontWeight={400}
                    marginBottom="1rem"
                  >
                    {new Date(
                      complianceTransaction?.closingDate || ''
                    ).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </Typography>
                </Box>
                <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Creation Date
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    fontWeight={400}
                    marginBottom="1rem"
                  >
                    {new Date(
                      complianceTransaction.createdAt
                    ).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </Typography>
                </Box>

                <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Transaction Type
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    fontWeight={400}
                    marginBottom="1rem"
                  >
                    {TRANSACTION_TYPE_OPTIONS(complianceTransaction?.type) ||
                      '-'}
                  </Typography>
                </Box>

                <Box
                  sx={{ width: '100%', maxWidth: '400px', marginLeft: '1rem' }}
                >
                  <Typography
                    color="#666666"
                    fontSize="0.9rem"
                    marginBottom="0.5rem"
                  >
                    Remarks
                  </Typography>
                  <Box position="relative">
                    <textarea
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      placeholder="Add remarks here..."
                      style={{
                        width: '100%',
                        padding: '0.5rem',
                        borderRadius: '0.5rem',
                        border: '1px solid #d9d9d9',
                        resize: 'vertical',
                        fontSize: '0.9rem',
                        minHeight: '4rem',
                      }}
                    />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Button
                        type="primary"
                        onClick={updateRemarks}
                        loading={savingRemarks}
                      >
                        {savingRemarks ? 'Saving...' : 'Save'}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                {/* <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Created By
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    fontWeight={400}
                    marginBottom="1rem"
                  >
                    Compliance Team
                  </Typography>
                </Box> */}
                {/* <Box>
                  <Typography color="#666666" fontSize="0.9rem">
                    Agent
                  </Typography>
                  <Select
                    ref={realtorSelectorRef}
                    value={assignedAgent}
                    size="small"
                    variant="standard"
                    autoWidth
                    sx={{
                      minWidth: '10rem',
                    }}
                    renderValue={() => {
                      return (
                        <Typography fontSize="1rem">
                          {agents.find((agent) => agent._id === assignedAgent)
                            ?.name || 'Select Agent'}
                        </Typography>
                      );
                    }}
                  >
                    {agents.map((agent, index) => (
                      <MenuItem
                        key={index}
                        value={agent.name}
                        onClick={() => assignRealtor(agent._id)}
                      >
                        <Typography fontSize="0.9rem">{agent.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Box> */}
              </Box>
            </Card>
          )}
        </Box>

        <Modal
          title="Upload Documents"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Dragger
            style={{
              width: '100%',
              marginBottom: '1rem',
            }}
            multiple={true}
            accept=".pdf"
            beforeUpload={beforeUpload}
            onChange={(info) => {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                message.success(
                  `${info.file.name} file uploaded successfully.`
                );
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            onRemove={(file) => {
              setFilesToUpload(filesToUpload.filter((f) => f.uid !== file.uid));
              setFaultyFiles(
                faultyFiles.filter(
                  (faultyFileName) => faultyFileName !== file.name
                )
              );
            }}
            fileList={filesToUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">File formats accepted: PDF</p>
            <p className="ant-upload-hint">
              Maximum Upload Packet Size: 100 MB
            </p>
          </Dragger>
        </Modal>

        {/* TODO: Modularise this better */}
        {view === 'folder' ? null : ( // <FolderView />
          <Table
            dataSource={sortDocuments(complianceTransaction?.documents || [])}
            columns={columns}
            style={{ cursor: 'pointer', tableLayout: 'fixed' }}
            pagination={false}
            onRow={(record: Document) => ({
              onClick: () => !record.isSeparator && handleDocumentClick(record),
            })}
            components={{
              body: {
                row: (props: any) => {
                  if (props?.children[0]?.props?.record?.isSeparator) {
                    return (
                      <tr>
                        <td
                          colSpan={columns.length}
                          style={{
                            padding: 0,
                            borderBottom: 'none !important',
                          }}
                        >
                          <Divider
                            style={{ margin: '1rem 0', borderColor: '#c2e2fb' }}
                          />
                        </td>
                      </tr>
                    );
                  }
                  return <tr {...props} />;
                },
              },
            }}
          />
        )}
      </Box>
    </Box>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default ComplianceTransactionDetailsPage;
