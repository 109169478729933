import './TransactionFilterComponent.scss';
import { DatePicker, Drawer, Select, SelectProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import FilterButton from 'app/components/common/Checks/FilterButton';
import { transactionProcessingStatusMap } from '../colorScheme';

interface SelectOption {
  value: string;
  label: string;
}

interface InitialFilters {
  statuses: string[];
  statusLabels: string[];
  transactionTypes?: string[];
}

const statuses: SelectOption[] = [
  {
    value: 'NOT_STARTED',
    label: transactionProcessingStatusMap.NOT_STARTED.name,
  },
  {
    value: 'PROCESSING',
    label: transactionProcessingStatusMap.PROCESSING.name,
  },
  {
    value: 'MAX_APPROVED',
    label: transactionProcessingStatusMap.MAX_APPROVED.name,
  },
  {
    value: 'USER_APPROVED',
    label: transactionProcessingStatusMap.USER_APPROVED.name,
  },
  {
    value: 'SENT_TO_AGENT_FOR_UPDATES',
    label: transactionProcessingStatusMap.SENT_TO_AGENT_FOR_UPDATES.name,
  },
  {
    value: 'DOCS_MISSING',
    label: transactionProcessingStatusMap.DOCS_MISSING.name,
  },
  {
    value: 'VALIDATION_FAILED',
    label: transactionProcessingStatusMap.VALIDATION_FAILED.name,
  },
];

const useDebounceEffect = (effect: () => void, deps: any[], delay: number) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);
    return () => clearTimeout(handler);
  }, deps);
};

const TransactionFilterComponent: React.FC<{
  onFilterChange: (
    statuses: string[],
    createdAtFilter: { gte: string; lte: string },
    closingDateFilter: { gte: string; lte: string },
    transactionTypes: string[]
  ) => void;
  initialFilters: InitialFilters;
}> = ({ onFilterChange, initialFilters }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    initialFilters.statuses
  );
  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState<
    string[]
  >(initialFilters.transactionTypes || []);
  const [statusLabels, setStatusLabels] = useState<string[]>(
    initialFilters.statusLabels
  );
  const [createdAtFilter, setCreatedAtFilter] = useState<{
    gte: string;
    lte: string;
  }>({
    gte: '',
    lte: '',
  });
  const [closingDateFilter, setClosingDateFilter] = useState<{
    gte: string;
    lte: string;
  }>({
    gte: '',
    lte: '',
  });
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const transactionTypeOptions = [
    { value: 'PURCHASE', label: 'Buy' },
    { value: 'LISTING', label: 'List' },
  ];

  const clearFilters = () => {
    setSelectedStatuses([]);
    setStatusLabels([]);
    setCreatedAtFilter({ gte: '', lte: '' });
    setClosingDateFilter({ gte: '', lte: '' });
    setSelectedTransactionTypes([]);
  };

  const handleStatusSelect = (value: string[]) => {
    if (value.length === 0) {
      setStatusLabels([]);
    } else {
      const newStatusLabels = value
        .map((val) => statuses.find((s) => s.value === val)?.label || '')
        .filter(Boolean);
      setStatusLabels(newStatusLabels);
    }
    setSelectedStatuses(value);
  };

  useDebounceEffect(
    () => {
      // Calculate active filters count
      let count = 0;
      if (selectedStatuses.length > 0) count++;
      if (createdAtFilter.gte && createdAtFilter.lte) count++;
      if (closingDateFilter.gte && closingDateFilter.lte) count++;
      if (selectedTransactionTypes.length > 0) count++;
      setActiveFiltersCount(count);

      // Trigger filter change
      onFilterChange(
        selectedStatuses,
        createdAtFilter,
        closingDateFilter,
        selectedTransactionTypes
      );
    },
    [
      selectedStatuses,
      createdAtFilter.gte,
      createdAtFilter.lte,
      closingDateFilter.gte,
      closingDateFilter.lte,
      selectedTransactionTypes,
    ],
    300 // 300ms debounce
  );

  useEffect(() => {
    // Calculate active filters count
    let count = 0;
    if (selectedStatuses.length > 0) count++;
    if (createdAtFilter.gte && createdAtFilter.lte) count++;
    if (closingDateFilter.gte && closingDateFilter.lte) count++;
    if (selectedTransactionTypes.length > 0) count++;
    setActiveFiltersCount(count);

    // Trigger filter change
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      closingDateFilter,
      selectedTransactionTypes
    );
  }, [
    selectedStatuses,
    createdAtFilter,
    closingDateFilter,
    selectedTransactionTypes,
  ]);

  return (
    <div className="filter-component">
      <FilterButton
        onClick={toggleDropdown}
        clearFilters={clearFilters}
        selectedCount={activeFiltersCount}
      />

      <Drawer
        title={<Typography fontSize="1.5rem">Filters</Typography>}
        open={showDropdown}
        onClose={toggleDropdown}
        width={350}
      >
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Status
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Status"
            value={selectedStatuses}
            onChange={(value) => handleStatusSelect(value)}
            options={statuses}
            allowClear
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Transaction Type
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Transaction Type"
            value={selectedTransactionTypes}
            onChange={(value) => setSelectedTransactionTypes(value)}
            options={transactionTypeOptions}
            allowClear
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Created Date
          </Typography>
          <DatePicker.RangePicker
            disabledDate={(current) => current > dayjs()}
            value={
              createdAtFilter.gte && createdAtFilter.lte
                ? [dayjs(createdAtFilter.gte), dayjs(createdAtFilter.lte)]
                : null
            }
            onChange={(value) => {
              if (!value) {
                setCreatedAtFilter({ gte: '', lte: '' });
              }
              if (value) {
                setCreatedAtFilter({
                  gte: value[0]?.format('MM-DD-YYYY') || '',
                  lte: value[1]?.format('MM-DD-YYYY') || '',
                });
              }
            }}
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Closing Date
          </Typography>
          <DatePicker.RangePicker
            value={
              closingDateFilter.gte && closingDateFilter.lte
                ? [dayjs(closingDateFilter.gte), dayjs(closingDateFilter.lte)]
                : null
            }
            onChange={(value) => {
              if (!value) {
                setClosingDateFilter({ gte: '', lte: '' });
              }
              if (value) {
                setClosingDateFilter({
                  gte: value[0]?.format('MM-DD-YYYY') || '',
                  lte: value[1]?.format('MM-DD-YYYY') || '',
                });
              }
            }}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default TransactionFilterComponent;
