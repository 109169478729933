import { validateEnvVariable } from './helpers';

const { REACT_APP_FLAGSMITH_API_KEY } = process.env;

const API_BASE_URL = validateEnvVariable(REACT_APP_FLAGSMITH_API_KEY);

const featureFlagEnvironment = {
  API_BASE_URL,
};

export default featureFlagEnvironment;
